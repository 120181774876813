import React, { useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import './Help.css'
import { GetAllWebsiteFaq } from '../../utils/apis/master/Master';
import { Context } from '../../utils/context';


const Help = () => {
    var HtmlToReactParser = require("html-to-react").Parser;
    var htmlToReactParser = new HtmlToReactParser();
    const { sellerId } = useContext(Context);
    const [faq, setFaq] = useState([]);
    const getfaq = async () => {
        const res = await GetAllWebsiteFaq(sellerId);
        if (res?.success) {
            setFaq(res?.data);
        }
    }
    console.log(faq, "faq");

    useEffect(() => {
        getfaq();
    }, []);
    return (
        <section className='help'>
            <Container>
                <div className='white-bggg'>
                    <h4 className='help-title'>Help Center | 24x7 Customer Care Support</h4>

                    <Accordion>
                        {faq?.map(item => (
                            <Accordion.Item key={item.id} eventKey={item.id}>
                                <Accordion.Header>{item?.name}</Accordion.Header>
                                <Accordion.Body>
                                    
                                    {item?.s_sub_faqs?.map(data => (
                                    <div>
                                        <p className='sub-title'>{data?.name}</p>
                                        <p className='text'>{htmlToReactParser.parse(data?.description)}</p>
                                    </div>
                                    ))}
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            </Container>
        </section>
    )
}

export default Help