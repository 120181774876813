import React from 'react'
import './SubBanner.css'
import { Col, Container, Row } from 'react-bootstrap'
function SubBanner() {
    const subaner=[
        {
            image:
            process.env.PUBLIC_URL +
            "/assets/Image/Homepage/Banner/banner74.jpg",
        },
        {
            image:
            process.env.PUBLIC_URL +
            "/assets/Image/Homepage/Banner/banner72.jpg",
        },
        {
            image:
            process.env.PUBLIC_URL +
            "/assets/Image/Homepage/Banner/banner73.jpg",
        },
    ]
  return (
    <>
    <section className='subbaner_section mt-4 mb-4'>
        <Container>
            <div className='subbbaner_main_div'>
                <Row>
                {subaner.map((subaner, index) => (
                    <Col xxl={4} xl={4} lg={6} md={6} sm={12} className='mx-auto mb-3'>
                        <div className='submainimg'>
                            <img src={subaner.image} className='subimg'/>
                        </div>
                    </Col>
                ))}
                </Row>
            </div>
        </Container>
    </section>
    </>
  )
}

export default SubBanner