import React, { useContext, useState } from "react";
import "./Login.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useForm } from "react-hook-form";
import { userLogin } from '../../utils/apis/login/Login';
import Cookies from "js-cookie";
import classNames from "classnames";
import Register from "./Register/Register";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import { Context } from "../../utils/context";

function Login() {
    const [showEmail, setShowEmail] = useState(true);
    const { signin, setSignin } = useContext(Context);
    const [showPassword, setShowPassword] = useState(false);
    const [phoneValue, setPhoneValue] = useState("");
    const [showForgetPassword, setShowForgetPassword] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const navigate = useNavigate();

    const toggleInput = () => {
        setShowEmail(!showEmail);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleCreateAccountClick = () => {
        setShowRegisterForm(true);
    };

    const {
        register,
        handleSubmit,
        getValues,
        setError,
        formState: { errors },
        reset,
    } = useForm();

    const onSubmit = async (data) => {
        const loginData = {
            ...data,
            email_or_contact: showEmail ? data.email_or_contact : phoneValue,
        };

        const res = await userLogin(loginData);
        if (res?.success) {
            await Cookies.set("ecommerce3_security", res?.data?.token);
            await setSignin(true);
            navigate('/');
            reset();
        } else {
            if (res?.message?.errors) {
                res.message.errors.forEach(error => {
                    setError(error.path, { message: error.msg });
                });
            }
        }
    };

    return (
        <section className="login_section">
            <Container>
                {!showForgetPassword && !showRegisterForm && (
                    <div>
                        <Row className="justify-content-center mx-auto">
                            <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} className="p-4 p-lg-4">
                                <div className="login-bggg ">
                                    <div className="text-center">
                                        <h5 className="title">Login to your account.</h5>
                                    </div>

                                    <div>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <div>
                                                {showEmail ? (
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label className="text">Email</Form.Label>
                                                        <Form.Control type="text" placeholder="name@example.com"
                                                            {...register("email_or_contact", {
                                                                required: "Email or phone number is required",
                                                            })}
                                                            className={classNames("", {
                                                                "is-invalid": errors?.email_or_contact,
                                                                "is-valid": getValues("email_or_contact"),
                                                            })} />
                                                        {/* {errors.email_or_contact && <div className="invalid-feedback">{errors.email_or_contact.message}</div>} */}
                                                    </Form.Group>
                                                ) : (
                                                    <>
                                                        <Form.Label className="text">Phone</Form.Label>
                                                        <PhoneInput
                                                            className={classNames("mb-3", {
                                                                "is-invalid": errors?.email_or_contact,
                                                            })}
                                                            country={"in"}
                                                            value={phoneValue}
                                                            onChange={setPhoneValue}
                                                        />
                                                        {/* {errors.email_or_contact && <div className="invalid-feedback">{errors.email_or_contact.message}</div>} */}
                                                    </>
                                                )}
                                                <p onClick={toggleInput} style={{ cursor: 'pointer', color: '#e40046', textDecoration: 'underline', fontSize: '13px', fontWeight: '600' }} className="text-end mb-0">
                                                    using {showEmail ? 'phone number' : 'email'}
                                                </p>
                                            </div>
                                            <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label className="text">Password</Form.Label>
                                                <div className="fcont_div">
                                                    <Form.Control
                                                        type={showPassword ? "text" : "password"}
                                                        placeholder="password"
                                                        {...register("password", {
                                                            required: "Password is required",
                                                            minLength: {
                                                                value: 6,
                                                                message: "Password must be at least 6 characters",
                                                            },
                                                        })}
                                                        className={classNames("", {
                                                            "is-invalid": errors?.password,
                                                            "is-valid": getValues("password"),
                                                        })} />
                                                    <FontAwesomeIcon
                                                        icon={showPassword ? faEyeSlash : faEye}
                                                        className="eyicn"
                                                        onClick={togglePasswordVisibility}
                                                    />
                                                </div>
                                                {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                                            </Form.Group>
                                            <div className="d-flex justify-content-between">
                                                <Form.Check
                                                    type="checkbox"
                                                    id="custom-switch"
                                                    label="Remember me"
                                                    className="sub-text"
                                                    {...register("remember_me")}
                                                />
                                                <p className="sub-text" onClick={() => setShowForgetPassword(true)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>Forgot Password</p>
                                            </div>
                                            <div className="text-center">
                                                <Button type="submit" className="loginbtn">Login</Button>
                                            </div>
                                            <div className="text-center">
                                                <p className="mt-3 mb-2 sub-text ">Dont have an account?
                                                    <span className="midtext colrrr" onClick={handleCreateAccountClick} style={{ cursor: 'pointer', textDecoration: 'underline' }}> Register Now</span> </p>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
                {showForgetPassword && <ForgotPassword setShowForgetPassword={setShowForgetPassword} />}
                {showRegisterForm && <Register setShowRegisterForm={setShowRegisterForm}/>}
            </Container>
        </section>
    );
}

export default Login;
