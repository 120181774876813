import React, { useContext, useEffect, useState } from "react";
import "./AddCartProduct.css";

import {Rating} from "react-simple-star-rating";
import {faHeart, faShuffle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import {Link} from "react-router-dom";
import { getReview, productReviewOnlyOnce } from "../../../utils/apis/Product/Product";
import { Context } from "../../../utils/context";
function AddCartProduct({image, text, title, price1, price2, productId}) {
    const { sellerId, IMG_URL } = useContext(Context);
    const [getreviews, setGetreviews]=useState(0);
    const getReviews = async () => {
        try {
            const response = await getReview(productId);
            if (response?.success) {
                setGetreviews(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const [userreviewed, setUserReviewed] = useState([]);

    const getUserReviewee = async () => {
      const res = await productReviewOnlyOnce(productId, sellerId);
  
      const ratings = res?.data?.reviews?.map((review) => review?.rating);
      setUserReviewed(ratings);
      console.log(ratings, "gauarav");
    };
  
    useEffect(() => {
      getUserReviewee();
    }, []);
  
  
    const [avrage, setaverage] = useState(0);
    var average = 0;
    useEffect(() => {
      // Calculate sum and count inside this useEffect, which runs after reviewdetail is updated
      const array = userreviewed?.map(Number); // Convert each element to a number
      const sum = array?.reduce((acc, curr) => acc + curr, 0); // Sum of array elements
      const count = array?.length;
      setaverage(sum / count);
  
    }, [userreviewed]);
    return (
        <>
            <section className="addproduct_section">
                <div className="addproduct_main">
                    <div className="addcartimg_div_main">
                        <div className="addcartimg_div">
                            <img src={image} className="addcartimg" />
                        </div>
                        <div className="fontosey_div">
                            <Link to={`/product-detail/${productId}`}>
                                <FontAwesomeIcon icon={faEye} />
                            </Link>
                        </div>
                    </div>
                    <div className="text-center">
                        <p className="small-text mb-0"></p>
                        <p className="prodcname mb-1">{title}</p>
                        <div>
                            <Rating 
                            initialValue={avrage}
                            SVGclassName="star-svg"
                            fillColor="#ffc107"
                            size={"25px"}
                            readonly={true}/>
                        </div>
                        <p>
                            <span className="discprice">{price2}</span> <span className="mainprice">{price1}</span>
                        </p>
                    </div>
                    <div className="main_divBottombtn">
                        <div className="botombtn_div">
                            <div>
                                <div className="sufflebtn_div me-2">
                                    <FontAwesomeIcon icon={faHeart} />
                                </div>
                            </div>
                            
                            <div className="btnaddcart_div">
                                <Link to="/add-cart">
                                    <button className="btn_addcart">Add To Cart</button>
                                </Link>
                            </div>
                            <div>
                                {/* <div className='sufflebtn_div ms-2'>
                                    <FontAwesomeIcon icon={faShuffle} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AddCartProduct;
