import React from "react";
import "./App.css";
import "./index.css";
import { useLocation } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import ScrollToTop from "./components/ScrollTotop/ScrollTotop";
import HomePage from "./components/HomePage/HomePage";
import AboutUs from "./components/AboutUs/AboutUs";
import AllCategories from "./components/AllCategories/AllCategories";
import Blog from "./components/Blog/Blog";
import Login from "./components/Login/Login";
import BlogDetails from "./components/Blog/BlogDetails/BlogDetails";
import ContactUs from "./components/ContactUs/ContactUs";
import ProductDetail from "./components/ProductDetail/ProductDetail";
import AddToCart from "./components/AddToCart/AddToCart";
import CheckoutForm from "./components/CheckoutForm/CheckoutForm";
import MyAccount from "./components/MyAccount/MyAccount";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsCondition from "./components/TermsCondition/TermsCondition";
import ShippingReturns from "./components/ShippingReturns/ShippingReturns";
import MyWishList from "./components/MyWishList/MyWishList";
import Help from "./components/Help/Help";
import AppContext from "./utils/context";
import Register from "./components/Login/Register/Register";
import AllCategoriesMain from "./components/AllCategoriesMain/AllCategoriesMain";
import ForgotPassword from "./components/Login/ForgotPassword/ForgotPassword"
import OrderSuccessFull from "./components/CommonComponets/OrderSuccessFull/OrderSuccessFull";
import AllCoupouns from "./components/AllCoupouns/AllCoupouns";
import SearchResults from "./components/SearchResults/SearchResults";


function App() {
    // const location = useLocation();
    // const isLoginPage = location.pathname === '/login';
    return (
        <>
        <AppContext>
                <ScrollToTop />
                <div>
                    <Header />
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/about" element={<AboutUs />} />
                        <Route path="/allcategories/:id" element={<AllCategories />} />
                        <Route path="/allcategoriesmain" element={<AllCategoriesMain />} />
                        <Route path="/blog" element={<Blog />} />
                        <Route path="/blogdetails/:id" element={<BlogDetails />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/forgot" element={<ForgotPassword />} />
                        <Route path="/contactus" element={<ContactUs />} />
                        <Route path="/product-detail/:id" element={<ProductDetail />} />
                        <Route path="/add-cart" element={<AddToCart />} />
                        <Route path="/checkout-form" element={<CheckoutForm />} />
                        <Route path="/my-account" element={<MyAccount />} />
                        <Route path="/my-wishlist" element={<MyWishList />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/terms-condition" element={<TermsCondition />} />
                        <Route path="/order-success" element={<OrderSuccessFull />} />
                        <Route path="/help" element={<Help />} />
                        <Route path="/allcoupouns" element={<AllCoupouns />} />
                        <Route path="/searchresults/:name" element={<SearchResults />} />
                    </Routes>
                    <Footer />
                </div>
        </AppContext>         
        </>
    );
}

export default App;
