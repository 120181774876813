import React, { useContext } from 'react'
import './BlogDetailsMain.css'
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Form } from 'react-bootstrap';
import ButtonCommon from '../../../CommonComponets/ButtonCommon/ButtonCommon';
import { Context } from '../../../../utils/context';
import parse from 'html-react-parser';
import HTMLReactParser from 'html-react-parser/lib/index';



function BlogDetailsMain({blogdet}) {
    // const text1 = "POST BY: WPTHEMEGO / OCTOBER 14, 2016 / 0 COMMENTS / BLOG";
    // const heading1 = "Ullamco laboris nisi ut aliquip ex";
    // const text2 = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.";
    // const text3 = "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";
    // const text4 = "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable.";
    // const heading2 = "Lorem Khaled Ipsum is a Major Key to Success";
    // const text5 = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";
    // const subheading3 = "Lorem Khaled Ipsum is a Major Key to Success";
    // const text6 = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, erat in malesuada aliquam, est erat faucibus purus, eget viverra nulla sem vitae neque. Quisque id sodales libero. In nec enim nisi, in ultricies quam. Sed lacinia feugiat velit, cursus molestie lectus.";
    // const text7 = "Eget viverra nulla sem vitae neque";
    // const text8 = "Consectetur adipiscing elit";
    // const text9 = "The airport with a casual ganji";
    // const heading3 = "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.";
    // const text10 = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, erat in malesuada aliquam, est erat faucibus purus, eget viverra nulla sem vitae neque. Quisque id sodales libero. In nec enim nisi, in ultricies quam. Sed lacinia feugiat velit, cursus molestie lectus.";
    // const text11 = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, erat in malesuada aliquam, est erat faucibus purus, eget viverra nulla sem vitae neque. Quisque id sodales libero. In nec enim nisi, in ultricies quam. Sed lacinia feugiat velit, cursus molestie lectus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, erat in malesuada aliquam, est erat faucibus purus, eget viverra nulla sem vitae neque. Quisque id sodales libero. In nec enim nisi, in ultricies quam. Sed lacinia feugiat velit, cursus molestie lectus.";
    // const text12 = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, erat in malesuada aliquam, est erat faucibus purus, eget viverra nulla sem vitae neque. Quisque id sodales libero. In nec enim nisi, in ultricies quam. Sed lacinia feugiat velit, cursus molestie lectus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, erat in malesuada aliquam, est erat faucibus purus, eget viverra nulla sem vitae neque. Quisque id sodales libero. In nec enim nisi, in ultricies quam. Sed lacinia feugiat velit, cursus molestie lectus.";
    // const image1 = process.env.PUBLIC_URL + "/assets/Image/blog/blogimage.jpg";
    // const image2 = process.env.PUBLIC_URL + "/assets/Image/blog/blog4.jpg";
    // const image3 = process.env.PUBLIC_URL + "/assets/Image/blog/blog3.jpg";
    // const image4 = process.env.PUBLIC_URL + "/assets/Image/blog/blog2.jpg";


    const {IMG_URL} = useContext(Context);

    return (
        <>
            <section className='blogdetailmain_section'>
                <div className='blogdtaimain_div'>
                    <div className='topimage_div mb-3'>
                        <img src={IMG_URL + blogdet?.image} className='blogdeta_img' alt="...Loading" />
                    </div>
                    <div className='blogtext_div mb-4'>
                        {/* <p className='small-text'>{blogdet?.createdAt}</p> */}
                        <p className='small-text'>{blogdet?.createdAt ? new Date(blogdet.createdAt).toLocaleString() : ''}</p>

                        <h4 className='main-title mb-3'>{blogdet?.name}</h4>
                        {/* <p className='text'>{HTMLReactParser(blogdet?.long_description)}</p> */}

                        <p className='text'>{blogdet?.long_description ? HTMLReactParser(blogdet.long_description) : 'Description not available.'}</p>
                        {/* <p className='text'>{text3}</p>
                        <p className='text'>{text4}</p>
                        <h4 className='main-title  mb-3'>{heading2}</h4>
                        <p className='text'>{text5}</p> */}
                    </div>

                    {/* <div>
                        <Row>
                            <Col xxl={6} xl={6}>
                                <div className='topimage_div mb-3'>
                                    <img src={image4} className='blogdeta_img' alt="...loading" />
                                </div>
                            </Col>
                            <Col xxl={6} xl={6}>
                                <div className=''>
                                    <h6 className='title mb-3'>{subheading3}</h6>
                                    <p className='text'>{text6}</p>
                                    <ol>
                                        <li className='text'>{text7}</li>
                                        <li className='text'>{text8}</li>
                                        <li className='text'>{text9}</li>
                                    </ol>

                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='mt-2 mb-4'>
                        <h4 className='main-title mb-4'>{heading3}</h4>
                        <p className='text'>{text10}</p>
                        <p className='text'>{text11}</p>
                    </div>

                    <div>
                        <Row>
                            <Col xxl={6} xl={6}>
                                <div className='topimage_div mb-4'>
                                    <img src={image2} className='blogdeta_img' alt="...loading" />
                                </div>
                            </Col>
                            <Col xxl={6} xl={6}>
                                <div className='topimage_div mb-4'>
                                    <img src={image3} className='blogdeta_img' alt="...loading" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='mb-3 mt-3'>
                        <p className='text '>{text12}</p>
                    </div>
                    <div className='icondivs mb-5'>
                        <div className='d-flex'>
                            <div className='text fashiontag me-2'>
                                fashion
                            </div>
                            <div className='text fashiontag'>
                                news
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='icontag me-2'>
                                <FontAwesomeIcon icon={faFacebookF} />
                            </div>
                            <div className='icontag'>
                                <FontAwesomeIcon icon={faTwitter} />
                            </div>
                        </div>
                    </div> */}

                    <div className='leavecommentdiv mb-5'>
                        <h4 className='title'>Leave A Comment</h4>
                        <p className='text'>Logged in as rakhi.deshmukh. Edit your profile. Log out? Required fields are marked *</p>

                        <Form>
                            <Form.Control as="textarea" rows={5} placeholder='Your Comment' />

                           
                            <ButtonCommon text="Submit" className='commentbtn'/>
                        </Form>

                        

                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogDetailsMain