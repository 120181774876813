import React, {useState} from "react";
import "./IncreamentDecreament.css";
import { CartQuantity, RemoveToCart } from "../../../utils/apis/addToCart/addToCart";

const IncreamentDecreament = ({ quantity, id, cartGet, removeFromCart }) => {
    const [counter, setCounter] = useState(quantity);
    const [buttonStatus, setButtonStatus] = useState("true");
console.log(id, "quantity");
    async function increment(counter) {
        counter++;
        setButtonStatus("");
        await CartQuantity(id, "add");
        setCounter(counter);
        cartGet();
    }

    async function decrement(counter) {
        counter--;
        if (counter === 0) {
            await removeFromCart();
        } else {
            await CartQuantity(id, "minus");
        }
        setCounter(counter);
        cartGet();
        if (counter < 1) {
            setButtonStatus("true");
        }
    }
    return (
        <>
            <div className="Incre_Decre_btn scale-up-hor-right">
                <div className="add-cart-box">
                    <button className="minus" disabled={buttonStatus} onClick={() => decrement(counter)}>
                        -
                    </button>

                    <span className="quantity p-2">{counter}</span>

                    <button className="add" onClick={() => increment(counter)}>
                        +
                    </button>
                </div>
            </div>
        </>
    );
};

export default IncreamentDecreament;
