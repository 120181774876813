import React, { useEffect, useState } from 'react';
import '../../CheckoutForm/CheckoutForm.css';
import { Col, Form, Row, InputGroup, Button } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import classNames from "classnames";
import { RegxExpression } from '../../../utils/common';
import { UserEdit, UserGet } from '../../../utils/apis/myAccount/myAccount';
import ButtonCommon from '../../CommonComponets/ButtonCommon/ButtonCommon';

const AccountDetails = () => {
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [editStatus, setEditStatus] = useState(true);
    const [userData, setUserData] = useState();

    const togglePasswordVisibility = (setter) => {
        setter(prevState => !prevState);
    };

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        setValue,
        clearErrors,
        formState: { errors },
        reset,
        trigger,
    } = useForm({
        defaultValues: {
            address_type: "Home",
        },
    });

    const onSubmit = async (data) => {
        const res = await UserEdit(data);
        if (res?.success) {
            setTimeout(() => {
                setEditStatus(true);
            }, 3000);
        } else {
            if (res?.message?.both) {
                setError("email", { message: res?.message?.both });
                setError("contact_no", { message: "" });
            }
            if (res?.message?.email) {
                setError("email", { message: res?.message?.email });
            }
            if (res?.message?.contact_no) {
                setError("contact_no", { message: res?.message?.contact_no });
            }
        }
    };


    
    const getUser = async () => {
        const res = await UserGet();
        if (res?.success) {
            setUserData(res.data);
            reset(res?.data);
        }
    };

    console.log(userData, "userData");

    useEffect(() => {
        getUser();
        // AddressGet();
    }, []);


    return (
        <section className='checkout-form'>
            <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col lg={6} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-3" controlId="formFirstName">
                                    <Form.Label>First name <span className='red-star'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Your Name"
                                        disabled={editStatus}
                                        {...register("first_name", {
                                            required: "First name required",
                                        })}
                                        className={classNames({
                                            "is-invalid": errors?.first_name && !editStatus,
                                            "is-valid": getValues("first_name") && !editStatus,
                                        })}
                                        onKeyDown={(event) => {
                                            if (!RegxExpression.name.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-3" controlId="formLastName">
                                    <Form.Label>Last name <span className='red-star'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled={editStatus}
                                        placeholder="Enter Last Name"
                                        {...register("last_name", {
                                            required: "Last name required",
                                        })}
                                        className={classNames({
                                            "is-invalid": errors?.last_name && !editStatus,
                                            "is-valid": getValues("last_name") && !editStatus,
                                        })}
                                        onKeyDown={(event) => {
                                            if (!RegxExpression.name.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-3" controlId="formDisplayName">
                                    <Form.Label>Phone No <span className='red-star'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled={editStatus}
                                        placeholder="Enter Mobile Number"
                                        {...register("contact_no", {
                                            required: "Field is required",
                                            minLength: {
                                                value: 10,
                                                message: "Number should be at least 10 characters",
                                            },
                                        })}
                                        className={classNames({
                                            "is-invalid": errors?.contact_no && !editStatus,
                                            "is-valid": getValues("contact_no") && !editStatus,
                                        })}
                                        onKeyDown={(event) => {
                                            if (!RegxExpression.phone.test(event.key)) {
                                                if (event.key !== "Backspace") {
                                                    event.preventDefault();
                                                }
                                            }
                                        }}
                                        maxLength={10}
                                    />
                                    {/* <p>This will be how your name will be displayed in the account section and in reviews</p> */}
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-3" controlId="formEmailAddress">
                                    <Form.Label>Email address<span className='red-star'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        disabled={editStatus}
                                        placeholder="Enter Email"
                                        {...register("email", {
                                            required: "Email is required",
                                            pattern: {
                                                value: RegxExpression.email,
                                                message: "Invalid email address",
                                            },
                                        })}
                                        className={classNames({
                                            "is-invalid": errors?.email && !editStatus,
                                            "is-valid": getValues("email") && !editStatus,
                                        })}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="text-end">
                            <ButtonCommon
                                buttontype="button"
                                text="Edit"
                                className="editbtn me-2"
                                onClick={() => setEditStatus(false)}
                            >
                                Edit
                            </ButtonCommon>
                            <ButtonCommon text="Update Profile" type="submit" className="updatebtn" />
                        </div>
                        </Form>
                        <h5 className='check-title mt-4'>Password change</h5>
                        <div className='brown-border'></div>
                   
                    <Form>
                        <Row>

                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-3" controlId="formCurrentPassword">
                                    <Form.Label>Current password (leave blank to leave unchanged)</Form.Label>
                                    <InputGroup>
                                        <Form.Control type={showCurrentPassword ? "text" : "password"} />
                                        <Button variant="secondary" onClick={() => togglePasswordVisibility(setShowCurrentPassword)}>
                                            {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-3" controlId="formNewPassword">
                                    <Form.Label>New password (leave blank to leave unchanged)</Form.Label>
                                    <InputGroup>
                                        <Form.Control type={showNewPassword ? "text" : "password"} />
                                        <Button variant="secondary" onClick={() => togglePasswordVisibility(setShowNewPassword)}>
                                            {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Form.Group className="mb-3" controlId="formConfirmPassword">
                                    <Form.Label>Confirm new password</Form.Label>
                                    <InputGroup>
                                        <Form.Control type={showConfirmPassword ? "text" : "password"} />
                                        <Button variant="secondary" onClick={() => togglePasswordVisibility(setShowConfirmPassword)}>
                                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>


                    </Form>
                </Col>
            </Row>
        </section>
    );
};

export default AccountDetails;
