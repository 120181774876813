import React, { useContext, useEffect, useState } from "react";
import "./FeaturedProducts.css";
import { Container, Nav, Tab } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import Product from "../../CommonComponets/Product/Product";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../utils/context";
import { GetCategoriesCards } from "../../../utils/apis/master/Master";
import { Products } from "../../../utils/apis/Product/Product";

function FeaturedProducts() {
    const [swiperInstance, setSwiperInstance] = useState(null);
    const { sellerId, IMG_URL } = useContext(Context);
    const [categoriesCards, setCategoriesCards] = useState([]);
    const [product, setProduct] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    useEffect(() => {
        getCategoriesCards();
    }, []);

    useEffect(() => {
        if (selectedCategoryId !== null) {
            getProduct();
        }
    }, [selectedCategoryId]);

    const getCategoriesCards = async () => {
        try {
            const res = await GetCategoriesCards(sellerId);
            if (res?.success) {
                setCategoriesCards(res?.data);
                // Set the first category as the default selected category
                const firstCategoryId = res.data[0]?.category_id;
                setSelectedCategoryId(firstCategoryId);
            }
        } catch (error) {
            console.error("Error fetching categories cards:", error);
        }
    };

    const getProduct = async () => {
        try {
            const res = await Products(selectedCategoryId, "", "", sellerId);
            if (res?.success) {
                setProduct(res.data?.data);
            }
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    const handleSelect = (categoryId) => {
        setSelectedCategoryId(categoryId);
    };

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    return (
        <section className="feature_section mb-4">
            <Container>
                <div className="main_feature_div">
                    {categoriesCards.length > 0 && (
                        <Tab.Container id="left-tabs-example" defaultActiveKey={categoriesCards[0].id.toString()}>
                            <div className="topheaddiv  mb-3">
                                <h4 className="title">Featured Products</h4>
                                <Nav variant="pills">
                                    {categoriesCards.map((category) => (
                                        <Nav.Item key={category.id} onClick={() => handleSelect(category.category_id)}>
                                            <Nav.Link eventKey={category.id.toString()} active={selectedCategoryId === category.category_id}>
                                                {category.name}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                            </div>
                            <Tab.Content>
                                {categoriesCards.map((category) => (
                                    <Tab.Pane key={category.id} eventKey={category.id.toString()}>
                                        <div className="swipmain_div">
                                            <Swiper
                                                spaceBetween={30}
                                                modules={[]}
                                                autoplay={{ delay: 2000 }}
                                                breakpoints={{
                                                    320: { slidesPerView: 1 },
                                                    480: { slidesPerView: 2 },
                                                    640: { slidesPerView: 2 },
                                                    768: { slidesPerView: 3 },
                                                    1024: { slidesPerView: 4 },
                                                    1250: { slidesPerView: 5 },
                                                }}
                                                onSwiper={(swiper) => setSwiperInstance(swiper)}
                                                className="mySwiper"
                                            >
                                                {product.map((pro, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div className="prodban_div">
                                                            <Product
                                                                image={IMG_URL + pro?.thumbnail}
                                                                productId={pro?.id}
                                                                text={pro?.product_title}
                                                                discountprice={pro?.mrp}
                                                                mainprice={pro?.selling_price}
                                                            />
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                            <div className="swiperbutton_div">
                                                <div className="buttondiv" onClick={() => handleMainSliderClick("prev")}>
                                                    <FontAwesomeIcon icon={faChevronLeft} />
                                                </div>
                                                <div className="buttondiv" onClick={() => handleMainSliderClick("next")}>
                                                    <FontAwesomeIcon icon={faChevronRight} />
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Tab.Container>
                    )}
                </div>
            </Container>
        </section>
    );
}

export default FeaturedProducts;
