import React, { useContext } from 'react'
import './MainBlog.css'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { Context } from '../../../utils/context';
import parse from 'html-react-parser';


const blogPosts = [
    {
        id: 1,
        imgSrc: '/assets/Image/blog/2-1.jpg',
        date: 'September 25, 2023',
        author: 'admin',
        title: 'Produce top level purified bottled water',
        description: 'Sed viverra ullamcorper interdum. Cras eu convallis libero. Nulla venenatis vitae odio sed dictum. Mauris volutpat dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.....'
    },
    {
        id: 2,
        imgSrc: '/assets/Image/blog/1-1.jpg',
        date: 'September 25, 2023',
        author: 'admin',
        title: 'How water useful for our body & life',
        description: 'Vivamus non fermentum lacus. Cras vel metus vitae augue fermentum laoreet quis ac turpis. Nunc nisi mi, scelerisque vel dapibus ut, hendrerit et mi. Vqrem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt......'
    },
    {
        id: 3,
        imgSrc: '/assets/Image/blog/10-1.jpg',
        date: 'September 25, 2023',
        author: 'admin',
        title: 'We are promised to deliver bottled water in-time',
        description: 'Donec eu sem dolor. Integer quis lacus faucibus, dapibus felis eu, condimentum urna. Suspendisse commodo ornare velit non vestibulum. Pgrem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.....'
    }
];

const MainBlog = ({blogdata}) => {
    const { IMG_URL, sellerId } = useContext(Context);
    return (
        <section className='main-blog'>
            <Row>
                {blogdata?.data?.map((post,index) => (
                    <Link to={`/blogdetails/${post?.id}`}>
                        <Col lg={12} key={post?.id} className='mb-4'>
                            <div className='main-img-blog'>
                                <img src={IMG_URL + post?.image} className='blog-img' />
                                <div className='white-bggg d-flex'>
                                    <div className='me-2 common-txt'>
                                        <FontAwesomeIcon icon="fa-solid fa-calendar" className='me-2' />{post?.createdAt ? new Date(post?.createdAt).toLocaleString() : ''}
                                    </div>
                                    {/* <div className='common-txt'>
                                        <FontAwesomeIcon icon="fa-solid fa-user" /> {post.author}
                                    </div> */}
                                </div>
                            </div>
                            <div className='mt-5'>
                                <h3 className='blog-common-title'>{post?.name}</h3>
                                <p className='sub-txt'>{parse(post?.description)}</p>
                                <Link to={`/blogdetails/${post?.id}`}><button className='common-btn' type='button'>Read More <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" /></button></Link>
                            </div>
                        </Col>
                    </Link>                  
                ))}
            </Row>
        </section>
    )
}

export default MainBlog
