// LogoutModal.js
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./LogOutModal.css";
const LogoutModal = ({show, onHide, onConfirm, handleLogout}) => {
    return (
        <div className="Log-out-Modal">
            <Modal show={show} onHide={onHide} centered size="sm" className="Log-out-Modal">
                <Modal.Header closeButton>
                    <Modal.Title>Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to log out?</Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={onHide}>
                Cancel
            </Button> */}
                    <Button variant="primary" className="logout" onClick={handleLogout}>
                        Logout
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default LogoutModal;
