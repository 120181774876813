import React, { useEffect, useState } from 'react'
import './Dashboard.css'
import { Link } from 'react-router-dom'
import { UserGet } from '../../../utils/apis/myAccount/myAccount';

const Dashboard = () => {

    const [usergetsData, setUsergetsData] = useState();
    const getUser = async () => {
        const res = await UserGet();
        if (res?.success) {
            setUsergetsData(res.data);
            
        }
    };
    useEffect(() => {
        getUser();
        
    }, []);

    
    console.log();

    return (
        <section>
            <div>
                <p className='sub-txtt'>Hello <span className='sub-title'>{usergetsData?.first_name}{" "}{usergetsData?.last_name}</span> (not <span className='sub-title'>{usergetsData?.first_name} </span>? <span className='log-out-txtt' ><Link to='/log-out'>Log out</Link></span>)</p>
                <p className='sub-txtt'>From your account dashboard you can view your recent orders, manage your shipping and billing addresses, and edit your password and account details.</p>
            </div>
        </section>
    )
}

export default Dashboard