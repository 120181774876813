import React, {useContext, useEffect, useState} from "react";
import "./MyWishList.css";
import {Col, Container, Row, Table} from "react-bootstrap";
import Breadcrumbs from "../CommonComponets/Breadcrumbs/Breadcrumbs";
import Product from "../CommonComponets/Product/Product";
import {Link} from "react-router-dom";
import {faBagShopping, faEye, faHeart} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {Context} from "../../utils/context";
import {getWishlistProducts} from "../../utils/apis/Wishlist/Wishlist";
import Heart_Component from "../CommonComponets/Heart_Component/Heart_Component";

const MyWishList = () => {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        {text: "Home", link: "/"},
        {text: "Mywishlist", link: "#"},
    ]);

    const { IMG_URL } = useContext(Context);
    const [products, setProducts] = useState([]);
    const getList = async () => {
        const res = await getWishlistProducts();
        if (res?.success) {
            setProducts(res?.data);
        }
        console.log(res?.data, "getwishlist");
    };

    useEffect(() => {
        getList();
    }, []);
    return (
        <>
            <Container>
                {products.length === 0 ? (
                    <>
                        <div className="heading_holder mt-3">
                            <h5 className="title">Your wishlist is Empty</h5>
                            <p className="sub-text">
                                You have no items in your shopping wishlist. Let's go add something
                            </p>
                            <div className="main-btn-div">
                                <Link to={"/"}>
                                    <div className="Add">
                                        <button className="add-btn" type="button">
                                            Add Now
                                        </button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <section className="product_section">
                        <h1 className="title">Wishlist</h1>
                            <Row className="">
                                {products?.map((item, index) => (
                                    <Col xxl={3} xl={3} lg={4} md={4} sm={6} xs={12} key={index}>
                                    <Link to={`/product-detail/${item.id}`}>
                                        <div className="product_main_div">
                                            <div className="producctmain_img">
                                                <img src={IMG_URL + item.thumbnail} className="productimg" />
                                            </div>
                                            <div className="producttext_div text-center">
                                                {/* <ReactStars className='ratingstart' /> */}
                                                <div className="productname_div text-center">
                                                    <p className="productname">{item.product_title}</p>
                                                </div>
                                              <div className="main">
                                                    <span className="discountprice">₹{item.selling_price}</span>
                                                    <span className="mainprice">₹{item.mrp}</span>
                                                    </div>
                                            </div>
                                            <div className="iconsproductsss">
                                                {/* <Link to={`/product-details/${productId}`}> */}
                                                <div className="iconsproduct iconprod1">
                                                    <Heart_Component productId={item?.id} getList={getList} />
                                                </div>
                                                {/* </Link> */}
                                                <div className="iconsproduct  iconprod2">
                                                    <FontAwesomeIcon icon={faEye} className="icnpro" />
                                                </div>
                                                <div className="iconsproduct  iconprod2">
                                                    <FontAwesomeIcon icon={faBagShopping} className="icnpro" />
                                                </div>
                                            </div>
                                        </div>
                                        </Link>
                                    </Col>
                                ))}
                            </Row>
                        </section>
                    </>
                )}
            </Container>
        </>
        //     </Container>
        // </section>
    );
};

export default MyWishList;
