import React, {useContext, useEffect, useState} from "react";
import "./AddToCart.css";
import Breadcrumbs from "../CommonComponets/Breadcrumbs/Breadcrumbs";
import {Col, Container, Form, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IncreamentDecreament from "../CommonComponets/IncreamentDecreament/IncreamentDecreament";
import {Link, useNavigate} from "react-router-dom";
import {Context} from "../../utils/context";
import {RemoveToCart, getCart} from "../../utils/apis/addToCart/addToCart";
import Cookies from "js-cookie";
import { getCoupansdata } from "../../utils/apis/Product/Product";

const AddToCart = () => {
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {text: "Home", link: "/"},
        {text: "Cart", link: "#"},
    ]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [coupanId, setCoupanId] = useState(null);
    const [cartsignin, setCartsignin] = useState([]);
    const [couponDetail, setCouponDetail] = useState(null);
    const [couponCode, setCouponCode] = useState('');
    const [discountinfo, setDiscountinfo] = useState(null);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [message, setMessage] = useState('');
    const [discountedPrice, setDiscountedPrice] = useState(totalPrice);
    const [discountTotal, setDiscountTotal] = useState(0);
    const [discountPrice, setDiscountPrice] = useState(null);
  
    console.log(discountedPrice, "amountcommon");
    const navigate = useNavigate();
    const {IMG_URL, signin, sellerId, triggercart} = useContext(Context);
    const [cart, setCart] = useState([]);

    const cartGet = async () => {
        if (signin) {
            const res = await getCart(sellerId);
            if (res?.success) {
                setCartsignin(res?.data);
            }
        } else {
            if (Cookies.get("ecommerce3_cart")) {
                setCart(JSON.parse(Cookies.get("ecommerce3_cart")));
            }
        }
    };

    useEffect(() => {
        cartGet();
    }, [signin, sellerId, triggercart]);

    useEffect(() => {
        const calculateTotalPrice = () => {
            let price = 0;
            if (signin) {
                cartsignin.forEach((product) => {
                    price += product.selling_price * product.quantity;
                });
            } else {
                cart.forEach((product) => {
                    price += product.price * product.quantity;
                });
            }
            setTotalPrice(price);
        };

        calculateTotalPrice();
    }, [cartsignin, cart, signin]);

    const removeFromCart = async (id, index) => {
        if (signin) {
            await RemoveToCart(id);
            cartGet();
        } else {
            let updatedCart = [...cart];
            updatedCart.splice(index, 1);
            setCart(updatedCart);
            Cookies.set("ecommerce3_cart", JSON.stringify(updatedCart));
        }
        triggercart();
    };



    const applyClick = async () => {
        const res = await getCoupansdata(couponCode, totalPrice);
        if (res?.success) {
          const coupon = res.data;
          setCouponDetail(coupon);
          setCoupanId(coupon?.id);
          setDiscountinfo(coupon?.discount);
    
          let newDiscountedPrice = totalPrice;
          let newDiscountAmount = 0;
    
          if (coupon.type === 'percentage') {
            newDiscountAmount = totalPrice * parseFloat(coupon.discount) / 100;
          } else {
            newDiscountAmount = parseFloat(coupon.total_amount);
          }
    
          newDiscountedPrice = totalPrice - newDiscountAmount;
    
          setDiscountedPrice(newDiscountedPrice);
          setDiscountTotal(newDiscountedPrice);
          setDiscountAmount(newDiscountAmount);
          setDiscountPrice(newDiscountedPrice)
    
        } else {
          setMessage('Invalid coupon code');
          setDiscountedPrice(totalPrice);
          setDiscountPrice(totalPrice)
          setDiscountAmount(0);
        }
      };
    
      useEffect(() => {
        if (totalPrice <= 1000) {
          setMessage('The total price is less than or equal to 1000');
        } else {
          setMessage('');
        }
      }, [totalPrice]);
    
      useEffect(() => {
        setDiscountedPrice(totalPrice);
      }, [totalPrice]);

    return (
        <>
            {cart.length === 0 && cartsignin.length === 0 ? (
                <div className="heading_holder">
                    <h5 className="title">Your cart is Empty</h5>
                    <p className="sub-text">You have no items in your shopping cart. Let's go buy something</p>
                    <div className="main-btn-div">
                        <Link to={"/"}>
                            <div className="Shop">
                                <button className="back-btn" type="button">
                                    Shop Now
                                </button>
                            </div>
                        </Link>
                    </div>
                </div>
            ) : (
                <section className="add-to-cart">
                    <Container>
                        <Row>
                            <Breadcrumbs items={breadcrumbItems} />
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>Product</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>SubTotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {signin
                                        ? cartsignin.map((product, index) => (
                                              <tr key={product.id}>
                                                  <td>
                                                      <FontAwesomeIcon
                                                          icon="fa-solid fa-xmark"
                                                          className="cross-button"
                                                          onClick={() => removeFromCart(product.cart_id)}
                                                      />
                                                  </td>
                                                  <td>
                                                      <div className="image-product">
                                                          <img
                                                              src={IMG_URL + product.thumbnail}
                                                              className="apl-img"
                                                              alt={product.product_title}
                                                          />
                                                      </div>
                                                  </td>
                                                  <td className="color-text">{product.product_title}</td>
                                                  <td> ₹{product.selling_price}</td>
                                                  <td>
                                                      <div>
                                                          <IncreamentDecreament
                                                              quantity={product.quantity}
                                                              id={product.cart_id}
                                                              cartGet={cartGet}
                                                              removeFromCart={() =>
                                                                  removeFromCart(product.cart_id, index)
                                                              }
                                                          />
                                                      </div>
                                                  </td>
                                                  <td> ₹{product.selling_price * product.quantity}</td>
                                              </tr>
                                          ))
                                        : cart?.map((product, index) => (
                                              <tr key={product.id}>
                                                  <td>
                                                      <FontAwesomeIcon
                                                          icon="fa-solid fa-xmark"
                                                          className="cross-button"
                                                          onClick={() => removeFromCart(product.id, index)}
                                                      />
                                                  </td>
                                                  <td>
                                                      <div className="image-product">
                                                          <img
                                                              src={IMG_URL + product.image}
                                                              className="apl-img"
                                                              alt={product.name}
                                                          />
                                                      </div>
                                                  </td>
                                                  <td className="color-text">{product.name}</td>
                                                  <td> ₹{product.price}</td>
                                                  <td>
                                                      <div>
                                                          <IncreamentDecreament
                                                              quantity={product.quantity}
                                                              id={product.product_id}
                                                              cartGet={cartGet}
                                                              removeFromCart={() => removeFromCart(product.id, index)}
                                                          />
                                                      </div>
                                                  </td>
                                                  <td> ₹{product.price * product.quantity}</td>
                                              </tr>
                                          ))}
                                    <tr className="border">
                                        <td colSpan={6}>
                                            <div className="d-flex mt-3">
                                                <Form>
                                                    <Form.Group
                                                        className="mb-3 me-2"
                                                        controlId="exampleForm.ControlInput1"
                                                    >
                                                        <Form.Control type="text" placeholder="Coupon Code"  value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
                                                    </Form.Group>
                                                </Form>
                                                <div className="button-holder">
                                                    <button className="apply-coupon me-2"  onClick={applyClick}>APPLY COUPON</button>
                                                </div>
                                                <div className="button-holder">
                                                    <button className="apply-coupon">UPDATE CART</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Col lg={10}>
                                <div className="heading-holder">
                                    <h2>CART TOTALS</h2>
                                </div>
                                <div className="cart-total mt-4">
                                    <div className="d-flex">
                                        <div className="text-heading">
                                            <h4>Subtotal</h4>
                                        </div>
                                        <div className="text-heading ms-auto">
                                            <p>₹{totalPrice}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="text-heading">
                                            <h4>Shipping</h4>
                                        </div>
                                        <div className="text-heading ms-auto">
                                            <p>
                                                <b>Flat Rate</b>
                                            </p>
                                            <p>
                                                Shipping To <b>All</b>
                                            </p>
                                            <p>
                                                Change Address
                                                <FontAwesomeIcon icon="fa-solid fa-truck" className="ms-2" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="total-text">
                                    <div className="d-flex">
                                        <div className="text-heading">
                                            <h4>Total</h4>
                                        </div>
                                        <div className="text-heading ms-auto">
                                            <p className="total-price">₹{totalPrice}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="button-holder mt-3">
                                    {signin && (
                                        <Link to="/checkout-form">
                                            <button className="apply-coupon">PROCEED TO CHECKOUT</button>
                                        </Link>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            )}
        </>
    );
};

export default AddToCart;
