import React, {useContext, useEffect, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation} from "swiper/modules";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "./ProductSlider.css";
import {Tooltip, OverlayTrigger, Button} from "react-bootstrap";
import {Rating} from "react-simple-star-rating";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Heart_Component from "../../CommonComponets/Heart_Component/Heart_Component";
// import ButtonCommon from "../../CommonComponents/ButtonCommon/ButtonCommon";
import {
    faAngleLeft,
    faAngleRight,
    faCircleCheck,
    faLock,
    faCodeCompare,
    faHeart,
    faQuestion,
} from "@fortawesome/free-solid-svg-icons";
// import IncreamentDecreament from "../../CommonComponent/IncreamentDecreament/IncreamentDecreament";
import {faFacebook, faTwitter, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {Link, useNavigate, useParams} from "react-router-dom";
import CountUp from "react-countup";
import ButtonCommon from "../../CommonComponets/ButtonCommon/ButtonCommon";
import IncreamentDecreament from "../../CommonComponets/IncreamentDecreament/IncreamentDecreament";
import {useForm} from "react-hook-form";
import {getProductDetail} from "../../../utils/apis/Product/Product";
import {Context} from "../../../utils/context";
import {AddToCart, RemoveToCart, getCartCheck} from "../../../utils/apis/addToCart/addToCart";

const Default = {
    initialZoom: 3,
    minZoom: 1.25,
    maxZoom: 4,
    zoomSpeed: 0.01,
};

class Zoomable {
    constructor(element, config) {
        this.element = element;
        this.config = this._mergeConfig(config);

        const {initialZoom, minZoom, maxZoom} = this.config;

        this.zoomed = false;
        this.initialZoom = Math.max(Math.min(initialZoom, maxZoom), minZoom);
        this.zoom = this.initialZoom;

        this.img = element.querySelector(".zoomable__img");
        this.img.draggable = false;
        this.element.style.setProperty("--zoom", this.initialZoom);

        this._addEventListeners();
    }

    static get Default() {
        return Default;
    }

    _addEventListeners() {
        this.element.addEventListener("mouseenter", () => this._handleMouseover());
        this.element.addEventListener("mousemove", (evt) => this._handleMousemove(evt));
        this.element.addEventListener("mouseleave", () => this._handleMouseout());
        this.element.addEventListener("wheel", (evt) => this._handleWheel(evt));
    }

    _handleMouseover() {
        if (this.zoomed) {
            return;
        }

        this.element.classList.add("zoomable--zoomed");
        this.zoomed = true;
    }

    _handleMousemove(evt) {
        if (!this.zoomed) {
            return;
        }

        const elPos = this.element.getBoundingClientRect();
        const percentageX = `${((evt.clientX - elPos.left) * 100) / elPos.width}%`;
        const percentageY = `${((evt.clientY - elPos.top) * 100) / elPos.height}%`;

        this.element.style.setProperty("--zoom-pos-x", percentageX);
        this.element.style.setProperty("--zoom-pos-y", percentageY);
    }

    _handleMouseout() {
        if (!this.zoomed) {
            return;
        }

        this.element.style.setProperty("--zoom", this.initialZoom);
        this.element.classList.remove("zoomable--zoomed");
        this.zoomed = false;
    }

    _handleWheel(evt) {
        if (!this.zoomed) {
            return;
        }

        evt.preventDefault();

        const newZoom = this.zoom + evt.deltaY * (this.config.zoomSpeed * -1);
        const {minZoom, maxZoom} = this.config;

        this.zoom = Math.max(Math.min(newZoom, maxZoom), minZoom);
        this.element.style.setProperty("--zoom", this.zoom);
    }

    _mergeConfig(config) {
        return {
            ...this.constructor.Default,
            ...(typeof config === "object" ? config : {}),
        };
    }
}

const ProductSlider = ({reviewdetail, IMG_URL, sellerId, id, average}) => {
    // const [modalShowone, setModalShowone] = React.useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const [cartsuccess, setCartsuccess] = useState(false);

    const colorsd = ["#000", "#33FF57", "#3357FF", "#F333FF"];

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
    };
    const swiperRef = useRef(null);
    const [swiperInstance, setSwiperInstance] = useState(null);

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };
    const zoomableRef = useRef(null);

    useEffect(() => {
        const element = zoomableRef.current;
        if (!element) return;

        const zoomable = new Zoomable(element);

        return () => {
            // Clean up event listeners when component unmounts
            element.removeEventListener("mouseenter", zoomable._handleMouseover);
            element.removeEventListener("mousemove", zoomable._handleMousemove);
            element.removeEventListener("mouseleave", zoomable._handleMouseout);
            element.removeEventListener("wheel", zoomable._handleWheel);
        };
    }, []);

    const slidesData = [
        {
            image: "/assets/Image/product/graphes.jpg",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assets/Image/product/graphes.jpg"),
        },
        {
            image: "/assets/Image/product/peach.jpg",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assets/Image/product/peach.jpg"),
        },
        {
            image: "/assets/Image/product/spinach.jpg",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assets/Image/product/spinach.jpg"),
        },
        {
            image: "/assets/Image/product/tomato.jpg",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assets/Image/product/tomato.jpg"),
        },
        {
            image: "/assets/Image/product/graphes.jpg",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assets/Image/product/graphes.jpg"),
        },
        {
            image: "/assets/Image/product/peach.jpg",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assets/Image/product/peach.jpg"),
        },
    ];
    const [number, setNumber] = useState(1);
    const handleMinusClick = () => {
        if (number > 1) {
            setNumber(number - 1);
        }
    };

    const handlePlusClick = () => {
        setNumber(number + 1);
    };

    const [showButtons, setShowButtons] = useState(false);
    const [show, setShow] = useState(false);

    const [heartactive, setheartActive] = useState(false);
    const handleClickes = ({data}) => {
        setheartActive(!heartactive);
    };

    // Rating star
    const [rating, setRating] = useState(0);

    // Catch Rating value
    const handleRating = (rate: number) => {
        setRating(rate);

        // other logic
    };
    // Optinal callback functions
    const onPointerEnter = () => console.log("Enter");
    const onPointerLeave = () => console.log("Leave");
    const onPointerMove = (value: number, index: number) => console.log(value, index);

    const [modalShow, setModalShow] = useState(false);
    const [images, setImages] = useState([]);
    // const {id}= useParams();
    console.log("iddd", id);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setValue,
        setError,
        reset,
        formState: {errors},
    } = useForm();

    const [singleVariant, setSingleVariant] = useState(false);
    const [MultipleVariantTwo, setMultipleVariantTwo] = useState(false);
    const [MultipleVariantThree, setMultipleVariantThree] = useState(false);

    const [data, setData] = useState({});

    const getProductData = async (id) => {
        {
            const res = await getProductDetail(id, sellerId);
            if (res?.success) {
                setData(res.data);

                if (res?.data?.s_product_variant?.variant_type === "Single") {
                    setData(res.data);
                    setValue("product_title", res.data?.product_title);
                    setValue("customization", res.data?.s_product_customization?.s_customization?.name);
                    setValue("variant", res.data?.s_product_variant?.s_product_variant_attribute?.s_attribute?.name);
                    setValue("unit", res.data?.s_product_variant?.s_product_variant_attribute?.s_attribute?.defind);
                    setValue(
                        "variant_details",
                        res.data?.s_product_variant?.s_product_variant_attribute?.s_product_variant_attribute_details
                    );

                    await setValue(
                        "activeVariant",
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.id
                    );

                    setValue(
                        "sku_id",
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.sku_id
                    );

                    setValue(
                        "s_p_v_a_d_id",
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.id
                    );

                    setValue(
                        "mrp",
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock?.mrp
                    );
                    setValue(
                        "selling_price",
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_price_a_stock?.selling_price
                    );

                    setSingleVariant(true);
                    setImages(
                        res.data?.s_product_variant?.s_product_variant_attribute
                            ?.s_product_variant_attribute_details?.[0]?.s_p_v_a_d_images
                    );
                } else if (res?.data?.s_product_variant?.variant_type === "Multiple") {
                    if (
                        res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                            ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as.length == 0
                    ) {
                        setData(res.data);
                        setValue("product_title", res.data?.product_title);
                        setValue("customization", res.data?.s_product_customization?.s_customization?.name);
                        setValue("variant", res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name);
                        setValue("variant_details", res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds);

                        setValue(
                            "variant_one",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_attribute?.name
                        );

                        setValue(
                            "variant_one_details",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds
                        );

                        await setValue(
                            "activeVariant",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.id
                        );

                        await setValue(
                            "activeSubVariant",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]?.id
                        );

                        setValue(
                            "sku_id",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]?.sku_id
                        );
                        setValue(
                            "s_p_v_a_one_d_a_d_id",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]?.id
                        );
                        setValue(
                            "mrp",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                        );
                        setValue(
                            "selling_price",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
                        );
                        setMultipleVariantTwo(true);
                        setImages(
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_a_d_images
                        );
                    } else {
                        setData(res.data);
                        setValue("product_title", res.data?.product_title);
                        setValue("customization", res.data?.s_product_customization?.s_customization?.name);
                        setValue("variant", res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_attribute?.name);
                        setValue("variant_details", res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds);

                        setValue(
                            "variant_one",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_attribute?.name
                        );

                        setValue(
                            "variant_one_details",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds
                        );

                        setValue(
                            "variant_two",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute?.name
                        );

                        setValue(
                            "variant_two_details",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds
                        );

                        setValue(
                            "sku_id",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                ?.sku_id
                        );
                        setValue(
                            "s_p_v_a_o_d_a_d_a_d_id",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]?.id
                        );
                        setValue(
                            "mrp",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                        );

                        setValue(
                            "selling_price",
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price
                        );

                        setImages(
                            res.data?.s_product_variant?.s_p_v_a_ones?.[0]?.s_p_v_a_one_ds?.[0]?.s_p_v_a_one_d_as?.[0]
                                ?.s_p_v_a_one_d_a_ds?.[0]?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                ?.s_p_v_a_one_d_a_d_a_d_images
                        );
                        setMultipleVariantThree(true);
                    }
                }
            }
        }
    };

    const onChangeVariant = async (data, type = "Single", level = "1") => {
        if (type === "Single" && level === "1") {
            setValue("sku_id", data?.sku_id);
            setValue("mrp", data?.s_p_v_a_d_price_a_stock?.mrp);
            setValue("selling_price", data?.s_p_v_a_d_price_a_stock?.selling_price);
            setValue("s_p_v_a_d_id", data?.id);
        } else if (type === "Multiple" && level === "2") {
            setValue("sku_id", data?.sku_id);
            setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
            setValue("selling_price", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price);
            setValue("s_p_v_a_one_d_a_d_id", data?.id);
        } else if (type === "Multiple" && level === "3") {
            setValue("sku_id", data?.sku_id);
            setValue("mrp", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp);
            setValue("selling_price", data?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.selling_price);
            setValue("s_p_v_a_o_d_a_d_a_d_id", data?.id);
        }
    };

    const handleClickSlide = (image) => {
        setSelectedImage(image);
    };

    useEffect(() => {
        if (id) {
            getProductData(id);
        }
    }, [id]);

    //Start

    const [swiperInstancemobile, setSwiperInstancemobile] = useState(null);
    const [swiperInstancenew, setSwiperInstancenew] = useState(null);
    const [showAddedToCart, setShowAddedToCart] = useState(false);
    const [checkExits, setCheckExits] = useState(false);
    const {signin, triggercart} = useContext(Context);
    const navigate = useNavigate();
    const handleAddToCart = async () => {
        const data = {
            product_id: id,
            seller_id: sellerId,
            s_p_v_a_d_id: getValues("s_p_v_a_d_id"),
            s_p_v_a_one_d_a_d_id: getValues("s_p_v_a_one_d_a_d_id"),
            s_p_v_a_o_d_a_d_a_d_id: getValues("s_p_v_a_o_d_a_d_a_d_id"),
            name: getValues("product_title"),
            price: getValues("selling_price"),
            image: images?.[0]?.image,
        };

        console.log(data, "dtatatatat");

        const res = await AddToCart(data);
        triggercart();
        setCartsuccess(true);
        getCartCheckProduct();

        setTimeout(() => {
            setCartsuccess(false);
        }, 3000);
    };

    const BuyNowClick = async () => {
        if (signin) {
            const data = {
                product_id: id,
                seller_id: sellerId,
                s_p_v_a_d_id: getValues("s_p_v_a_d_id"),
                s_p_v_a_one_d_a_d_id: getValues("s_p_v_a_one_d_a_d_id"),
                s_p_v_a_o_d_a_d_a_d_id: getValues("s_p_v_a_o_d_a_d_a_d_id"),
            };

            console.log(data, "dataa");

            triggercart();

            if (!checkExits) {
                await AddToCart(data);
                setShowAddedToCart(true);
                getCartCheckProduct();
            }
            navigate("/add-cart");
        } else {
            navigate("/login");
        }
    };

    const handleRemoveFromCart = async () => {
        const res = await RemoveToCart(checkExits);
        getCartCheckProduct();
        triggercart();
    };

    const getCartCheckProduct = async () => {
        const res = await getCartCheck(id);
        if (res?.success) {
            setCheckExits(res.data.id);
            console.log(res?.data.id, "rr");
        } else {
            setCheckExits(res?.success);
        }
    };
    useEffect(() => {
        if (id) {
            getProductData(id);
            getCartCheckProduct();
        }
    }, [id]);
    return (
        <>
            <section className="ProductInner">
                <Container>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={12}>
                            <div className="row camera-sec">
                                <Zoom>
                                    <div className="card-sec ">
                                        <div className="product-detail text-center">
                                            {selectedImage ? (
                                                <div ref={zoomableRef}>
                                                    <img src={selectedImage} className="zoomable__img" alt="Selected" />
                                                </div>
                                            ) : (
                                                <div className="specs" ref={zoomableRef}>
                                                    <img src={IMG_URL + images?.[0]?.image} className="zoomable__img" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Zoom>

                                <div className="camera-slider p-3">
                                    <div className="row p-0 m-0">
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <Swiper
                                                slidesPerView={"auto"}
                                                modules={[Autoplay]}
                                                onSwiper={(swiper) => setSwiperInstance(swiper)}
                                                breakpoints={{
                                                    0: {
                                                        slidesPerView: 3,
                                                        spaceBetween: 10,
                                                    },
                                                    370: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 10,
                                                    },
                                                    485: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 10,
                                                    },
                                                    575: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 30,
                                                    },

                                                    768: {
                                                        slidesPerView: 6,
                                                        spaceBetween: 20,
                                                    },
                                                    992: {
                                                        slidesPerView: 6,
                                                        spaceBetween: 30,
                                                    },
                                                    1024: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 30,
                                                    },
                                                    1200: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 30,
                                                    },
                                                    1440: {
                                                        slidesPerView: 5,
                                                        spaceBetween: 30,
                                                    },
                                                    2000: {
                                                        slidesPerView: 5,
                                                        spaceBetween: 20,
                                                    },
                                                }}
                                                navigation
                                                pagination={{clickable: true}}
                                                className="mySwiper"
                                            >
                                                {images.map((slide, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div className="Main-div">
                                                            <div
                                                                className="camera-imgs"
                                                                onClick={() => handleClickSlide(IMG_URL + slide?.image)}
                                                            >
                                                                <img
                                                                    src={IMG_URL + slide?.image}
                                                                    className="inner-img"
                                                                />
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                            <div className="silder-btn">
                                                <div className="back-btn" onClick={() => handleMainSliderClick("next")}>
                                                    <FontAwesomeIcon icon={faAngleRight} />
                                                </div>
                                                <div className="next-btn" onClick={() => handleMainSliderClick("prev")}>
                                                    <FontAwesomeIcon icon={faAngleLeft} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={12}>
                            <h1 className="title-product">{getValues("product_title")}</h1>
                            <h1 className="main-title">{getValues("product_title")}</h1>
                            <div className="rating-main">
                                <Rating
                                    initialValue={average}
                                    SVGclassName="star-svg"
                                    fillColor="#ffc107"
                                    size={"25px"}
                                    readonly={true}
                                />
                                <p className="sub-text ms-2 ">{average.toFixed(2)}/5</p>
                            </div>
                            <div className="price-main">
                                <p className="price">₹ {getValues("selling_price")}</p>
                                <p className="main-price">₹ {getValues("mrp")}</p>
                            </div>
                            <div className="in-Stock">
                                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                                <p className="sub-title">In Stock</p>
                            </div>

                            {/* timer section started */}

                            <Row className="mt-2">
                                {singleVariant && (
                                    <>
                                        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                                            <p className="sub-text">{getValues("variant")}</p>
                                        </Col>
                                        <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
                                            <div className="d-flex">
                                                {getValues("variant_details")?.map((item, index) => (
                                                    <div className="box">
                                                        <div className="color">
                                                            <Button
                                                                variant="standard"
                                                                className={
                                                                    getValues("activeVariant") === item?.id
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                                key={item?.id ?? index}
                                                                onClick={async () => {
                                                                    await setValue("activeVariant", item?.id);
                                                                    await onChangeVariant(item, "Single", "1");
                                                                    await setImages(item?.s_p_v_a_d_images);
                                                                }}
                                                            >
                                                                {item?.s_sub_attribute?.name ?? "Unknown"}{" "}
                                                                {getValues("unit")}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                    </>
                                )}

                                {MultipleVariantTwo && (
                                    <>
                                        <div className="standard-section mb-3">
                                            {getValues("variant_details")?.map((item, index) => (
                                                <Button
                                                    variant="standard"
                                                    className={getValues("activeVariant") === item?.id ? "active" : ""}
                                                    key={index}
                                                    onClick={async () => {
                                                        await setValue("activeVariant", item?.id);
                                                        await setValue(
                                                            "activeSubVariant",
                                                            item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]?.id
                                                        );
                                                        await setValue(
                                                            "variant_one",
                                                            item?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name ?? ""
                                                        );

                                                        await setValue(
                                                            "variant_one_details",
                                                            item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds ?? []
                                                        );

                                                        await setImages(
                                                            item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                                                ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                                        );
                                                    }}
                                                >
                                                    {item?.s_sub_attribute?.name ?? "Unknown"}
                                                </Button>
                                            ))}
                                        </div>

                                        <div className="standard-section">
                                            {getValues("variant_one_details")?.map((item, index) => (
                                                <Button
                                                    variant="standard"
                                                    className={
                                                        getValues("activeSubVariant") === item?.id ? "active" : ""
                                                    }
                                                    key={item?.id ?? index}
                                                    onClick={async () => {
                                                        await setValue("activeSubVariant", item?.id);
                                                        onChangeVariant(item, "Multiple", "2");
                                                        setImages(item?.s_p_v_a_one_d_a_d_a_d_images ?? []);
                                                    }}
                                                >
                                                    {item?.s_sub_attribute?.name ?? "Unknown"}
                                                </Button>
                                            ))}
                                        </div>
                                    </>
                                )}

                                {MultipleVariantThree && (
                                    <>
                                        <div className="standard-section mb-3">
                                            {getValues("variant_details")?.map((item, index) => (
                                                <Button
                                                    variant="standard"
                                                    className={getValues("activeVariant") === item?.id ? "active" : ""}
                                                    key={item?.id ?? index}
                                                    onClick={async () => {
                                                        await setValue("activeVariant", item?.id);

                                                        await setValue(
                                                            "activeSubVariant",
                                                            item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]?.id
                                                        );

                                                        await setValue(
                                                            "activeSubChildVariant",
                                                            item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                                ?.id
                                                        );

                                                        await setValue(
                                                            "variant_one",
                                                            item?.s_p_v_a_one_d_as?.[0]?.s_attribute?.name ?? ""
                                                        );

                                                        await setValue(
                                                            "variant_one_details",
                                                            item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds ?? []
                                                        );

                                                        setValue(
                                                            "variant_two",
                                                            item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute?.name
                                                        );

                                                        setValue(
                                                            "variant_two_details",
                                                            item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds
                                                        );

                                                        setValue(
                                                            "sku_id",
                                                            item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                                ?.sku_id
                                                        );
                                                        setValue(
                                                            "mrp",
                                                            item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                                ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                                                        );

                                                        setImages(
                                                            item?.s_p_v_a_one_d_as?.[0]?.s_p_v_a_one_d_a_ds?.[0]
                                                                ?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                                ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                                        );
                                                    }}
                                                >
                                                    {item?.s_sub_attribute?.name ?? "Unknown"}
                                                </Button>
                                            ))}
                                        </div>

                                        <div className="standard-section mb-3">
                                            {getValues("variant_one_details")?.map((item, index) => (
                                                <Button
                                                    variant="standard"
                                                    className={
                                                        getValues("activeSubVariant") === item?.id ? "active" : ""
                                                    }
                                                    key={item?.id ?? index}
                                                    onClick={async () => {
                                                        await setValue("activeSubVariant", item?.id);
                                                        await setValue(
                                                            "activeSubChildVariant",
                                                            item?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                                ?.id
                                                        );
                                                        setValue(
                                                            "variant_two",
                                                            item?.s_p_v_a_one_d_a_d_as?.[0]?.s_attribute?.name
                                                        );

                                                        setValue(
                                                            "variant_two_details",
                                                            item?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds
                                                        );

                                                        setValue(
                                                            "sku_id",
                                                            item?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                                ?.sku_id
                                                        );
                                                        setValue(
                                                            "mrp",
                                                            item?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                                ?.s_p_v_a_o_d_a_d_a_d_p_a_s_d?.mrp
                                                        );

                                                        setImages(
                                                            item?.s_p_v_a_one_d_a_d_as?.[0]?.s_p_v_a_one_d_a_d_a_ds?.[0]
                                                                ?.s_p_v_a_one_d_a_d_a_d_images ?? []
                                                        );
                                                    }}
                                                >
                                                    {item?.s_sub_attribute?.name ?? "Unknown"}
                                                </Button>
                                            ))}
                                        </div>

                                        <div className="standard-section mb-3">
                                            {getValues("variant_two_details")?.map((item, index) => (
                                                <Button
                                                    variant="standard"
                                                    className={
                                                        getValues("activeSubChildVariant") === item?.id ? "active" : ""
                                                    }
                                                    key={item?.id ?? index}
                                                    onClick={async () => {
                                                        await setValue("activeSubChildVariant", item?.id);
                                                        onChangeVariant(item, "Multiple", "3");
                                                        setImages(item?.s_p_v_a_one_d_a_d_a_d_images ?? []);
                                                    }}
                                                >
                                                    {item?.s_sub_attribute?.name ?? "Unknown"}
                                                </Button>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </Row>

                            <Row className="mt-">
                                <div className="buttonsectionmain">
                                    {checkExits ? (
                                        <Button
                                            variant="primary"
                                            className="addtocartbtn"
                                            onClick={handleRemoveFromCart}
                                            a
                                        >
                                            Remove Cart
                                        </Button>
                                    ) : (
                                        <>
                                            <Link to="/add-cart">
                                                <Button className="addtocartbtn" onClick={() => handleAddToCart(id)}>
                                                    Add to Cart
                                                </Button>
                                            </Link>

                                            <Button className="buynowbtn" onClick={BuyNowClick}>
                                                <FontAwesomeIcon icon={faLock} /> Buy Now
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </Row>
                            <div className="Add-To-Cart mt-3">
                                {/* <div className="main-cart">
                                    <Link to="/add-cart">
                                        <ButtonCommon text="Add To cart" className="cart" />
                                    </Link>
                                </div> */}
                                <div className="icon-button">
                                    <Heart_Component productId={id} />
                                </div>
                            </div>
                            {/* <p className="sub-title mt-3">
                                Categories: Butter & Eggs, Fastfood, Fresh Berries, Fruits & Nut Gifts, Ocean Foods,
                                Vegetables
                            </p> */}
                            <div className="social-icon mt-2">
                                <p>Share:</p>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faFacebook} className="facebook" />

                                    <FontAwesomeIcon icon={faTwitter} className="facebook" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default ProductSlider;
