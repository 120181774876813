import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper.min.css";
import "./NewArrivals.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { Products } from "../../../utils/apis/Product/Product";
import { Context } from "../../../utils/context";
import Product from "../../CommonComponets/Product/Product";

const NewArrivals = () => {
    const { IMG_URL, sellerId } = useContext(Context);
  
    const [newArrivals, setNewArrivals] = useState([]);

    const getNewArrivals = async () => {
        try {
            const res = await Products("", "", "", sellerId);
            if (res?.success) {
               
                setNewArrivals(Array.isArray(res.data?.data) ? res.data?.data : []);
            } else {
                console.error("Failed to fetch new arrivals:", res?.message);
            }
        } catch (error) {
            console.error("Error fetching new arrivals:", error);
        }
    };

    useEffect(() => {
        getNewArrivals();
    }, [sellerId]);



    const [swiperInstance, setSwiperInstance] = useState(null);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    return (
        <section className="NewArrivals">
            <Container>
                <div className="Arrivals">
                    <h1 className="title">NEW ARRIVALS</h1>
                    <Link to="/allcategories">
                        <p className="small-text">
                            View all <FontAwesomeIcon icon={faChevronRight} />
                        </p>
                    </Link>
                </div>

                <Swiper
                    breakpoints={{
                        0: { slidesPerView: 1, spaceBetween: 30 },
                        380: { slidesPerView: 1, spaceBetween: 30 },
                        485: { slidesPerView: 2, spaceBetween: 30 },
                        575: { slidesPerView: 2, spaceBetween: 30 },
                        768: { slidesPerView: 3, spaceBetween: 30 },
                        992: { slidesPerView: 3, spaceBetween: 30 },
                        1024: { slidesPerView: 4, spaceBetween: 30 },
                        1200: { slidesPerView: 4, spaceBetween: 30 },
                        1440: { slidesPerView: 6, spaceBetween: 30 },
                        2000: { slidesPerView: 6, spaceBetween: 30 },
                    }}
                    onSwiper={setSwiperInstance}
                    className="mySwiper"
                >
                    {newArrivals.map((productdata, index) => (
                        <SwiperSlide key={index}>
                            <div className="prodban_div">
                            <Product
                                            image={IMG_URL + productdata?.thumbnail}
                                            productId={productdata?.id}
                                            text={productdata?.product_title}
                                            discountprice={productdata?.mrp}
                                            mainprice={productdata?.selling_price}
                                        />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="swiperbutton_div">
                    <div className="buttondiv-one" onClick={() => handleMainSliderClick("prev")}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </div>
                    <div className="buttondiv-two" onClick={() => handleMainSliderClick("next")}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default NewArrivals;
