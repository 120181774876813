import React, { useContext, useEffect, useState } from 'react'
import './AllCategories.css'
import { Col, Container, Form, Nav, Row, Tab } from 'react-bootstrap'
import { faGrip, faGripLines, faHeart, faShuffle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddCartProduct from '../CommonComponets/AddCartProduct/AddCartProduct'
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { Rating } from 'react-simple-star-rating'
import { faEye } from '@fortawesome/free-regular-svg-icons'
import Breadcrumbs from '../CommonComponets/Breadcrumbs/Breadcrumbs'
import RecentlyView from './RecentlyView/RecentlyView'
import ButtonCommon from '../CommonComponets/ButtonCommon/ButtonCommon'
import { Link, useParams } from 'react-router-dom'
import { MaxPrice, Products } from '../../utils/apis/Product/Product'
import { Context } from '../../utils/context'

import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

function AllCategories() {
  const { IMG_URL, sellerId } = useContext(Context);
  const { id } = useParams();
  // const [selectedOption, setSelectedOption] = useState('Select option');
  // const [isDropdownVisible, setDropdownVisible] = useState(false);
  // const [selectedOption1, setSelectedOption1] = useState('');
  // const [isDropdownVisible1, setDropdownVisible1] = useState(false);

  // const options = [
  //   { id: 'id_1', value: 'option long value dcccc ddddd dddd' },
  //   { id: 'id_2', value: 'option 2' },
  //   { id: 'id_3', value: 'option 3' },
  // ];
  // const options1 = [
  //   { id: 'id_1', value: '1' },
  //   { id: 'id_2', value: '2' },
  //   { id: 'id_3', value: '3' },
  // ];

  // const handleOptionClick = (option) => {
  //   setSelectedOption(option.value);
  //   setDropdownVisible(false);

  // };
  // const handleOptionClick1 = (option1) => {
  //   setSelectedOption1(option1.value);
  //   setDropdownVisible1(false);

  // };





  const addproduct = [
    {
      image: process.env.PUBLIC_URL + "/assets/Image/Categories/lotus.jpg",
      category: "Beauty",
      name: "CACTUS STOPPERS AND CHARM SET IMPERDIET",
      details: "Aenean in mattis velit. Mauris dolor ante, congue a sagittis quis, varius eu justo. In dictum feugiat sem quis efficitur.",
      mainprice: "$20.00",
      discountprice: "$18.00"
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Image/Categories/lotus.jpg",
      category: "Beauty",
      name: "CACTUS STOPPERS AND CHARM SET IMPERDIET",
      details: "Aenean in mattis velit. Mauris dolor ante, congue a sagittis quis, varius eu justo. In dictum feugiat sem quis efficitur.",
      mainprice: "$20.00",
      discountprice: "$18.00"
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Image/Categories/slvarman.jpg",
      category: "Beauty",
      name: "CACTUS STOPPERS AND CHARM SET IMPERDIET",
      details: "Aenean in mattis velit. Mauris dolor ante, congue a sagittis quis, varius eu justo. In dictum feugiat sem quis efficitur.",
      mainprice: "$20.00",
      discountprice: "$18.00"
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Image/Categories/scrub.jpg",
      category: "Beauty",
      name: "CACTUS STOPPERS AND CHARM SET IMPERDIET",
      details: "Aenean in mattis velit. Mauris dolor ante, congue a sagittis quis, varius eu justo. In dictum feugiat sem quis efficitur.",
      mainprice: "$20.00",
      discountprice: "$18.00"
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Image/Categories/love.jpg",
      category: "Beauty",
      name: "CACTUS STOPPERS AND CHARM SET IMPERDIET",
      details: "Aenean in mattis velit. Mauris dolor ante, congue a sagittis quis, varius eu justo. In dictum feugiat sem quis efficitur.",
      mainprice: "$20.00",
      discountprice: "$18.00"
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Image/Categories/lipstick.jpg",
      category: "Beauty",
      name: "CACTUS STOPPERS AND CHARM SET IMPERDIET",
      details: "Aenean in mattis velit. Mauris dolor ante, congue a sagittis quis, varius eu justo. In dictum feugiat sem quis efficitur.",
      mainprice: "$20.00",
      discountprice: "$18.00"
    },
  ]

  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "All Categories", link: "#" },
    ,
  ]);
  const [products, setProduct] = useState([]);

  const [price, setPrice] = useState([]);


  const [value, setValue] = useState({ min: 20, max: 79999 });
  const getproducts = async () => {
    const res = await Products("", "", id, sellerId, value.min, value.max);
    if (res?.success) {
      setProduct(res?.data);
      console.log(res, 'jkhfgdiuhiu');
    }
  }






  console.log(value, 'pricess');
  const getPrice = async () => {
    const res = await MaxPrice(sellerId);
    if (res?.success) {
      setPrice(res?.data);
      console.log(res?.data, "category");
    }
  };

  useEffect(() => {
    getproducts();

  }, [value])

  useEffect(() => {
    getPrice();
  }, []);

  const [values, setValues] = useState([price?.minPrice || 0, price?.maxPrice || 100]);

  const handleChange = (newValues) => {
    setValues(newValues);
    setPrice({ minPrice: newValues[0], maxPrice: newValues[1] });
  };
  return (
    <>
      <section className='allcategories_section my-4'>
        <Container>

          <div className='allcategoriesmain_div'>
            <div>
              <Breadcrumbs items={breadcrumbItems} />
            </div>
            <Row>
              <Col xxl={3} xl={3} lg={4} md={12}>
                <div className='category_right'>
                  <div className='subcat_div mb-3'>
                    <div className='titles'>
                      <h4 className='title mb-0'>PRICE</h4>
                    </div>
                    <div className='pricerange_div  pt-4'>
                      {/* <RangeSlider

                        draggableTrack
                        maxValue={price?.maxPrice}
                        minValue={price?.minPrice}
                        defaultValue={values}
                        onInput={handleChange}
                        formatLabel={(value) => `${value} Rs`}
                        value={value}
                        onChange={setValue}
                        onChangeComplete={(value) => console.log(value)}
                      /> */}

                      <InputRange
                        draggableTrack
                        maxValue={price?.maxPrice}
                        minValue={price?.minPrice}
                        defaultValue={values}
                        onInput={handleChange}
                        formatLabel={(value) => `${value} Rs`}
                        value={value}
                        onChange={setValue}
                        onChangeComplete={(value) => console.log(value)}
                      />
                    </div>


                    <div className='limit_div'>
                      <div className='min_limitdiv'>
                        {value?.min}
                      </div>
                      <div className='min_limitdiv'>
                        {value?.max}
                      </div>
                    </div>

                    {/* <button className='filterbutton'>filter</button> */}
                    {/* <ButtonCommon text="Filter" className='filterbutton' /> */}
                    {/* <p className='text mb-0'>Price: 30.00 USD — 100.00 USD</p> */}
                  </div>
                  {/* <div className='subcat_div  mb-3'>
                    <div className='titles'>
                      <h4 className='title mb-0'>FILTER BY SIZE</h4>
                    </div>
                    <div className='size_main_div'>
                      <div className='sizediv'>
                        L
                      </div>
                      <div className='sizediv'>
                        M
                      </div>
                      <div className='sizediv'>
                        XL
                      </div>
                      <div className='sizediv'>
                        S
                      </div>
                    </div>
                  </div>
                  <div className='subcat_div  mb-3'>
                    <div className='titles'>
                      <h4 className='title mb-0'>FILTER BY COLOR</h4>
                    </div>
                    <div className='size_main_div'>
                      <div className='colordiv'>
                      </div>
                      <div className='colordiv'>
                      </div>
                      <div className='colordiv'>
                      </div>
                      <div className='colordiv'>
                      </div>
                    </div>

                  </div>
                  <div className='subcat_div  mb-3'>
                    <div className='titles'>
                      <h4 className=' title mb-0'>BRAND</h4>
                    </div>

                    <div className='size_main_div'>
                      <Form>
                        {['checkbox'].map((type) => (
                          <div key={`default-${type}`} className="mb-3">
                            <Form.Check
                              type={type}
                              id={`default-${type}`}
                              label="Agatha"
                            />

                            <Form.Check
                              type={type}
                              label="Agatha"
                              id={`disabled-default-${type}`}
                            />
                          </div>
                        ))}
                      </Form>
                    </div>
                  </div>

                  <div className='subcat_div  mb-3'>
                    <div className='titles'>
                      <h4 className='title mb-0'>TAGS</h4>
                    </div>

                    <div className='size_main_div'>
                      <div className='tagdiv'>
                        Computer
                      </div>
                      <div className='tagdiv'>
                        Ipad
                      </div>
                      <div className='tagdiv'>
                        Iphone
                      </div>
                      <div className='tagdiv'>
                        Macbook Air
                      </div>
                    </div>

                  </div> */}

                </div>
              </Col>
              <Col xxl={9} xl={9} lg={8} md={12}>
                <div className='category_right'>
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className='main_grid_div mb-3'>
                      <Nav variant="pills" className="">
                        <Nav.Item>
                          <Nav.Link eventKey="first"><FontAwesomeIcon icon={faGrip} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second"><FontAwesomeIcon icon={faGripLines} /></Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <div className='selectmain_div'>
                        {/* <Form.Select aria-label="Default select example">
                          <option>Short by price</option>
                          <option value="1" >Short by Popularity</option>
                          <option value="2">Short by Rating</option>
                          <option value="3">Short by Date</option>
                        </Form.Select> */}
                        {/* <div
                          className='select'
                          onMouseEnter={() => setDropdownVisible(true)}
                          onMouseLeave={() => setDropdownVisible(false)}
                        >
                          <p className='input'>{selectedOption}</p>
                          <div className={`hidden ${isDropdownVisible ? 'visible' : ''}`}>
                            {options.map((option) => (
                              <p
                                key={option.id}
                                value={option.id}
                                onClick={() => handleOptionClick(option)}
                              >
                                {option.value}
                              </p>
                            ))}
                          </div>
                        </div> */}


                        {/* <p className='mb-0 mx-2'>Show</p>

                        <Form.Select aria-label="Default select example">
                          <option>4</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </Form.Select> */}
                        {/* <div
                          className='select'
                          onMouseEnter={() => setDropdownVisible1(true)}
                          onMouseLeave={() => setDropdownVisible1(false)}
                        >
                          <p className='input'>{selectedOption1}</p>
                          <div className={`hidden ${isDropdownVisible1 ? 'visible' : ''}`}>
                            {options1.map((option1) => (
                              <p
                                key={option1.id}
                                value={option1.id}
                                onClick={() => handleOptionClick1(option1)}
                              >
                                {option1.value}
                              </p>
                            ))}
                          </div>
                        </div> */}
                      </div>

                      <div>

                      </div>
                    </div>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className='griddivv'>
                          <Row>
                            {products?.data?.map((addproduct, index) => (
                              <Col xxl={3} xl={3} lg={4} md={4} sm={6} className='mb-3'>
                                <div className=''>
                                  <AddCartProduct

                                    image={IMG_URL + addproduct?.thumbnail}
                                    productId={addproduct?.id}
                                    title={addproduct?.product_title}
                                    price1={addproduct?.mrp}
                                    price2={addproduct?.selling_price} />
                                </div>

                              </Col>
                            ))}
                          </Row>
                        </div>


                      </Tab.Pane>
                      <Tab.Pane eventKey="second">

                        <div className='secondtab_div'>
                          {products?.data?.map((addproduct, index) => (
                            <div className='mainprod_div'>
                              <Row>
                                <Col xxl={3} xl={3} lg={4} md={5} sm={12}>
                                  <div className='imgmian_div'>
                                    <div className='listviewimage_div'>
                                      <img src={IMG_URL + addproduct?.thumbnail} className='listaddcartimg' />
                                    </div>
                                    <div className='fontosey_div'>
                                    <Link to={`/product-detail/${addproduct?.id}`}> <FontAwesomeIcon icon={faEye} /></Link>
                                    </div>

                                  </div>
                                </Col>
                                <Col xxl={9} xl={9} lg={8} md={7} sm={12} className='my-auto'>
                                  <div className='ms-3'>
                                    <div className='listcategorytext_div'>
                                      {/* <p className='small_text mb-0'>{addproduct.category}</p> */}
                                      <p className='prodcname mb-1'>{addproduct?.product_title}</p>
                                      <p className='small_text detailstext mb-0'>{addproduct.details}</p>
                                      <div>
                                        <Rating />
                                      </div>
                                      <p><span className='discprice'>{addproduct.selling_price}</span> <span className='mainprice'>{addproduct?.mrp}</span></p>
                                      <div className='list_botombtn_div'>
                                        <div className='list_btnaddcart_div me-2'>
                                          <Link to='/add-cart'><button className='list_btn_addcart'>Add To Cart</button></Link>
                                        </div>
                                        <div className='list_sufflebtn_div me-2'>
                                          <FontAwesomeIcon icon={faHeart} />
                                        </div>
                                        <div className='list_sufflebtn_div ms-2'>
                                          <FontAwesomeIcon icon={faShuffle} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Col>
            </Row>
          </div>
          <RecentlyView />
        </Container>
      </section>
    </>
  )
}

export default AllCategories