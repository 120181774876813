import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../../utils/common";


function CheckEmail() {
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger,
    } = useForm();

    const onSubmit = async (data) => {

    }


    return (
        <section className="checkemail-section">
            <Container>
                <div className="LogIn">
                    <div className="row bg-color me-0 ms-0">
                        <div className="col-lg-7 p-0">
                            <div className="row justify-content-center border-bottom-color me-0 ms-0  ">
                                <div className="loginlogo-div">
                                    <img
                                        className="loginlogo"
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Websitelogo/netpurti_logo.png"
                                        }
                                        alt="social_img"
                                    />
                                </div>
                                <div className="col-xxl-8  col-xl-8 col-lg-8 col-md-9 ">
                                    <div className="back_to_span">
                                        <Link to={"/"}>
                                            <p>Back to Home</p>
                                        </Link>
                                    </div>

                                    <div className="heading-holder">
                                        <h4>Check your email</h4>
                                        <p>We send a password reset link to abc@gmail.com</p>
                                    </div>

                                    <div className="log_in_form">
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Form.Group className="mb-3" controlId="">
                                                <Form.Control
                                                    type="text"
                                                    name="email"
                                                    placeholder="Enter Email"
                                                    {...register("email", {
                                                        required: "Email is required",
                                                        pattern: {
                                                            value: RegxExpression.email,
                                                            message: "Invalid email address",
                                                        },
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.email,
                                                        "is-valid": getValues("email"),
                                                    })}
                                                />
                                                {errors?.email && (
                                                    <sup className="text-danger">
                                                        {errors?.email?.message}
                                                    </sup>
                                                )}
                                            </Form.Group>


                                            <div className="btn-holder">
                                                <Button className="SubmitBtn">Open Gmail </Button>
                                            </div>
                                        </Form>
                                        <div className="create_account">
                                            <div className="login_text">
                                                <p>
                                                    Didn't receive the email?
                                                    <span className="login_text">Click to resend</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
}

export default CheckEmail;
