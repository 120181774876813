import React, { useContext, useEffect, useState } from 'react'
import './AllCoupouns.css'
import { Col, Container, Row } from 'react-bootstrap'
import { CouponsProduct } from '../../utils/apis/master/Master';
import { Context } from '../../utils/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faTag } from '@fortawesome/free-solid-svg-icons';
function AllCoupouns() {
    const [coupenproduct, setCoupenProduct] = useState([]);

    const copyCouponCode = (code) => {
        navigator.clipboard.writeText(code);
      };
      
    

    const { sellerId, IMG_URL } = useContext(Context);

    const getCoupenProduct = async () => {
        const res = await CouponsProduct(sellerId);
        if (res?.success) {
            setCoupenProduct(res?.data);
        }
    };
    useEffect(() => {
        getCoupenProduct();
    }, []);

  return (
   <>
   
   <section className='allcoupoun_section my-4'>
    <Container>
        <div className='allcoupon_main'>
            <h3 className='title'>My Coupouns</h3>
            <Row>
            {coupenproduct.map((coupoun, index) => (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6} className='mb-3'>
                <div className='coupon_divs '>
                    <p  className='copupn_text'>{coupoun?.name}</p>
                    <p className='copupn_text2'><FontAwesomeIcon icon={faTag}  className='fatagcolor'/> {coupoun?.coupon_info}</p>
                    <p className='copupn_text'>minimum_order: Rs {coupoun?.minimum_order}</p>
                  
                    <p className='copupn_text'>{coupoun?.coupon_message}</p>
                    <div className='codecoupouns text-end'  >
                    <p className='copupn_text3 ' onClick={() => copyCouponCode(coupoun?.code)}>{coupoun?.code} <FontAwesomeIcon icon={faCopy} /></p>
                    </div>
                </div>
                
                </Col>
            ))}
            </Row>
        </div>
    </Container>
   </section>
   </>
  )
}

export default AllCoupouns