import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./BestValueOffer.css";
const BestValueOffer = () => {
    return (
        <>
            <section className="BestValueOffer">
                <Container>
                    <h4 className="title">BEST VALUED OFFERS</h4>
                    <Row>
                        <Col xxl={4} xl={4} lg={4} md={4}>
                            <div className="best-sale">
                                <img
                                    className="sale"
                                    src={process.env.PUBLIC_URL + "/assets/Image/Homepage/Offers/shop1.jpg"}
                                    alt="Logo"
                                />
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4}>
                            <div className="best-sale">
                                <img
                                    className="sale"
                                    src={process.env.PUBLIC_URL + "/assets/Image/Homepage/Offers/shop2.jpg"}
                                    alt="Logo"
                                />
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4}>
                            <div className="best-sale">
                                <img
                                    className="sale"
                                    src={process.env.PUBLIC_URL + "/assets/Image/Homepage/Offers/shop3.jpg"}
                                    alt="Logo"
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className="coupon-banner">
                        <img
                            className="coupon"
                            src={process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/banner-coup.jpg"}
                            alt="Logo"
                        />
                    </div>
                </Container>
            </section>
        </>
    );
};

export default BestValueOffer;
