import React, { useContext, useEffect, useState } from 'react'
import "./AboutUs.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Context } from '../../utils/context';
// import { aboutDetails, aboutbanner } from '../../utils/apis/common/Common';
import HTMLReactParser from 'html-react-parser/lib/index';
import { aboutDetails, aboutbanner } from '../../utils/apis/master/Master';

const AboutUs = () => {
    const { IMG_URL, sellerId } = useContext(Context);
const [aboutBanner, setAboutBanner] =useState("");
const [aboutdetails, setAboutDetails] =useState("");
    const getAboutBanner = async () => {
        const res = await aboutbanner(sellerId);
        if (res?.success) {
            setAboutBanner(res?.data);
            console.log(res?.data);
        } else {
        }
    };
    const getAboutdata = async () => {
        const res = await aboutDetails(sellerId);
        if (res?.success) {
            setAboutDetails(res?.data);
            console.log(res?.data);
        } else {
        }
    };

    useEffect(() => {
        getAboutBanner();
        getAboutdata();
    }, []);

    return (
        <>
            <section className='Aboutus'>
                <div className='container'>
                    <div className='ButtonMain mt-4'>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>About</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className='Aboutbanner'>
                        <img src={IMG_URL + aboutBanner?.image} className='aboutbannerImg' />
                    </div>
                    <div className=''>
                    <p>{aboutdetails?.description ? HTMLReactParser(aboutdetails?.description) : 'Description not available.'}</p>
                    <p>{aboutdetails?.description_two ? HTMLReactParser(aboutdetails?.description_two) : 'Description not available.'}</p>
                    </div>
                    {/* <div className='row mb-4'>
                        <div className='col-lg-3 col-md-4'>
                            <div className='fashionleft'>
                                <h3>Fashion is just another
                                    accessory for someone
                                    with great style</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-6 ms-auto'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='Stylishmain'>
                                        <h3>Stylish forever</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='Stylishmain'>
                                        <h3>Summer shopping</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='Stylishmain'>
                                        <h3>Celebration</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='Stylishmain'>
                                        <h3>Outfit trends</h3>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='videoContainer'>
                        <img src={process.env.PUBLIC_URL + "/assets/Image/about/aboutbanner.jpg"} className='videoImg' />
                    </div>
                    <div className='row aboutImgmain'>
                        <div className='col-lg-4'>
                            <div className='cardImg'>
                                <img src={process.env.PUBLIC_URL + "/assets/Image/about/aboutImg1.jpg"} className='aboutImg' />
                                <div className='cardtitle'>
                                    <h3>WHAT WE REALLY DO?</h3>
                                    <p>et all the best deals, sales and offers from the best online shopping store in UAE. Sign up now!</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='cardImg'>
                                <img src={process.env.PUBLIC_URL + "/assets/Image/about/aboutImg2.jpg"} className='aboutImg' />
                                <div className='cardtitle'>
                                    <h3>OUR VERSION</h3>
                                    <p>et all the best deals, sales and offers from the best online shopping store in UAE. Sign up now!</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='cardImg'>
                                <img src={process.env.PUBLIC_URL + "/assets/Image/about/aboutImg1.jpg"} className='aboutImg' />
                                <div className='cardtitle'>
                                    <h3>HISTORY OF BEGINNING</h3>
                                    <p>et all the best deals, sales and offers from the best online shopping store in UAE. Sign up now!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='teameetmain'>
                        <h3 className='teamMatehead'>MEET OUR TEAM</h3>
                        <div className='row Meetourteam'>
                            <div className='col-lg-3 col-md-6'>
                                <div className='Aboutbanner'>
                                    <img src={process.env.PUBLIC_URL + "/assets/Image/about/tes1.jpg"} className='aboutbannerImg' />
                                    <div className='Stylishmain'>
                                        <h3>NINA BURNS</h3>
                                        <p>TECH LEAD</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6'>
                                <div className='Aboutbanner'>
                                    <img src={process.env.PUBLIC_URL + "/assets/Image/about/tes2.jpg"} className='aboutbannerImg' />
                                    <div className='Stylishmain'>
                                        <h3>TOMMY HILFGER</h3>
                                        <p>DEVELOPER.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6'>
                                <div className='Aboutbanner'>
                                    <img src={process.env.PUBLIC_URL + "/assets/Image/about/tes3.jpg"} className='aboutbannerImg' />
                                    <div className='Stylishmain'>
                                        <h3>VLADMIR RADSKIN</h3>
                                        <p>WEB DEISGNER</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6'>
                                <div className='Aboutbanner'>
                                    <img src={process.env.PUBLIC_URL + "/assets/Image/about/tes1.jpg"} className='aboutbannerImg' />
                                    <div className='Stylishmain'>
                                        <h3>MICHAEL PHELPS</h3>
                                        <p>FOUNDER & CEO</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default AboutUs