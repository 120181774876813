import React, {useContext, useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import {faChevronLeft, faChevronRight, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Swiper, SwiperSlide} from "swiper/react";
import Product from "../../CommonComponets/Product/Product";
import {Pagination} from "swiper/modules";
import {Context} from "../../../utils/context";
import {GetTodayDeal} from "../../../utils/apis/master/Master";
import "./DailyDeals.css";
const DailyDeals = () => {
    const [swiperInstance3, setSwiperInstance3] = useState(null);
    const handleMainSliderClick3 = (direction) => {
        if (swiperInstance3) {
            if (direction === "prev") {
                swiperInstance3.slidePrev();
            } else if (direction === "next") {
                swiperInstance3.slideNext();
            }
        }
    };

    const productData = [
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/botle.jpg",
            name: "fresh meat lacus",
            mainprice: "$2600",
            discountprice: "$2000",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/apl.jpg",
            name: "postur ligula mattiss veli",
            mainprice: "$2300",
            discountprice: "$2100",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/flower.jpg",
            name: "sapin none orane ligula ovice",
            mainprice: "$2000",
            discountprice: "$1500",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/flower.jpg",
            name: "fresh Organic green",
            mainprice: "$2200",
            discountprice: "$1800",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/flower.jpg",
            name: "fresh Organic green",
            mainprice: "$2200",
            discountprice: "$1800",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/flower.jpg",
            name: "fresh Organic green",
            mainprice: "$2200",
            discountprice: "$1800",
        },
    ];

    const {sellerId, IMG_URL} = useContext(Context);

    const [dailydeal, setDailyDeal] = useState([]);
    const getdailydeal = async () => {
        const res = await GetTodayDeal(sellerId);
        if (res?.success) {
            setDailyDeal(res?.data);
        } else {
        }
    };

    useEffect(() => {
        getdailydeal();
    }, []);

    return (
        <>
            <Container>
                <div className="deals_main_div mt-5">
                    <div className="d-flex justify-content-between">
                        <h4 className="title">DAILY DEALS</h4>
                        <Link to={`/allcategoriesmain`}>
                            <p className="text">
                                view all <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
                            </p>
                        </Link>
                    </div>
                    <div className="swipermain_div">
                        <Swiper
                            spaceBetween={30}
                            pagination={{
                                dynamicBullets: true,
                            }}
                            modules={[Pagination]}
                            autoplay={{delay: 2000}}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },

                                480: {
                                    slidesPerView: 2,
                                },

                                640: {
                                    slidesPerView: 2,
                                },

                                768: {
                                    slidesPerView: 3,
                                },
                                1024: {
                                    slidesPerView: 3,
                                },
                                1250: {
                                    slidesPerView: 5,
                                },
                            }}
                            onSwiper={(swiper) => setSwiperInstance3(swiper)}
                            className="mySwiper"
                        >
                            {dailydeal?.map((productdata, index) => (
                                <Link to={`/product-detail/${productdata?.id}`}>
                                    <SwiperSlide key={index}>
                                        <div className="prodban_div">
                                            <Product
                                                image={IMG_URL + productdata?.thumbnail}
                                                productId={productdata?.id}
                                                text={productdata?.product_title}
                                                discountprice={productdata?.mrp}
                                                mainprice={productdata?.selling_price}
                                            />
                                        </div>
                                    </SwiperSlide>
                                </Link>
                            ))}
                        </Swiper>
                        <div className="swiperbutton_div">
                            <div className="buttondiv-left" onClick={() => handleMainSliderClick3("prev")}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </div>
                            <div className="buttondiv-right" onClick={() => handleMainSliderClick3("next")}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default DailyDeals;
