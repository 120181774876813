import Cookies from "js-cookie";
import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

import { Context } from "../../context";
import { useContext } from "react";

import { getProductDetail } from "../Product/Product";
export const loginCart = async () => {

  let cartItems = [];

  const existingCart = Cookies.get("ecommerce3_cart");
  if (existingCart) {
    cartItems = JSON.parse(existingCart);
  }
  if (cartItems.length > 0) {
    await Promise.all(
      cartItems.map(async (value) => {
        await postData(`/outer-website/cart/add`, {
          product_id: value?.id,
        });
      })
    );
    await Cookies.remove("ecommerce3_cart");
  }
};

export const AddToCart = async (d) => {
  try {
    // const res = await postData(`/outer-website/cart/add`, data);
    if (Cookies.get("ecommerce3_security")) {
      const res = await postData(`/outer-website/cart/add`, {
        product_id: d?.product_id,
        seller_id: d?.seller_id,
        s_p_v_a_d_id: d?.s_p_v_a_d_id,
        s_p_v_a_one_d_a_d_id: d?.s_p_v_a_one_d_a_d_id,
        s_p_v_a_o_d_a_d_a_d_id: d?.s_p_v_a_o_d_a_d_a_d_id,
      });

      console.log(res, "fgfgfgf");
      return true;

    } else {
      let cartItems = [];
      const seller_id = 1;
      const existingCart = Cookies.get("ecommerce3_cart");
      console.log(existingCart, "carttttt");
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
        console.log(cartItems, "itemsms");
      }
      const res = await getProductDetail(d.product_id, seller_id);
      if (res?.success) {
        console.log(res?.data, "no");
        const existingItemIndex = cartItems.findIndex((item) => item.product_id === d.product_id);
        console.log(existingItemIndex, "existing");
        console.log(res.data.s_child_category.image, "imagegege");
        const data11 = {
          product_id: d?.product_id,
          name: d?.name,
          image: d?.image,
          price: d?.price,
          quantity: 1,
        };
        cartItems.push(data11);
        console.log(cartItems, "hthththt");
        Cookies.set("ecommerce3_cart", JSON.stringify(cartItems));

      }
    }
    return true;
  } catch (error) {
    console.log(error);
  }
};

export const RemoveToCart = async (id) => {
  try {
    const res = await getData(`/outer-website/cart/remove?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const CartQuantity = async (id, type) => {
  try {
    if (Cookies.get("ecommerce3_security")) {

      const res = await postData(`/outer-website/cart/quantity`, {
        cart_id: id,
        type: type,
      });
      return res;
    } else {
      let cartItems = [];
      const existingCart = Cookies.get("ecommerce3_cart");
      console.log(existingCart, "existingCart");
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }
      const existingItemIndex = cartItems.findIndex((item) => item.product_id === id);

      if (existingItemIndex === -1) {
        const newItem = { id, quantity: 1 };
        cartItems.push(newItem);
      }

      else {

        if (cartItems[existingItemIndex]?.quantity === 1 && type === 'minus') {
          cartItems.splice(existingItemIndex, 1);
        } else {
          if (type === 'minus') {
            cartItems[existingItemIndex].quantity -= 1;
          } else {
            cartItems[existingItemIndex].quantity += 1;
          }
        }
        Cookies.set('ecommerce3_cart', JSON.stringify(cartItems));
      }

      // Save the updated cart back to cookies
      Cookies.set("ecommerce3_cart", JSON.stringify(cartItems));

    }

    return true;
  } catch (error) {
    console.log(error);
  }
};

export const getCart = async (sellerId) => {
  try {
    return await getData(`/outer-website/cart/show?seller_id=${sellerId}`);
  } catch (error) {
    console.log(error);
  }
};


const seller_id = 1;
export const getCartCheck = async (id, seller_id) => {

  try {

    if (Cookies.get("ecommerce3_security")) {
      return await getData(
        `/outer-website/cart/getCart?${id}`
      );
    } else {
      let cartItems = [];

      const existingCart = Cookies.get("ecommerce3_cart");
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }

      const existingItemIndex = cartItems.findIndex((item) => item.id == id);

      if (existingItemIndex === -1) {
        return { success: false, cartItem: cartItems[existingItemIndex] };
      } else {
        return { success: true, cartItem: cartItems[existingItemIndex] };
      }
    }
  } catch (error) {
    console.log(error);
  }
};
