import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import './Salebanner.css'
import { Context } from "../../../utils/context";
import { GetSalebanner } from "../../../utils/apis/master/Master";


const SaleBanner = () => {
  const {sellerId, IMG_URL} = useContext(Context);



  const [saleBanner, setSalebanner] = useState([]);
  const getsaleBanner = async () => {
      const res = await GetSalebanner(sellerId);
      if (res?.success) {
          setSalebanner(res?.data);
      } else {

      }
  }
  
  useEffect(() => {
      getsaleBanner();
  }, []);

  return (
    <>
      <section className="SaleBanner">
        <div className="Banner">
          {/* <img
            className="Sale"
            src={process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/banner75.jpg"}
            alt="Logo"
          /> */}
           {saleBanner.length > 0 && (
                <div className="Banner">
                    <img
                        className="Sale"
                        src={`${IMG_URL}/${saleBanner[0].bannerImage}`}
                        alt="Sale Banner"
                    />
                </div>
            )}
        </div>
      </section>
    </>
  );
};

export default SaleBanner;
