import React, {useState, useEffect, useContext} from "react";
import "./Header.css";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleQuestion, faHeart, faUser} from "@fortawesome/free-regular-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faCartShopping, faGift, faPhoneVolume, fas, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import LoginModal from "../CommonComponets/LoginModal/LoginModal";
import LogoutModal from "../CommonComponets/LogOutModal/LogOutModal";
import {Context} from "../../utils/context";
import Cookies from "js-cookie";
library.add(fas);
const Header = () => {
    const location = useLocation();
    const [modalShow, setModalShow] = useState(false);
    const [activeItem, setActiveItem] = useState(() => {
        return localStorage.getItem("activeItem") || "home";
    });

    useEffect(() => {
        const path = location.pathname.substr(1);
        updateActiveItem(path || "home");
    }, [location]);

    const updateActiveItem = (newActiveItem) => {
        localStorage.setItem("activeItem", newActiveItem);
        setActiveItem(newActiveItem);
    };

    // const [activeLink, setActiveLink] = useState("home");
    // useEffect(() => {
    //   const path = location.pathname.substring(1);
    //   setActiveLink(path || "home");
    // }, [location.pathname]);

    const [searchToggled, setIsToggled] = useState(false);
    // Function to toggle the state
    const toggleClick = () => {
        setIsToggled(!searchToggled);
    };

    const handleModalclick = () => {
        setModalShow(true);
        setTimeout(() => {
            setModalShow(false);
            window.location = "/login";
        }, 50000);
    };

    const [activeLink, setActiveLink] = useState("Home");
    console.log(activeLink);
    const handleNavItemClick = (selectedLink) => {
        setActiveLink(selectedLink);
        const navbarToggle = document.querySelector(".navbar-toggler");
        if (navbarToggle && window.innerWidth <= 992) {
            navbarToggle.click();
        }
    };

    const {signin, setSignin, setUsertype} = useContext(Context);

    console.log(signin, "{ show, onHide, onConfirm }");
    const navigate = useNavigate();
    const [logoutModalShow, setLogoutModalShow] = useState(false);

    const handleAuthClick = () => {
        if (signin) {
            setLogoutModalShow(true);
        } else {
            navigate("/login");
        }
    };

    const handleLogoutConfirm = () => {
        console.log("Logging out...");

        setLogoutModalShow(false);

        navigate("/login");
    };

    const handleLogout = async () => {
        Cookies.remove("ecommerce3_security", {path: "/"});
        await setSignin(false);
        await setUsertype("");
        setLogoutModalShow(false);
        await navigate("/login");
    };

    const {wishlistCount, cartCount} = useContext(Context);
    console.log(wishlistCount, "wish");
    console.log(cartCount, "count");

    const [cartLengthFromCookies, setCartLengthFromCookies] = useState(0);

    useEffect(() => {
        const cartLength = Cookies.get("cartLength") || 0;
        setCartLengthFromCookies(Number(cartLength));
    }, []);
    const getCartLength = () => (signin ? cartCount : cartLengthFromCookies);

    const [searchTerm, setSearchTerm] = useState("");
    const handleChanges = (e) => {
        setSearchTerm(e.target.value);
        navigate(`/searchresults/${searchTerm}`);
    };

    const handleSubmits = (e) => {
        e.preventDefault();
        navigate(`/searchresults/${searchTerm}`);
    };

    return (
        <>
            {/* start header section  */}
            <section className="second-header-sec">
                <div className="container">
                    <Navbar expand="lg">
                        <Navbar.Brand as={Link} to="/">
                            <img
                                className="headlogo ms-3"
                                src={process.env.PUBLIC_URL + "/assets/Image/Logo/logo-1.png"}
                                alt="Logo"
                            />
                        </Navbar.Brand>
                        <div className="mx-auto ms-5">
                            <InputGroup className="mt-3">
                                <Form.Control
                                    placeholder="Search Item..."
                                    aria-describedby="basic-addon2"
                                    value={searchTerm}
                                    onChange={handleChanges}
                                />
                                <InputGroup.Text id="basic-addon2" onClick={handleSubmits}>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} className="icon-menu" />
                                </InputGroup.Text>
                            </InputGroup>
                        </div>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <div className="all-icon ms-auto mt-lg-0 mt-md-3 mt-sm-3 mt-3">
                                <Link to={"/shop-now"}>
                                    <FontAwesomeIcon icon={faPhoneVolume} className="cart-logo me-3" />
                                    1800-332-0888
                                </Link>

                                <Link to={"/help"}>
                                    <FontAwesomeIcon icon={faCircleQuestion} className="cart-logo me-2" />
                                    Help
                                </Link>
                                <Link to={"/my-wishlist"}>
                                    <div className="heart">
                                        <FontAwesomeIcon icon={faHeart} className="icon" />
                                        <div className="bageicn">{wishlistCount}</div>
                                    </div>
                                </Link>

                                {signin ? (
                                    <Button type="button" onClick={handleAuthClick} className="loginbtn">
                                        Log Out
                                    </Button>
                                ) : (
                                    <Button type="button" onClick={handleAuthClick} className="loginbtn">
                                        Log In
                                    </Button>
                                )}
                            </div>
                            <div className="mobile-view-menu">
                                <Nav>
                                    <Nav.Link
                                        as={Link}
                                        to="/"
                                        active={activeItem === "home"}
                                        onClick={() => handleNavItemClick("home")}
                                    >
                                        Home
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/about"
                                        active={activeItem === "about"}
                                        onClick={() => handleNavItemClick("about")}
                                    >
                                        about
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/blog"
                                        active={activeItem === "blog"}
                                        onClick={() => handleNavItemClick("blog")}
                                    >
                                        Blog
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/contactus"
                                        active={activeItem === "contactus"}
                                        onClick={() => handleNavItemClick("contactus")}
                                    >
                                        Contact
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/allcategoriesmain"
                                        active={activeItem === "allcategoriesmain"}
                                        onClick={() => handleNavItemClick("allcategoriesmain")}
                                    >
                                        All Categories
                                    </Nav.Link>
                                </Nav>
                            </div>
                        </Navbar.Collapse>
                    </Navbar>
                    {/* Second Header */}
                    <div className="second-header-deskkkk">
                        <Navbar>
                            <Nav>
                                <Nav.Link as={Link} to="/" active={activeItem === "home"}>
                                    Home
                                </Nav.Link>
                                <Nav.Link as={Link} to="/about" active={activeItem === "about"}>
                                    about
                                </Nav.Link>
                                <Nav.Link as={Link} to="/blog" active={activeItem === "blog"}>
                                    Blog
                                </Nav.Link>
                                <Nav.Link as={Link} to="/contactus" active={activeItem === "contactus"}>
                                    Contact
                                </Nav.Link>
                                <Nav.Link as={Link} to="/allcategoriesmain" active={activeItem === "allcategoriesmain"}>
                                    All Categories
                                </Nav.Link>
                            </Nav>
                            <Navbar.Toggle />
                            <Navbar.Collapse className="justify-content-end">
                                <div className="text-end">
                                    <Link to={"/add-cart"}>
                                        <button className="my-carttt-btn">
                                            <FontAwesomeIcon icon={faCartShopping} className="me-2" />
                                            {signin ? cartCount : cartLengthFromCookies}
                                        </button>
                                    </Link>
                                </div>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
            </section>
            <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
            <LogoutModal
                show={logoutModalShow}
                onHide={() => setLogoutModalShow(false)}
                onConfirm={handleLogoutConfirm}
                handleLogout={handleLogout}
            />

            {/* end header section  */}
        </>
    );
};

export default Header;
