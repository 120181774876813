import React, {useState} from "react";
import "./Product.css";
import ReactStars from "react-stars";
import {faBagShopping, faEye, faHeart} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import Heart_Component from "../Heart_Component/Heart_Component";
function Product({image, productId, text, discountprice, mainprice}) {
    console.log(productId, "abcdss");

    const [heartactive, setheartActive] = useState(false);
    const handleClickes = ({data}) => {
        setheartActive(!heartactive);
    };

    return (
        <section className="product_section">
            <div className="product_main_div">
                <Link to={`/product-detail/${productId}`}>
                    <div className="producctmain_img">
                        <img src={image} className="productimg" />
                    </div>
                </Link>
                <div className="producttext_div text-center">
                    {/* <ReactStars className='ratingstart' /> */}
                    <div className="productname_div text-center">
                        <p className="productname">{text}</p>
                    </div>
                    <div className="main">
                        <span className="discountprice">₹{discountprice}</span>
                        <span className="mainprice">₹{mainprice}</span>
                    </div>
                </div>
                <div className="iconsproductsss">
                    <div className="iconsproduct iconprod1">
                        <Heart_Component productId={productId} />
                    </div>

                    {/* <div className="iconsproduct  iconprod2">
            <FontAwesomeIcon icon={faEye} className="icnpro" />
          </div> */}
                    <Link to={`/product-detail/${productId}`}>
                        <div className="iconsproduct  iconprod2">
                            <FontAwesomeIcon icon={faBagShopping} className="icnpro" />
                        </div>
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default Product;
