import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import './OrderSuccessFull.css';

const OrderSuccessFull = ({ isOpen, onConfirm, onClose, ...props }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (isOpen) {
            const timer = setTimeout(() => {
                navigate('/');
            }, 3000); // Change 3000 to the desired duration in milliseconds

            return () => clearTimeout(timer);
        }
    }, [isOpen, navigate]);

    if (!isOpen) return null;

    return (
        <div className="Order">
            <Modal
                show={isOpen}
                onHide={onClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                {...props}
                className="Order"
            >
                <Modal.Body>
                    <div className="check">
                        <FontAwesomeIcon icon="fa-solid fa-circle-check" className="check-icon" />
                    </div>
                    <h4 className="title text-center">Order Confirmed</h4>
                    <p className="text text-center">Your order has been placed successfully</p>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default OrderSuccessFull;
