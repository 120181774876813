import React, { useContext, useEffect, useState } from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./HealthyFood.css";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import { Context } from "../../../utils/context";
import { Brands } from "../../../utils/apis/master/Master";

const HealthyFood = () => {
    const brand = [
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Brands/br1.jpg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Brands/br2.jpg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Brands/br3.jpg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Brands/br4.jpg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Brands/br5.jpg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Brands/br6.jpg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Brands/br7.jpg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Brands/br1.jpg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Brands/br2.jpg",
        },  
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Brands/br3.jpg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Brands/br4.jpg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Brands/br5.jpg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Brands/br6.jpg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Brands/br7.jpg",
        },
    ];

    const {sellerId, type , IMG_URL} = useContext (Context);
    const [brands, setBrand] = useState([]);
    const getBrand = async () => {
        const res = await Brands(sellerId, type);
        if (res?.success) {
            setBrand(res?.data);
            console.log(res?.data, "brasds");
        }
    };

    useEffect(() => {
        getBrand();
    }, []);
    return (
        <>
            <section className="HealthyFood">
               
                    <div className="healthy-banner">
                        <img
                            className="health"
                            src={process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/healthy.jpg"}
                            alt="Logo"
                        />
                    </div>
               
                <div className="main-brand">
                    <Container>
                        <div className="brands">
                            <Swiper
                                className="mySwiper"
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    380: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    485: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },
                                    575: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },

                                    768: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },
                                    992: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },
                                    1200: {
                                        slidesPerView: 7,
                                        spaceBetween: 30,
                                    },
                                    1440: {
                                        slidesPerView: 7,
                                        spaceBetween: 30,
                                    },
                                    2000: {
                                        slidesPerView: 7,
                                        spaceBetween: 30,
                                    },
                                }}
                            >
                                {brands.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="brand-image">
                                            <img
                                                className="brands-main"
                                                src={IMG_URL+ item?.image}
                                                alt="Logo"
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </Container>
                </div>
            </section>
        </>
    );
};

export default HealthyFood;
