import React from "react";
import { Modal } from "react-bootstrap";
import Successfull_Lottie from "../Successfull_Modal/Animation/Successfull_Lottie/Successfull_Lottie";

function PasswordResetModal(props) {
    const { show, onHide, namess, ninja, acont } = props;

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                className="Successfull_Modal"
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="lottie-holder">
                        <Successfull_Lottie />
                    </div>
                    <p>Your password has been reset successfully!</p>
                    <p>{namess} <span className="ninjas"> {ninja} </span> {acont}</p>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PasswordResetModal;


