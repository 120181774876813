import React from 'react'
import HomeBanner from './HomeBanner/HomeBanner'
import SubBanner from './SubBanner/SubBanner'
import FeaturedProducts from './FeaturedProducts/FeaturedProducts'
import SaleBanner from './SaleBanner/SaleBanner'
import TopSellingProduct from './TopSellingProduct/TopSellingProduct'
import HealthyFood from './HealthyFood/HealthyFood'
import NewArrivals from './NewArrivals/NewArrivals'
import BestValueOffer from './BestValueOffer/BestValueOffer'
import TrendingProducts from './TrendingProducts/TrendingProducts'
import DailyDeals from './DailyDeals/DailyDeals'


const HomePage = () => {
    return (
        <>
            <HomeBanner />
            <DailyDeals/>
            <SubBanner/>
            <FeaturedProducts/>
            <SaleBanner/>
            <TopSellingProduct/>
            <HealthyFood/>
            <NewArrivals/>
            <BestValueOffer/>
            <TrendingProducts/>
        </>
    )
}

export default HomePage