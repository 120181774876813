import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Nav, Row, Tab } from "react-bootstrap";
import "./Description.css";
import { Rating } from "react-simple-star-rating";
import { text } from "@fortawesome/fontawesome-svg-core";
import { useForm } from "react-hook-form";
import { getReview, postReview, productReviewOnlyOnce } from "../../../utils/apis/Product/Product";
import { Context } from "../../../utils/context";
import { useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser/lib/index";
const Description = ({productdetailed}) => {
    // Rating star
    const [rating, setRating] = useState(0);
    const { IMG_URL, signin, sellerId } = useContext(Context);
    const { id } = useParams();
    console.log(id, "reviewic");

    const [userreview, setUserReviews] = useState(false);

    const [reviews, setReviews] = useState([]);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
    } = useForm();


    const getReviews = async () => {
        try {
            const response = await getReview(id);
            if (response?.success) {
                setReviews(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getUserReview = async () => {
        const res = await productReviewOnlyOnce(id, sellerId);

        setUserReviews(res?.data?.hasReviewed);
    };
    const onSubmit = async (data) => {
        try {
            data.product_id = id;
            data.seller_id = sellerId;
            data.rating = rating;

            const res = await postReview(data);
            if (res?.success) {
                reset();

                setRating(0);
                setModalShow(true);
                setTimeout(() => {

                    getUserReview();
                    setModalShow(false);
                }, 3000);
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Catch Rating value
    const handleRating = (rate: number) => {
        setRating(rate);

        // other logic
    };
    // Optinal callback functions
    // const onPointerEnter = () => console.log("Enter");
    // const onPointerLeave = () => console.log("Leave");
    // const onPointerMove = (value: number, index: number) => console.log(value, index);

    const [modalShow, setModalShow] = useState(false);

    const reviewsd = [
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/botle.jpg",
            date: "Admin – May 22, 2020",
            text: "Good",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/botle.jpg",
            date: "Admin – May 22, 2020",
            text: "Good",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/botle.jpg",
            date: "Admin – May 22, 2020",
            text: "Good",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/botle.jpg",
            date: "Admin – May 22, 2020",
            text: "Good",
        },
    ];
    useEffect(() => {
        getReviews();
        getUserReview();
    }, []);
    return (
        <>
            <section className="Description">
                <Container>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first" className="justify-content-center">
                        <div>
                            <Nav variant="pills" className="justify-content-center">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">DESCRIPTION</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">ADDITIONAL INFORMATION </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">REVIEWS</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>

                        <div>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <p className="description">
                                       {productdetailed?.about ? HTMLReactParser(productdetailed?.about) : 'Description not available.'}
                                    </p>
                                    {/* <p className="description">
                                        Donec quis dictum ipsum. Etiam efficitur tortor a velit fermentum, sed tempus
                                        sapien vestibulum. In purus ipsum, eleifend quis dictum in, laoreet ultricies
                                        velit. Integer aliquet gravida orci vitae tempor. Aliquam fringilla laoreet
                                        nunc, luctus malesuada justo varius non. In dictum sapien ut elit tincidunt, sit
                                        amet sollicitudin purus laoreet. Nullam id risus non felis faucibus dictum.
                                        Vestibulum mollis urna in ligula volutpat condimentum. Nunc imperdiet sed erat
                                        vel auctor. Fusce ut mauris suscipit, tempor urna vel, cursus sem. Etiam
                                        pulvinar est ut purus interdum bibendum. Cras eleifend ligula enim, ac vulputate
                                        massa commodo nec. Morbi vehicula sagittis ultricies. Aliquam at laoreet erat,
                                        nec malesuada nunc.
                                    </p> */}

                                    {/* <Row>
                                        <Col xxl={4} xl={4} lg={4}>
                                            <div className="main-image">
                                                <img
                                                    className="description"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/assets/Image/Product-details/sop1.jpg"
                                                    }
                                                    alt="Logo"
                                                />
                                            </div>
                                        </Col>
                                        <Col xxl={4}>
                                            <div className="main-image">
                                                <img
                                                    className="description"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/assets/Image/Product-details/sop2.jpg"
                                                    }
                                                    alt="Logo"
                                                />
                                            </div>
                                        </Col>
                                        <Col xxl={4}>
                                            <div className="main-image">
                                                <img
                                                    className="description"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/assets/Image/Product-details/sop3.jpg"
                                                    }
                                                    alt="Logo"
                                                />
                                            </div>
                                        </Col>
                                    </Row> */}
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <p className="tab-text">ADDITIONAL INFORMATION</p>
                                    <div className="weight">
                                        <p className="sub-title">type</p>
                                        <p className="kg">{productdetailed?.s_category?.name }</p>
                                    </div>
                                    <div className="weight">
                                        <p className="sub-title">Sub Type</p>
                                        <p className="kg">{productdetailed?.s_sub_category?.name }</p>
                                    </div>
                                    <div className="weight">
                                        <p className="sub-title">product_title</p>
                                        <p className="kg">{productdetailed?.product_title}</p>
                                    </div>
                                    <div className="weight">
                                        <p className="sub-title">product_generic_name</p>
                                        <p className="kg">{productdetailed?.product_generic_name}</p>
                                    </div>
                                    <div className="weight">
                                        <p className="sub-title">caution_and_safety</p>
                                        <p className="kg">{productdetailed?.caution_and_safety ? HTMLReactParser(productdetailed?.caution_and_safety) : 'caution_and_safety not available.'}</p>
                                    </div>
                                    
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <p className="tab-text">
                                        
                                        {reviews.length} REVIEW FOR <span>{productdetailed?.product_title}</span>
                                    </p>
                                    {reviews.map((review, index) => (
                                        <div className="Reviews-Rating">
                                            <Row>
                                                <Col xxl={6}>
                                                    <div className="profile">
                                                        {/* <div className="profile-image">
                                                            <img className="headlogo" src={"item.image"} alt="Logo" />
                                                        </div> */}
                                                        
                                                        <div className="text">
                                                        <h6 className="sub-title">{review?.o_w_user.first_name} {review?.o_w_user.last_name}</h6>
                                                            <p className="text">{review?.name}</p>
                                                            <p className="small-text">{new Date(review.createdAt).toLocaleString()}</p>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col xxl={6}>
                                                    <div className="rating-main">
                                                        <Rating
                                                            initialValue={review?.rating}
                                                            SVGclassName="star-svg"
                                                            fillColor="#ffc107"

                                                            size={"25px"}
                                                            readonly={true}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        {!userreview && signin && (
                                            <div className="reviewmain_div">
                                                <p className="">Add a review</p>
                                                <div>
                                                    <p className="">Your Rating</p>
                                                    <div className="ratingStarsec">
                                                        <Rating
                                                            onClick={(rate) => setRating(rate)}
                                                            ratingValue={rating}
                                                            size={"25px"}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="formdiv">

                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                        <Form.Label>Your Review</Form.Label>
                                                        <Form.Control  {...register("name", { required: "Name is required" })}
                                                            rows={5}
                                                            className="textareaRating"
                                                            placeholder={
                                                                "Tell us more (not more than 700 characters)"
                                                            }
                                                        />
                                                    </Form.Group>





                                                    <div className="ratingbtnsec">
                                                        <button
                                                            onClick={async () => {
                                                                await setRating(1);
                                                                setValue("name", "");
                                                            }}
                                                            className="cancelbtn"
                                                        >
                                                            Cancel
                                                        </button>

                                                        <button className="SubmitBtn" type="submit">
                                                            Post
                                                        </button>
                                                    </div>



                                                </div>

                                            </div>
                                        )}</Form>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </Container>
            </section>
        </>
    );
};

export default Description;
