import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";

import "./AllCategoriesMain.css";
import {text} from "@fortawesome/fontawesome-svg-core";
import {Link} from "react-router-dom";
import {AllSubCategory, Category} from "../../utils/apis/Product/Product";
import {Context} from "../../utils/context";
import Breadcrumbs from "../CommonComponets/Breadcrumbs/Breadcrumbs";
const AllCategoriesMain = () => {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        {text: "Home", link: "/"},
        {text: "All Categories", link: "#"},
        ,
    ]);

    const categoriesbox = [
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d1.png",
            cloth: "Women Categories",
            text: "Part Dress",
            title: "Hot Categories",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d2.png",
            cloth: "Women Categories",
            text: "Part Dress",
            title: "Hot Categories",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d3.png",
            cloth: "Women Categories",
            text: "Part Dress",
            title: "Hot Categories",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/images/Home/Deal/d4.png",
            cloth: "Women Categories",
            text: "Part Dress",
            title: "Hot Categories",
        },
    ];

    const {IMG_URL, sellerId} = useContext(Context);
    const [categories, setCategory] = useState([]);
    const getCategory = async () => {
        const res = await Category(sellerId);
        if (res?.success) {
            setCategory(res?.data);
            console.log(res?.data, "category");
            getSubCategory(res?.data?.[0]?.category_id);
        }
    };

    const [subCategory, setSubCategory] = useState([]);
    const getSubCategory = async (id) => {
        const res = await AllSubCategory(sellerId, id);
        if (res?.success) {
            setSubCategory(res?.data);
        }
    };

    useEffect(() => {
        getCategory();
    }, []);
    return (
        <>
            <section className="AllCategories">
                <Container>
                    <div className="main-block">
                        <h1 className="title">All Categories</h1>

                        <div>
                            <Breadcrumbs items={breadcrumbItems} />
                        </div>
                    </div>
                    <Row>
                        {categories?.map((item, index) => (
                            <Col xxl={4} xl={4} lg={4} md={6}>
                                <div className="Categories-Block mt-5" key={index}>
                                    <div className="blocks">
                                        <div className="main-categories">
                                            <img src={IMG_URL + item?.image} className="men" />
                                        </div>
                                        <div className="text">
                                            <h1 className="tab-text">{item?.name}</h1>
                                        </div>
                                    </div>
                                    <div className="category-type">
                                        <Row>
                                            {item?.s_category?.s_sub_categories?.map(
                                                (subCategory, subIndex) =>
                                                    subCategory?.subcategory_of_seller !== null && (
                                                        <Col xxl={6} xl={6} lg={6} md={6}>
                                                            <h1 className="title-product mb-3">
                                                                {subCategory?.subcategory_of_seller?.name}
                                                            </h1>
                                                            {subCategory?.child_category_of_sellers?.map(
                                                                (childCategory, childIndex) => (
                                                                    <Link
                                                                        to={`/allcategories/${childCategory?.child_category_id}`}
                                                                    >
                                                                        <p className="text ms-3 mb-1">
                                                                            {childCategory?.name}
                                                                        </p>{" "}
                                                                    </Link>
                                                                )
                                                            )}
                                                        </Col>
                                                    )
                                            )}
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        ))}

                        <Col></Col>
                    </Row>
                    {/* {categories?.map((item, index) => (
                        <div className="Categories-Block mt-5" key={index}>
                            <div className="blocks">
                                <div className="main-categories">
                                    <img src={IMG_URL + item?.image} className="men" />
                                </div>
                                <div className="text">
                                    <h1 className="title">{item?.name}</h1>
                                </div>
                            </div>

                            <Row>
                                {item?.s_category?.s_sub_categories?.map(
                                    (subCategory, subIndex) =>
                                        subCategory?.subcategory_of_seller !== null && (
                                            <Col xxl={4}>
                                                <h1 className="title mb-3">
                                                    {subCategory?.subcategory_of_seller?.name}
                                                </h1>
                                                {subCategory?.child_category_of_sellers?.map(
                                                    (childCategory, childIndex) => (
                                                        <Link to={`/allcategories/${childCategory?.child_category_id}`}>
                                                            <p className="text ms-3 mb-1">{childCategory?.name}</p>{" "}
                                                        </Link>
                                                    )
                                                )}
                                            </Col>
                                        )
                                )}
                            </Row>
                        </div>
                    ))} */}
                </Container>
            </section>
        </>
    );
};

export default AllCategoriesMain;
