import React, {useState, useEffect} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import classNames from "classnames";
import {RegxExpression} from "../../../utils/common";
import ButtonCommon from "../../CommonComponets/ButtonCommon/ButtonCommon";
import Select from "react-select";
import {Errors, Placeholders} from "../../../utils/errors";
import {DeleteAddress, EditAddress, getAddress, postAddress} from "../../../utils/apis/orderProcess/orderProcess";
import {Pincode, pincodeWiseAll} from "../../../utils/apis/master/Master";
import {Select2Data} from "../../../utils/api";
import "./Address.css";
const Address = () => {
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        setValue,
        formState: {errors},
        reset,
        trigger,
    } = useForm({});

    const [showForms, setShowForms] = useState(false);
    const [address, setAddress] = useState([]);
    const [cartsuccess, setCartsuccess] = useState(false);
    const [addressId, setAddressId] = useState("");
    const [cities, setCities] = useState("");
    const [states, setStates] = useState("");
    const [countries, setCountries] = useState("");
    const [pincodes, setPincodes] = useState([]);

    useEffect(() => {
        AddressGet();
    }, []);

    const AddressGet = async () => {
        const res = await getAddress();
        if (res?.success) {
            setAddress(res?.data);
        }
    };

    const handleDelete = async (id) => {
        const res = await DeleteAddress(id);
        if (res?.success) {
            AddressGet();
        }
    };

    const hanldeEdit = async (data) => {
        reset(data);
        setCities(data?.city?.name);
        setStates(data?.state?.name);
        setCountries(data?.country?.name);
        setValue("pincode_id", {
            value: data.pincode?.id,
            label: data.pincode?.name,
        });
        setValue("address_type", data?.address_type);
        setAddressId(data?.id);
        // setShowInnerPage(true);
        setShowForms(true);
    };

    const onSubmit = async (data) => {
        data.pincode_id = data?.pincode_id?.value;
        let res;
        if (addressId) {
            res = await EditAddress(data, addressId);
        } else {
            res = await postAddress(data);
        }

        if (res?.success) {
            AddressGet();
            setShowForms(false);
            reset();
            setAddressId("");
            setCities("");
            setStates("");
            setCountries("");
            setTimeout(() => {
                setCartsuccess(true);
            }, 1000);
            setCartsuccess(true);
        }
    };

    const handlebackPrevious = () => {
        setShowForms(false);
        reset();
        clearErrors();
    };

    const handlePincodeChange = async (e) => {
        const res = await Pincode(e);
        if (res?.success) {
            const data = await Select2Data(res.data, "pincode_id", false);
            setPincodes(data);
        }
    };

    const handlePincodeSelect = async (id) => {
        const res = await pincodeWiseAll(id);
        if (res?.success) {
            setCities(res.data.city.name);
            setStates(res.data.state.name);
            setCountries(res.data.country.name);
            setValue("city_id", res.data.city_id);
            setValue("state_id", res.data.state_id);
            setValue("country_id", res.data.country_id);
            setValue("pincode_id", {
                value: res.data.id,
                label: res.data.name,
            });
        }
    };

    return (
        <section className="address_sections">
            {!showForms ? (
                <>
                    <p className="sub-txtt">The following addresses will be used on the checkout page by default.</p>
                    <Row>
                        <Col lg={10} md={9} sm={9} xs={12}>
                            <h4 className="head-txtttt">Address</h4>
                            <div className=" ">
                                {address && address.length > 0 ? (
                                    <div className="">
                                    <Row>
                                    {address.map((value) => (
                                      <Col xxl={6} xl={6} lg={6}>
                                      <div className=" address_details mb-2">
                                                <div className="lists">
                                                
                                                    <p className="addtext text mb-1">
                                                        {value?.f_name} {value?.l_name}
                                                    </p>
                                                    <p className="addtext text mb-1">{value?.address}</p>
                                                    <p className="addtext text mb-1">{value?.pincode?.name}</p>
                                                    <p className="addtext text mb-1">{value?.city?.name}</p>
                                                    <p className="addtext text mb-1">{value?.state?.name}</p>
                                                    <p className="addtext text mb-1">{value?.landmark}</p>
                                                </div>
                                                <div>
                                                    <div>
                                                        <div>
                                                            <ButtonCommon
                                                                buttontype="button"
                                                                text="Edit"
                                                                className="addbtn  me-2"
                                                                onClick={() => {
                                                                    // handleEdit(value);
                                                                    hanldeEdit(value);
                                                                    // handleupdate(value);
                                                                }}
                                                            >
                                                                Edit
                                                            </ButtonCommon>
                                                            <ButtonCommon
                                                                buttontype="button"
                                                                text="Delete"
                                                                className="deletebtn"
                                                                onClick={() => handleDelete(value?.id)}
                                                            >
                                                                Delete
                                                            </ButtonCommon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                      </Col>
                                           
                                        ))}
                                    </Row>
                                       
                                    </div>
                                ) : null}
                            </div>
                        </Col>
                        <Col lg={2} md={3} sm={3} xs={12}>
                            <h4 className="head-txtttt text-center">new</h4>
                            <div className="text-center">
                                <button className="addbtn " onClick={() => setShowForms(true)}>
                                    Add
                                </button>
                            </div>
                        </Col>
                    </Row>
                </>
            ) : (
                <div className="address_content my-3">
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                First Name
                            </Form.Label>
                            <Col sm="10">
                                {/* <Form.Control as="textarea" type="text" placeholder="Paul k Jeneson" rows={3} /> */}
                                <Form.Control
                                    type="text"
                                    placeholder="Enter First Name"
                                    {...register("f_name", {
                                        required: "First name required",
                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.f_name,
                                        "is-valid": getValues("f_name"),
                                    })}
                                    onKeyDown={(event) => {
                                        if (!RegxExpression.name.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                Last Name
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Last Name"
                                    {...register("l_name", {
                                        required: "Last name required",
                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.l_name,
                                        "is-valid": getValues("l_name"),
                                    })}
                                    onKeyDown={(event) => {
                                        if (!RegxExpression.name.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                Contact
                            </Form.Label>
                            <Col sm="10">
                                {/* <Form.Control as="textarea" type="text" placeholder="Paul k Jeneson" rows={3} /> */}
                                <Form.Control
                                    className={classNames("", {
                                        "is-invalid": errors?.contact_no,
                                        "is-valid": getValues("contact_no"),
                                    })}
                                    type="text"
                                    name="contact_no"
                                    placeholder="Enter Mobile Number"
                                    {...register("contact_no", {
                                        required: "Field is required",
                                        minLength: {
                                            value: 10,
                                            message: "Number should be at least 10 characters",
                                        },
                                    })}
                                    onKeyDown={(event) => {
                                        if (!RegxExpression.phone.test(event.key)) {
                                            if (event.key !== "Backspace") {
                                                event.preventDefault();
                                            }
                                        }
                                    }}
                                    maxLength={10}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                Address
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    as="textarea"
                                    name="address"
                                    type="text"
                                    rows={3}
                                    placeholder="Enter Message"
                                    {...register("address", {
                                        required: "Message required",
                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.address,
                                    })}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                Postal Code
                            </Form.Label>
                            <Col sm="10">
                                {/* <Form.Control type="password" placeholder="Your Postal Code " /> */}
                                <Controller
                                    name="pincode_id"
                                    {...register("pincode_id", {
                                        required: Errors.pincode,
                                    })}
                                    control={control}
                                    render={({field}) => (
                                        <Select
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: errors.pincode_id ? "red" : baseStyles,
                                                }),
                                            }}
                                            {...field}
                                            options={pincodes}
                                            onInputChange={(selectedOption) => {
                                                handlePincodeChange(selectedOption);
                                            }}
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption.value);
                                                handlePincodeSelect(selectedOption.value);
                                            }}
                                        />
                                    )}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                Country
                            </Form.Label>
                            <Col sm="10">
                                {/* <Select className='' id="inputState" options={locationOptions} placeholder="Select Your Country" classNamePrefix="main" /> */}
                                <Form.Control
                                    className={classNames("", {
                                        "is-invalid": errors?.country,
                                    })}
                                    type="text"
                                    name="country_id"
                                    readOnly
                                    value={countries}
                                    placeholder={Placeholders.country}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                State
                            </Form.Label>
                            <Col sm="10">
                                {/* <Select className='' id="inputState" options={locationOptions} placeholder="Select Your State" classNamePrefix="main" /> */}

                                <Form.Control
                                    className={classNames("", {
                                        "is-invalid": errors?.state,
                                    })}
                                    type="text"
                                    name="state_id"
                                    value={states}
                                    placeholder={Placeholders.state}
                                    readOnly
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                City
                            </Form.Label>
                            <Col sm="10">
                                {/* <Select className='' id="inputState" options={locationOptions} placeholder="Select Your City" classNamePrefix="main" /> */}

                                <Form.Control
                                    className={classNames("", {
                                        "is-invalid": errors?.city,
                                    })}
                                    type="text"
                                    name="city_id"
                                    readOnly
                                    value={cities}
                                    // placeholder={Placeholders.city}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">
                                LandMark
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    placeholder="Enter LandMark"
                                    {...register("land_mark", {
                                        required: "LandMark required",
                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.land_mark,
                                        "is-valid": getValues("land_mark"),
                                    })}
                                />
                            </Col>
                        </Form.Group>
                        <Row>
                            <Col sm="2">
                                <div className="addressHead">
                                    <p className="sub-text">TYPE OF ADDRESS</p>
                                </div>
                            </Col>

                            <Col sm="10">
                                <div className="d-flex flex-wrap">
                                    <Form.Group className="addresscheck  me-3" id="formGridCheckbox">
                                        <Form.Check
                                            type="radio"
                                            name="addressType"
                                            label="Home"
                                            value="Home"
                                            defaultChecked={getValues("address_type") === "Home"}
                                            {...register("address_type", {
                                                required: Errors.addressType,
                                            })}
                                        />
                                    </Form.Group>
                                    <Form.Group className="addresscheck  me-3" id="formGridCheckbox">
                                        <Form.Check
                                            type="radio"
                                            name="addressType"
                                            label="Work"
                                            value="Work"
                                            defaultChecked={getValues("address_type") === "Work"}
                                            {...register("address_type", {
                                                required: Errors.addressType,
                                            })}
                                        />
                                    </Form.Group>
                                    <Form.Group className="addresscheck" id="formGridCheckbox">
                                        <Form.Check
                                            type="radio"
                                            name="addressType"
                                            label="Office"
                                            value="Office"
                                            defaultChecked={getValues("address_type") === "Office"}
                                            {...register("address_type", {
                                                required: Errors.addressType,
                                            })}
                                        />
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                        <div className="text-end">
                            <ButtonCommon
                                buttontype="button"
                                text="Back"
                                className="deletebtn me-2"
                                onClick={handlebackPrevious}
                            />
                            <ButtonCommon
                                buttontype="button"
                                text="Save"
                                className="addbtn"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </div>
                    </Form>
                </div>
            )}
        </section>
    );
};

export default Address;
