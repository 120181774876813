import React, {useContext, useEffect, useState} from "react";
import "./CheckoutForm.css";
import Form from "react-bootstrap/Form";
import {Button, Col, Container, Nav, Row, Tab} from "react-bootstrap";
import Successfull_Modal from "../CommonComponets/Successfull_Modal/Successfull_Modal";
import {getCart} from "../../utils/apis/addToCart/addToCart";
import {useNavigate} from "react-router-dom";
import {Context} from "../../utils/context";
import Cookies from "js-cookie";
import useRazorpay from "react-razorpay";
import OrderSuccessFull from "../CommonComponets/OrderSuccessFull/OrderSuccessFull";
import {Controller, useForm} from "react-hook-form";
import {Errors, Placeholders} from "../../utils/errors";
import classNames from "classnames";
import ButtonCommon from "../CommonComponets/ButtonCommon/ButtonCommon";
import {RegxExpression} from "../../utils/common";
import Select from "react-select";
import {EditAddress, getAddress, postAddress} from "../../utils/apis/orderProcess/orderProcess";
import {Pincode, pincodeWiseAll} from "../../utils/apis/master/Master";
import {Select2Data} from "../../utils/api";
const CheckoutForm = () => {
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        setValue,
        formState: {errors},
        reset,
        trigger,
    } = useForm({});

    const [showForms, setShowForms] = useState(false);
    const [address, setAddress] = useState([]);
    const [cartsuccess, setCartsuccess] = useState(false);
    const [addressId, setAddressId] = useState("");
    const [cities, setCities] = useState("");
    const [states, setStates] = useState("");
    const [countries, setCountries] = useState("");
    const [pincodes, setPincodes] = useState([]);

    const [addressSelect, setAddressSelect] = useState("");

    useEffect(() => {
        AddressGet();
    }, []);

    const AddressGet = async () => {
        const res = await getAddress();
        if (res?.success) {
            setAddress(res?.data);
        }
    };

    const onSubmit = async (data) => {
        data.pincode_id = data?.pincode_id?.value;
        let res;
        if (addressId) {
            res = await EditAddress(data, addressId);
        } else {
            res = await postAddress(data);
        }

        if (res?.success) {
            AddressGet();
            setShowForms(false);
            reset();
            setAddressId("");
            setCities("");
            setStates("");
            setCountries("");
            setTimeout(() => {
                setCartsuccess(true);
            }, 1000);
            setCartsuccess(true);
        }
    };

    const handlebackPrevious = () => {
        setShowForms(false);
        reset();
        clearErrors();
    };

    const handlePincodeChange = async (e) => {
        const res = await Pincode(e);
        if (res?.success) {
            const data = await Select2Data(res.data, "pincode_id", false);
            setPincodes(data);
        }
    };

    const handlePincodeSelect = async (id) => {
        const res = await pincodeWiseAll(id);
        if (res?.success) {
            setCities(res.data.city.name);
            setStates(res.data.state.name);
            setCountries(res.data.country.name);
            setValue("city_id", res.data.city_id);
            setValue("state_id", res.data.state_id);
            setValue("country_id", res.data.country_id);
            setValue("pincode_id", {
                value: res.data.id,
                label: res.data.name,
            });
        }
    };

    const [showShippingForm, setShowShippingForm] = useState(false);

    const handleCheckboxChange = () => {
        setShowShippingForm(!showShippingForm);
    };

    const [selectedOption, setSelectedOption] = useState("");

    const handleRadioChange = (option) => {
        setSelectedOption(option);
    };

    const [modalShow, setModalShow] = useState(false);

    // *********************Total Price *********************

    const [totalPrice, setTotalPrice] = useState(0);
    const [cartsignin, setCartsignin] = useState([]);
    const [cart, setCart] = useState([]);
    const navigate = useNavigate();
    const {IMG_URL, signin, sellerId, triggercart} = useContext(Context);

    const cartGet = async () => {
        if (signin) {
            const res = await getCart(sellerId);
            if (res?.success) {
                setCartsignin(res?.data);
            }
        } else {
            if (Cookies.get("ecommerce3_cart")) {
                setCart(JSON.parse(Cookies.get("ecommerce3_cart")));
            }
        }
    };

    useEffect(() => {
        cartGet();
    }, [signin, sellerId, triggercart]);

    useEffect(() => {
        const calculateTotalPrice = () => {
            let price = 0;
            if (signin) {
                cartsignin.forEach((product) => {
                    price += product.selling_price * product.quantity;
                });
            } else {
                cart.forEach((product) => {
                    price += product.price * product.quantity;
                });
            }
            setTotalPrice(price);
        };

        calculateTotalPrice();
    }, [cartsignin, cart, signin]);

    //    Payment Method
    const [paymentMethod, setPaymentMethod] = useState("online");
    const handleMethod = async (value) => {
        await setPaymentMethod(value);
    };

    const [Razorpay, openRazorpay] = useRazorpay();
    const [razorid, setRazorId] = useState();
    const [discount, setDiscount] = useState(0);
    const [checkoutid, setCheckoutid] = useState([]);
    const [showModal, setShowModal] = useState(false); 

    const handleRazorpay = async (storedValue) => {
        console.log(storedValue, "ghjgfjh");
        const options = {
            key: "rzp_test_I05KCFb1v5JJRc",
            amount: Math.round(storedValue) * 100,
            currency: "INR",
            name: "Ecommerce",
            description: "Test Transaction",
            image: "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",
            handler: async (res) => {
                await setRazorId(res.razorpay_payment_id);
                finalFunction(res.razorpay_payment_id);
                setShowModal(true); // Show modal after successful payment
                console.log(res, "ttttttttttttttttttttttttttttt");
            },
            prefill: {
                name: "Saurav Khambayate",
                email: "youremail@example.com",
                contact: "9999999999",
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#052c65",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
       
    };

   
    const finalFunction = async (razor = null) => {
        // const data = {
        //     address_id: addressSelect,
        //     amount: discountPrice,
        //     payment_mode: razor ? "online" : "cod",
        //     seller_id: sellerId,
        //     coupon_id: coupanId,
        //     discount: discountAmount
        // };
        // const res = await Checkout(data);
        // if (res?.success) {
        //     navigate(`/${res?.data?.order_id}`);
        // } else {
        //     navigate(`/${res?.data?.order_id}`);
        // }
        // triggercart();
    };

    useEffect(() => {
        cartGet();
    }, []);

    // const [showModal, setShowModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handlePlaceOrderClick = () => {
        setIsModalOpen(true);
    };

    const handleConfirmOrder = () => {
        setIsModalOpen(false);
        finalFunction();
    };

    return (
        <section className="checkout-form">
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        {/* <h5 className="check-title">Billing details</h5>
                        <div className="brown-border"></div>
                        <div>
                            <Form>
                                <Row>
                                    <Col lg={6} md={12} sm={12} xs={12}>
                                        <Form.Group className="mb-3" controlId="formFirstName">
                                            <Form.Label>
                                                First name <span className="red-star">*</span>
                                            </Form.Label>
                                            <Form.Control type="text" required />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} xs={12}>
                                        <Form.Group className="mb-3" controlId="formLastName">
                                            <Form.Label>
                                                Last name <span className="red-star">*</span>
                                            </Form.Label>
                                            <Form.Control type="text" required />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className="mb-3" controlId="formCompanyName">
                                            <Form.Label>Company name (optional)</Form.Label>
                                            <Form.Control type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className="mb-3" controlId="formStreetAddress">
                                            <Form.Label>
                                                Street address <span className="red-star">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="House Number and street name"
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} xs={12}>
                                        <Form.Group className="mb-3" controlId="formCity">
                                            <Form.Label>
                                                Town / City <span className="red-star">*</span>
                                            </Form.Label>
                                            <Form.Control type="text" required />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} xs={12}>
                                        <Form.Group className="mb-3" controlId="formState">
                                            <Form.Label>
                                                State <span className="red-star">*</span>
                                            </Form.Label>
                                            <Form.Control type="text" required />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} xs={12}>
                                        <Form.Group className="mb-3" controlId="formZipCode">
                                            <Form.Label>
                                                ZIP Code <span className="red-star">*</span>
                                            </Form.Label>
                                            <Form.Control type="number" required />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={12} sm={12} xs={12}>
                                        <Form.Group className="mb-3" controlId="formPhone">
                                            <Form.Label>
                                                Phone <span className="red-star">*</span>
                                            </Form.Label>
                                            <Form.Control type="number" required />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className="mb-3" controlId="formEmail">
                                            <Form.Label>
                                                Email address <span className="red-star">*</span>
                                            </Form.Label>
                                            <Form.Control type="email" required />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </div> */}

                        <section className="address_sections2">
                            {!showForms ? (
                                <>
                                    {/* <p className='sub-txtt'>The following addresses will be used on the checkout page by default.</p> */}

                                    <h4 className="head-txtttt">Address</h4>
                                    <div className=" ">
                                        {address && address.length > 0 ? (
                                            <div className="">
                                                <Row>
                                                    {address.map((value) => (
                                                        <Col lg={6} md={6} sm={6} xs={12}>
                                                            <div className=" address_detailed mb-2">
                                                                <Form.Check
                                                                    type="radio"
                                                                    name="addressType"
                                                                    className="me-3"
                                                                    onClick={() => {
                                                                        setAddressSelect(value?.id);
                                                                        // setActiveKey("1");
                                                                    }}
                                                                />

                                                                <div className="lists">
                                                                    <p className="addtext text mb-1">
                                                                        {value?.f_name} {value?.l_name}
                                                                    </p>
                                                                    <p className="addtext text mb-1">
                                                                        {value?.address}
                                                                    </p>
                                                                    <p className="addtext text mb-1">
                                                                        {value?.pincode?.name}
                                                                    </p>
                                                                    <p className="addtext text mb-1">
                                                                        {value?.city?.name}
                                                                    </p>
                                                                    <p className="addtext text mb-1">
                                                                        {value?.state?.name}
                                                                    </p>
                                                                    <p className="addtext text mb-1">
                                                                        {value?.landmark}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            {/* <ButtonCommon
                      buttontype="button"
                      text="Edit"
                      className="addbtn  me-2"
                      onClick={() => {
                        // handleEdit(value);
                        hanldeEdit(value)
                        // handleupdate(value);
                      }}
                    >
                      Edit
                    </ButtonCommon>
                    <ButtonCommon
                      buttontype="button"
                      text="Delete"
                      className="deletebtn"
                      onClick={() => handleDelete(value?.id)}
                    >
                      Delete
                    </ButtonCommon> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </div>
                                        ) : null}
                                    </div>

                                    <h4 className="head-txtttt text-center mt-4">Add New Address</h4>
                                    <div className="text-center">
                                        <button className="addbtn " onClick={() => setShowForms(true)}>
                                            Add
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <div className="address_content my-3">
                                    <Form>
                                        <Row>
                                            <Col xxl={6} xl={6} lg={6}>
                                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                    <Form.Label column sm="2">
                                                        First Name
                                                    </Form.Label>
                                                    <Col sm="10">
                                                        {/* <Form.Control as="textarea" type="text" placeholder="Paul k Jeneson" rows={3} /> */}
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter First Name"
                                                            {...register("f_name", {
                                                                required: "First name required",
                                                            })}
                                                            className={classNames("", {
                                                                "is-invalid": errors?.f_name,
                                                                "is-valid": getValues("f_name"),
                                                            })}
                                                            onKeyDown={(event) => {
                                                                if (!RegxExpression.name.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6}>
                                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                    <Form.Label column sm="2">
                                                        Last Name
                                                    </Form.Label>
                                                    <Col sm="10">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Last Name"
                                                            {...register("l_name", {
                                                                required: "Last name required",
                                                            })}
                                                            className={classNames("", {
                                                                "is-invalid": errors?.l_name,
                                                                "is-valid": getValues("l_name"),
                                                            })}
                                                            onKeyDown={(event) => {
                                                                if (!RegxExpression.name.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6}>
                                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                    <Form.Label column sm="2">
                                                        Contact
                                                    </Form.Label>
                                                    <Col sm="10">
                                                        {/* <Form.Control as="textarea" type="text" placeholder="Paul k Jeneson" rows={3} /> */}
                                                        <Form.Control
                                                            className={classNames("", {
                                                                "is-invalid": errors?.contact_no,
                                                                "is-valid": getValues("contact_no"),
                                                            })}
                                                            type="text"
                                                            name="contact_no"
                                                            placeholder="Enter Mobile Number"
                                                            {...register("contact_no", {
                                                                required: "Field is required",
                                                                minLength: {
                                                                    value: 10,
                                                                    message: "Number should be at least 10 characters",
                                                                },
                                                            })}
                                                            onKeyDown={(event) => {
                                                                if (!RegxExpression.phone.test(event.key)) {
                                                                    if (event.key !== "Backspace") {
                                                                        event.preventDefault();
                                                                    }
                                                                }
                                                            }}
                                                            maxLength={10}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6}>
                                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                    <Form.Label column sm="2">
                                                        Address
                                                    </Form.Label>
                                                    <Col sm="10">
                                                        <Form.Control
                                                            as="textarea"
                                                            name="address"
                                                            type="text"
                                                            rows={3}
                                                            placeholder="Enter Message"
                                                            {...register("address", {
                                                                required: "Message required",
                                                            })}
                                                            className={classNames("", {
                                                                "is-invalid": errors?.address,
                                                            })}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6}>
                                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                    <Form.Label column sm="2">
                                                        Postal Code
                                                    </Form.Label>
                                                    <Col sm="10">
                                                        {/* <Form.Control type="password" placeholder="Your Postal Code " /> */}
                                                        <Controller
                                                            name="pincode_id"
                                                            {...register("pincode_id", {
                                                                required: Errors.pincode,
                                                            })}
                                                            control={control}
                                                            render={({field}) => (
                                                                <Select
                                                                    styles={{
                                                                        control: (baseStyles) => ({
                                                                            ...baseStyles,
                                                                            borderColor: errors.pincode_id
                                                                                ? "red"
                                                                                : baseStyles,
                                                                        }),
                                                                    }}
                                                                    {...field}
                                                                    options={pincodes}
                                                                    onInputChange={(selectedOption) => {
                                                                        handlePincodeChange(selectedOption);
                                                                    }}
                                                                    onChange={(selectedOption) => {
                                                                        field.onChange(selectedOption.value);
                                                                        handlePincodeSelect(selectedOption.value);
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6}>
                                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                    <Form.Label column sm="2">
                                                        Country
                                                    </Form.Label>
                                                    <Col sm="10">
                                                        {/* <Select className='' id="inputState" options={locationOptions} placeholder="Select Your Country" classNamePrefix="main" /> */}
                                                        <Form.Control
                                                            className={classNames("", {
                                                                "is-invalid": errors?.country,
                                                            })}
                                                            type="text"
                                                            name="country_id"
                                                            readOnly
                                                            value={countries}
                                                            placeholder={Placeholders.country}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>

                                            <Col xxl={6} xl={6} lg={6}>
                                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                    <Form.Label column sm="2">
                                                        State
                                                    </Form.Label>
                                                    <Col sm="10">
                                                        {/* <Select className='' id="inputState" options={locationOptions} placeholder="Select Your State" classNamePrefix="main" /> */}

                                                        <Form.Control
                                                            className={classNames("", {
                                                                "is-invalid": errors?.state,
                                                            })}
                                                            type="text"
                                                            name="state_id"
                                                            value={states}
                                                            placeholder={Placeholders.state}
                                                            readOnly
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6}>
                                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                    <Form.Label column sm="2">
                                                        City
                                                    </Form.Label>
                                                    <Col sm="10">
                                                        {/* <Select className='' id="inputState" options={locationOptions} placeholder="Select Your City" classNamePrefix="main" /> */}

                                                        <Form.Control
                                                            className={classNames("", {
                                                                "is-invalid": errors?.city,
                                                            })}
                                                            type="text"
                                                            name="city_id"
                                                            readOnly
                                                            value={cities}
                                                            // placeholder={Placeholders.city}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6}>
                                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                    <Form.Label column sm="2">
                                                        LandMark
                                                    </Form.Label>
                                                    <Col sm="10">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter LandMark"
                                                            {...register("land_mark", {
                                                                required: "LandMark required",
                                                            })}
                                                            className={classNames("", {
                                                                "is-invalid": errors?.land_mark,
                                                                "is-valid": getValues("land_mark"),
                                                            })}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm="2">
                                                <div className="addressHead">
                                                    <p className="sub-text">TYPE OF ADDRESS</p>
                                                </div>
                                            </Col>

                                            <Col sm="10">
                                                <div className="d-flex flex-wrap">
                                                    <Form.Group className="addresscheck  me-3" id="formGridCheckbox">
                                                        <Form.Check
                                                            type="radio"
                                                            name="addressType"
                                                            label="Home"
                                                            value="Home"
                                                            defaultChecked={getValues("address_type") === "Home"}
                                                            {...register("address_type", {
                                                                required: Errors.addressType,
                                                            })}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="addresscheck  me-3" id="formGridCheckbox">
                                                        <Form.Check
                                                            type="radio"
                                                            name="addressType"
                                                            label="Work"
                                                            value="Work"
                                                            defaultChecked={getValues("address_type") === "Work"}
                                                            {...register("address_type", {
                                                                required: Errors.addressType,
                                                            })}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="addresscheck" id="formGridCheckbox">
                                                        <Form.Check
                                                            type="radio"
                                                            name="addressType"
                                                            label="Office"
                                                            value="Office"
                                                            defaultChecked={getValues("address_type") === "Office"}
                                                            {...register("address_type", {
                                                                required: Errors.addressType,
                                                            })}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="text-end">
                                            <ButtonCommon
                                                buttontype="button"
                                                text="Back"
                                                className="deletebtn me-2"
                                                onClick={handlebackPrevious}
                                            />
                                            <ButtonCommon
                                                buttontype="button"
                                                text="Save"
                                                className="addbtn"
                                                onClick={handleSubmit(onSubmit)}
                                            />
                                        </div>
                                    </Form>
                                </div>
                            )}
                        </section>
                    </Col>
                    {/* <Col lg={6} md={12} sm={12} xs={12}>
                        <Form.Check
                            label='Ship to a different address?'
                            className='mb-2'
                            onChange={handleCheckboxChange}
                        />
                        <div className='brown-border'></div>
                        {showShippingForm && (
                            <div>
                                <Form>
                                    <Row>
                                        <Col lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-3" controlId="formShippingFirstName">
                                                <Form.Label>First name <span className='red-star'>*</span></Form.Label>
                                                <Form.Control type="text" required />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-3" controlId="formShippingLastName">
                                                <Form.Label>Last name <span className='red-star'>*</span></Form.Label>
                                                <Form.Control type="text" required />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-3" controlId="formShippingCompanyName">
                                                <Form.Label>Company name (optional)</Form.Label>
                                                <Form.Control type="text" />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-3" controlId="formShippingStreetAddress">
                                                <Form.Label>Street address <span className='red-star'>*</span></Form.Label>
                                                <Form.Control type="text" placeholder='House Number and street name' required />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-3" controlId="formShippingCity">
                                                <Form.Label>Town / City <span className='red-star'>*</span></Form.Label>
                                                <Form.Control type="text" required />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-3" controlId="formShippingState">
                                                <Form.Label>State <span className='red-star'>*</span></Form.Label>
                                                <Form.Control type="text" required />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-3" controlId="formShippingZipCode">
                                                <Form.Label>ZIP Code <span className='red-star'>*</span></Form.Label>
                                                <Form.Control type="number" required />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-3" controlId="formShippingPhone">
                                                <Form.Label>Phone <span className='red-star'>*</span></Form.Label>
                                                <Form.Control type="number" required />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-3" controlId="formShippingOrderNotes">
                                                <Form.Label>Order notes (optional)</Form.Label>
                                                <Form.Control as="textarea" rows={3} placeholder='about your order' />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        )}
                    </Col> */}
                    <Col lg={12} md={12} sm={12} xs={12} className="mt-5">
                        {/* Order summary */}
                        <h5 className="check-title">Your order</h5>
                        <div className="brown-border"></div>

                        {/* Display each product in the cart */}
                        {signin &&
                            cartsignin &&
                            cartsignin.map((product, index) => (
                                <div key={index}>
                                    <div className="cream-bgg">
                                        <Row>
                                            <Col lg={8} md={6} sm={6} xs={6}>
                                                <p className="dark-text">{product.product_title}</p>
                                            </Col>
                                            <Col lg={4} md={6} sm={6} xs={6}>
                                                <p>₹{product.selling_price}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ))}

                        {/* Subtotal */}
                        <div className="cream-bgg">
                            <Row>
                                <Col lg={8} md={6} sm={6} xs={6}>
                                    <p className="dark-text">Subtotal</p>
                                </Col>
                                <Col lg={4} md={6} sm={6} xs={6}>
                                    <p>₹{totalPrice}</p>
                                </Col>
                            </Row>
                        </div>

                        {/* Shipping */}
                        <div className="cream-bgg">
                            <Row>
                                <Col lg={8} md={6} sm={6} xs={6}>
                                    <p className="dark-text">Shipping</p>
                                </Col>
                                <Col lg={4} md={6} sm={6} xs={6}>
                                    <p>Flat Rate</p>
                                </Col>
                            </Row>
                        </div>

                        {/* Total */}
                        <div className="cream-bgg">
                            <Row>
                                <Col lg={8} md={6} sm={6} xs={6}>
                                    <p className="dark-text">Total</p>
                                </Col>
                                <Col lg={4} md={6} sm={6} xs={6}>
                                    <p>₹{totalPrice}</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mt-5">
                        <p className="text">Select a payment option</p>

                        <div className="light-blue-bg">
                            {/* <Form>
                                <Form.Check
                                    type="radio"
                                    label="Direct bank transfer"
                                    onChange={() => handleRadioChange("bank")}
                                    checked={selectedOption === "bank"}
                                />
                                {selectedOption === "bank" && (
                                    <div className="main-blue">
                                        <p className="dark-blueee-txtt">
                                            Make your payment directly into our bank account. Please use your Order ID
                                            as the payment reference. Your order will not be shipped until the funds
                                            have cleared in our account.
                                        </p>
                                        <div className="tringle-blue"></div>
                                    </div>
                                )}

                                <Form.Check
                                    type="radio"
                                    label="Check payments"
                                    onChange={() => handleRadioChange("check")}
                                    checked={selectedOption === "check"}
                                />
                                {selectedOption === "check" && (
                                    <div className="main-blue">
                                        <p className="dark-blueee-txtt">
                                            Please send a check to Store Name, Store Street, Store Town, Store State /
                                            County, Store Postcode.
                                        </p>
                                        <div className="tringle-blue"></div>
                                    </div>
                                )}

                                <Form.Check
                                    type="radio"
                                    label="PayPal"
                                    onChange={() => handleRadioChange("paypal")}
                                    checked={selectedOption === "paypal"}
                                />
                                <div>
                                    <img
                                        src={process.env.PUBLIC_URL + "/assets/Image/Logo/AM_mc_vs_dc_ae.jpg"}
                                        className="ms-4 paypal-imgg"
                                        alt="PayPal"
                                    />
                                </div>
                                {selectedOption === "paypal" && (
                                    <div className="main-blue">
                                        <p className="dark-blueee-txtt">
                                            Pay via PayPal; you can pay with your credit card if you don’t have a PayPal
                                            account.
                                        </p>
                                        <div className="tringle-blue"></div>
                                    </div>
                                )}
                            </Form> */}
                            <div className="brown-borderr"></div>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Row>
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <div className="Tabs-section">
                                            <Nav variant="pills" className="">
                                                <Nav.Item onClick={() => handleMethod("cod")}>
                                                    <Nav.Link eventKey="six">
                                                        <div className="type-payment">
                                                            <p className="sub-text">Cash on Delivery</p>
                                                            <div className="pay">
                                                                {/* Any payment method specific content */}
                                                            </div>
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item onClick={() => handleMethod("Googlepay")}>
                                                    <Nav.Link eventKey="five">
                                                        <div className="type-payment">
                                                            <p className="sub-text">Googlepay</p>
                                                            <div className="pay">
                                                                <img
                                                                    src={
                                                                        process.env.PUBLIC_URL +
                                                                        "/assets/Image/payment/paytm.webp"
                                                                    }
                                                                    className="pay"
                                                                />
                                                            </div>
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item onClick={() => handleMethod("PhonePay")}>
                                                    <Nav.Link eventKey="seven">
                                                        <div className="type-payment">
                                                            <p className="sub-text">PhonePay</p>
                                                            <div className="pay">
                                                                <img
                                                                    src={
                                                                        process.env.PUBLIC_URL +
                                                                        "/assets/Image/payment/phonepe.webp"
                                                                    }
                                                                    className="pay"
                                                                />
                                                            </div>
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                    </Tab.Container>
                                </Row>
                            </Tab.Container>
                            <div className="text-end">
                                <div className="text-end">
                                    <div className="order-main">
                                        {paymentMethod === "cod" ? (
                                            <>
                                                <Button type="button" className="order" onClick={handlePlaceOrderClick}>
                                                    Place Order
                                                </Button>
                                                <OrderSuccessFull
                                                    isOpen={isModalOpen}
                                                    onConfirm={handleConfirmOrder}
                                                    onClose={handleCloseModal}
                                                />
                                            </>
                                        ) : (
                                            signin && (
                                                <Button
                                                    type="button"
                                                    className="order"
                                                    onClick={() => handleRazorpay(totalPrice)}
                                                >
                                                    Complete Order
                                                </Button>
                                            )
                                        )}
                                    </div>

                                    {/* Modal to show on success */}
                                    {showModal && (
                                        <OrderSuccessFull onClose={handleCloseModal}>
                                            <h2>Payment Successful!</h2>
                                            <p>Your order has been placed successfully.</p>
                                        </OrderSuccessFull>
                                    )}
                                </div>
                            </div>
                            <Successfull_Modal show={modalShow} onHide={() => setModalShow(false)} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default CheckoutForm;
