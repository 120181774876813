import React, { useContext, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import './ContactUs.css'
import { faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonCommon from '../CommonComponets/ButtonCommon/ButtonCommon'
import Breadcrumbs from '../CommonComponets/Breadcrumbs/Breadcrumbs'
// import { postContactUs } from '../../utils/apis/master/Master'
import { RegxExpression } from "../../utils/common";
import { Context } from '../../utils/context'
import { useForm } from 'react-hook-form'
import classNames from "classnames";
import Successfull_Modal from '../CommonComponets/Successfull_Modal/Successfull_Modal'
import { postContactUs } from '../../utils/apis/master/Master'
function ContactUs() {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/" },
        { text: "Contact", link: "#" },
    ]);
    const { IMG_URL, userdata, getData, postData, deleteData, sellerId } = useContext(Context);
    const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();
    const [successModalShow, setSuccessModalShow] = useState(false);
    const onSubmit = async (data) => {
        data.seller_id = sellerId;
        const res = await postContactUs(data)
        if (res?.success) {
            reset();
            setSuccessModalShow(true);
            setTimeout(() => {
                setSuccessModalShow(false);
            }, 1000);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }
    return (
        <>
            <section className='contact_section mt-4 mb-2'>
                <Container>
                    <div className='contact_main'>
                        <div>
                            <Breadcrumbs items={breadcrumbItems} />
                        </div>
                        <div className="gmap_canvas mb-5">
                            <iframe
                                className="gmap_iframe"
                                frameborder="0"
                                scrolling="no"
                                marginheight="0"
                                marginwidth="0"
                                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            ></iframe>
                            <a href="https://pdflist.com/" alt="pdflist.com"></a>
                        </div>

                        <div className='contctform_div'>
                            <Row>
                                <Col xxl={4} xl={4} lg={5} className='mb-3'>
                                    <div className=''>
                                        <h4 className='title'>WELCOME TO TOKYO</h4>
                                        <p className='text botombord mb-0 py-3'>Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis nostrud exercitation ullamco laboris nisi ut ali</p>
                                        <div className='text botombord d-flex py-3'>
                                            <FontAwesomeIcon icon={faLocationDot} className='me-3' />
                                            <p className=' m-0' >123 Suspendis matis, Sollicitudin District, Accums Fringilla</p>
                                        </div>
                                        <div className='text botombord d-flex py-3'>
                                            <FontAwesomeIcon icon={faEnvelope} className='me-3' />
                                            <p className='m-0'>Email: example@gmail.com</p>
                                        </div>
                                        <div className='text d-flex py-3'>
                                            <FontAwesomeIcon icon={faPhone} className='me-3' />
                                            <p className=' m-0'>Hotline: 0123456789</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={8} xl={8} lg={7} className='mb-3'>
                                    <div className=''>

                                        <h4 className='title mb-4'>LEAVE US A MESSAGE</h4>

                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row>
                                                <Col xxl={4} xl={4} lg={4} className='mb-3'>
                                                    <Form.Control
                                                        className={classNames("", {
                                                            "is-invalid": errors?.name,
                                                        })}
                                                        {...register("name", {
                                                            required: "Name is required"
                                                        })}
                                                        onKeyDown={(event) => {
                                                            if (!/[A-Z-a-z ]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        type="text"
                                                        placeholder="Your name"
                                                    />
                                                </Col>
                                                <Col xxl={4} xl={4} lg={4} className='mb-3'>
                                                    <Form.Control
                                                        className={classNames("", {
                                                            "is-invalid": errors?.email,
                                                        })}
                                                        {...register("email", {
                                                            required: "Email is required",
                                                            pattern: {
                                                                value: RegxExpression.email,
                                                                message: "Invalid email address",
                                                            },
                                                        })}
                                                        type="email"
                                                        placeholder="Email" />
                                                </Col>
                                                <Col xxl={4} xl={4} lg={4} className='mb-3'>
                                                    <Form.Control
                                                        className={classNames("", {
                                                            "is-invalid": errors?.subject,
                                                        })}
                                                        {...register("subject", {
                                                            required: "Subject is required"
                                                        })}
                                                        type="text" placeholder="Subject" />
                                                </Col>
                                                <Col xxl={12} className='mb-3'>

                                                    <Form.Control
                                                        className={classNames("", {
                                                            "is-invalid": errors?.message,
                                                        })}
                                                        {...register("message", {
                                                            required: "Message is required"
                                                        })}
                                                        as="textarea"
                                                        placeholder="Your message"
                                                        rows={3}
                                                    />
                                                </Col>
                                            </Row>
                                            <div>
                                                {/* <button className='sendbutton'>Send Message</button> */}
                                                <ButtonCommon text="Send Message" className='sendbutton' onClick={handleSubmit(onSubmit)} />
                                            </div>
                                        </Form>

                                    </div>
                                </Col>
                            </Row>
                        </div>


                    </div>
                </Container>
            </section>
            <Successfull_Modal show={successModalShow} namess={'Form Submitted Successfully'} />
        </>
    )
}

export default ContactUs