import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Col, Container, Row } from 'react-bootstrap';
import './Dashboard/Dashboard.css'
import Dashboard from './Dashboard/Dashboard';
import Orders from './Orders/Orders';
import Downloads from './Downloads/Downloads';
import Address from './Address/Address';
import AccountDetails from './AccountDetails/AccountDetails';

const MyAccount = () => {
  return (
    <section className='my-account my-5'>
        <Container>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                      <Col lg={2} md={2} sm={12} xs={12}>
                          <Nav variant="pills" className="flex-column">
                              <Nav.Item>
                                  <Nav.Link eventKey="first">Dashboard</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                  <Nav.Link eventKey="second">Orders</Nav.Link>
                              </Nav.Item>
                              {/* <Nav.Item>
                                  <Nav.Link eventKey="third">Download</Nav.Link>
                              </Nav.Item> */}
                              <Nav.Item>
                                  <Nav.Link eventKey="fourth">Address</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                  <Nav.Link eventKey="fifth">Account Details</Nav.Link>
                              </Nav.Item>
                          </Nav>
                      </Col>
                      <Col lg={10} md={10} sm={12} xs={12}>
                          <Tab.Content>
                              <Tab.Pane eventKey="first"><Dashboard/></Tab.Pane>
                              <Tab.Pane eventKey="second"><Orders/></Tab.Pane>
                              {/* <Tab.Pane eventKey="third"><Downloads/></Tab.Pane> */}
                              <Tab.Pane eventKey="fourth"><Address/></Tab.Pane>
                              <Tab.Pane eventKey="fifth"><AccountDetails/></Tab.Pane>
                          </Tab.Content>
                      </Col>
                  </Row>
              </Tab.Container>
        </Container>
    </section>
  )
}

export default MyAccount