import React, {useContext, useEffect, useState} from "react";
import "./RecentlyView.css";
import {Container} from "react-bootstrap";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Pagination} from "swiper/modules";
import AddCartProduct from "../../CommonComponets/AddCartProduct/AddCartProduct";
import {latestPost} from "../../../utils/apis/common/Common";
import {Context} from "../../../utils/context";
import Cookies from "js-cookie";

function RecentlyView() {
    const [swiperInstance3, setSwiperInstance3] = useState(null);

    const handleMainSliderClick3 = (direction) => {
        if (swiperInstance3) {
            if (direction === "prev") {
                swiperInstance3.slidePrev();
            } else if (direction === "next") {
                swiperInstance3.slideNext();
            }
        }
    };

    const addproduct = [
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Categories/lotus.jpg",
            category: "Beauty",
            name: "CACTUS STOPPERS AND CHARM SET IMPERDIET",
            details:
                "Aenean in mattis velit. Mauris dolor ante, congue a sagittis quis, varius eu justo. In dictum feugiat sem quis efficitur.",
            mainprice: "$20.00",
            discountprice: "$18.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Categories/lotus.jpg",
            category: "Beauty",
            name: "CACTUS STOPPERS AND CHARM SET IMPERDIET",
            details:
                "Aenean in mattis velit. Mauris dolor ante, congue a sagittis quis, varius eu justo. In dictum feugiat sem quis efficitur.",
            mainprice: "$20.00",
            discountprice: "$18.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Categories/slvarman.jpg",
            category: "Beauty",
            name: "CACTUS STOPPERS AND CHARM SET IMPERDIET",
            details:
                "Aenean in mattis velit. Mauris dolor ante, congue a sagittis quis, varius eu justo. In dictum feugiat sem quis efficitur.",
            mainprice: "$20.00",
            discountprice: "$18.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Categories/scrub.jpg",
            category: "Beauty",
            name: "CACTUS STOPPERS AND CHARM SET IMPERDIET",
            details:
                "Aenean in mattis velit. Mauris dolor ante, congue a sagittis quis, varius eu justo. In dictum feugiat sem quis efficitur.",
            mainprice: "$20.00",
            discountprice: "$18.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Categories/love.jpg",
            category: "Beauty",
            name: "CACTUS STOPPERS AND CHARM SET IMPERDIET",
            details:
                "Aenean in mattis velit. Mauris dolor ante, congue a sagittis quis, varius eu justo. In dictum feugiat sem quis efficitur.",
            mainprice: "$20.00",
            discountprice: "$18.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Categories/lipstick.jpg",
            category: "Beauty",
            name: "CACTUS STOPPERS AND CHARM SET IMPERDIET",
            details:
                "Aenean in mattis velit. Mauris dolor ante, congue a sagittis quis, varius eu justo. In dictum feugiat sem quis efficitur.",
            mainprice: "$20.00",
            discountprice: "$18.00",
        },
    ];
   
    const {sellerId, IMG_URL} = useContext(Context);
    const cookArr = Cookies.get("ProductIds");

    const [recentData, setRecentdata] = useState([]);
    const getRecentdata = async () => {
        const res = await latestPost(sellerId, cookArr);
        if (res?.success) {
            setRecentdata(res?.data);
        }
    };

    useEffect(() => {
        getRecentdata();
    }, []);

    return (
        <>
            <section className="recently_section">
                <Container>
                    <div className="deals_main_div mt-5">
                        <div className="">
                            <h6 className="title">Recently Viewed</h6>
                        </div>
                        <div className="swipermain_div mb-4">
                            <Swiper
                                spaceBetween={30}
                                modules={[]}
                                autoplay={{delay: 2000}}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                    },

                                    480: {
                                        slidesPerView: 2,
                                    },

                                    640: {
                                        slidesPerView: 2,
                                    },

                                    768: {
                                        slidesPerView: 3,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                    },
                                    1250: {
                                        slidesPerView: 4,
                                    },
                                }}
                                onSwiper={(swiper) => setSwiperInstance3(swiper)}
                                className="mySwiper px-2 py-2"
                            >
                                {recentData.map((recentproduct, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="prodban_div ">
                                            <AddCartProduct 
                                               image={IMG_URL + recentproduct?.thumbnail}
                                               title={recentproduct.product_title}
                                               price1={recentproduct.price1}
                                              price2={recentproduct.selling_price}
                                           
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="swiperbutton_div">
                                <div className="buttondiv" onClick={() => handleMainSliderClick3("prev")}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </div>
                                <div className="buttondiv" onClick={() => handleMainSliderClick3("next")}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
}

export default RecentlyView;
