import React, { useContext } from 'react'
import './RecentNews.css'
import { Card, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { Context } from '../../../utils/context';

const newsItems = [
  {
    id: 1,
    imgSrc: '/assets/Image/blog/img-1.png',
    title: 'Produce top level purified bottled water',
    date: 'September 25, 2023'
  },
  {
    id: 2,
    imgSrc: '/assets/Image/blog/img-2.png',
    title: 'How water useful for our body & life',
    date: 'September 25, 2023'
  },
  {
    id: 3,
    imgSrc: '/assets/Image/blog/img-3.png',
    title: 'We are promised to deliver bottled water in-time',
    date: 'September 25, 2023'
  }
];

const RecentNews = ({blogdata}) => {
  const { IMG_URL, sellerId } = useContext(Context);
  return (
    <section className='recent-news '>
      {/* <div className='recent-sec-border'>
        <h3 className='title'>CATEGORIES</h3>
        <div className='brown-border mb-3'></div>
        <Link to='/blogdetails'><p className='sub-title-recent'>Accessories (4)</p></Link>
        <Link to='/blogdetails'><p className='sub-title-recent'>Air Conditional (3)</p></Link>
        <Link to='/blogdetails'><p className='sub-title-recent'>Blog (10)</p></Link>
        <Link to='/blogdetails'><p className='sub-title-recent'>Cameras & Photo (3)</p></Link>
        <Link to='/blogdetails'><p className='sub-title-recent'>Kid's Clothing (1)</p></Link>
        <Link to='/blogdetails'><p className='sub-title-recent'>Post Format (8)</p></Link>
        <Link to='/blogdetails'><p className='sub-title-recent'>Summer Season (4)</p></Link>
      </div> */}
      <div className='recent-sec-border'>
        <h3 className='title'>Latest Blog</h3>
        <div className='brown-border mb-3'></div>
        {blogdata?.recent_post?.map((item,index) => (
          <Link to={`/blogdetails/${item?.id}`}>
            <div className='recent-news-blog-box' key={item?.id}>
              <Row>
                <Col md={3} sm={3} xs={3}>
                  <img src={IMG_URL + item?.image} className='recent-img'  />
                </Col>
                <Col md={9} sm={9} xs={9}>
                  <h4 className='recent-title'>{item?.name}</h4>
                  <span className='sub-title-recent'><FontAwesomeIcon icon="fa-solid fa-calendar-days" className='me-2 common-txt' />{item?.createdAt ? new Date(item?.createdAt).toLocaleString() : ''}</span>
                </Col>
              </Row>
            </div>
          </Link>
        ))}
      </div>
      {/* <div className='recent-sec-border'>
        <h3 className='title'>Recent Post</h3>
        <div className='brown-border mb-3'></div>
        <Link to='/blogdetails'><p className='sub-title-recent'>Post Format Image</p></Link>
        <Link to='/blogdetails'><p className='sub-title-recent'>Post Format Gallery</p></Link>
        <Link to='/blogdetails'><p className='sub-title-recent'>Post Format Audio</p></Link>
        <Link to='/blogdetails'><p className='sub-title-recent'>Post Format: Status</p></Link>
        <Link to='/blogdetails'><p className='sub-title-recent'>Post Format Gallery</p></Link>
        <Link to='/blogdetails'><p className='sub-title-recent'>Post Format Audio</p></Link>
        <Link to='/blogdetails'><p className='sub-title-recent'>Post Format: Status</p></Link>
      </div> */}
    </section>
  )
}

export default RecentNews
