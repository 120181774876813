import React, { useContext, useEffect, useState } from 'react'
import ProductSlider from './ProductSlider/ProductSlider'
import Description from './Description/Description'
import { useParams } from 'react-router-dom';
import { Context } from '../../utils/context';
import { getProductDetail, getReview, productReviewOnlyOnce } from '../../utils/apis/Product/Product';

const ProductDetail = () => {
  const { id } = useParams();
  const { sellerId, IMG_URL } = useContext(Context);



  const [reviewdetail, setReviewdetail] = useState([]);

  const getReviews = async () => {
    try {
      const response = await getReview(id);
      if (response?.success) {
        setReviewdetail(response.data.data);
        console.log(response.data.data, "gauata");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getReviews();
  }, []);

  const [userreviewed, setUserReviewed] = useState([]);

  const getUserReviewee = async () => {
    const res = await productReviewOnlyOnce(id, sellerId);

    const ratings = res?.data?.reviews?.map((review) => review?.rating);
    setUserReviewed(ratings);
    console.log(ratings, "gauarav");
  };

  useEffect(() => {
    getUserReviewee();
  }, []);


  const [avrage, setaverage] = useState(0);
  var average = 0;
  useEffect(() => {
    // Calculate sum and count inside this useEffect, which runs after reviewdetail is updated
    const array = userreviewed?.map(Number); // Convert each element to a number
    const sum = array?.reduce((acc, curr) => acc + curr, 0); // Sum of array elements
    const count = array?.length;
    setaverage(sum / count);

  }, [userreviewed]);




const [productdetailed, setProductdetailed]= useState({});

  const getProductData = async (id) => {
    {
        const res = await getProductDetail(id, sellerId);
        if (res?.success) {
          setProductdetailed(res.data);
          console.log(res?.data, "product Details");
        }
    }
};
useEffect(() => {
  if (id) {
      getProductData(id);
      //   getCartCheckProduct();
  }
}, [id]);



  return (
    <>
       <ProductSlider
      average={avrage}
      id={id}
      reviewdetail={reviewdetail}
      sellerId={sellerId}
      IMG_URL={IMG_URL}/>
      <Description
      productdetailed={productdetailed}
     
      /> 
    </>
  )
}

export default ProductDetail
