import React from "react";
import "./Footer.css";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import Nav from "react-bootstrap/Nav";
import { NavLink, Link } from "react-router-dom";
import { faFacebookF, faInstagram, faPinterestP, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Tween } from "jquery";
import { FaTwitterSquare } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <section className="footer">
        <div className="footer-first-sec">
          <Container className="text-center">
              <Row className=' row-cols-xl-5 row-cols-md-3 row-cols-sm-2 row-cols-2 p-2'>
              <Col >
                <div className="main-pink">
                  <div className="pink-bgggg">
                    <FontAwesomeIcon icon="fa-solid fa-box-open" className="order-iconnn" />
                  </div>
                  <p className="footer-txttt">ORDER ISSUES</p>
                </div>
              </Col>
              <Col >
                <div className="main-pink">
                  <div className="pink-bgggg">
                    <FontAwesomeIcon icon="fa-solid fa-piggy-bank" className="order-iconnn" />
                  </div>
                  <p className="footer-txttt">PAYMENT & PROMO</p>
                </div>
              </Col>
              <Col>
                <div className="main-pink">
                  <div className="pink-bgggg">
                    <FontAwesomeIcon icon="fa-solid fa-truck-fast" className="order-iconnn" />
                  </div>
                  <p className="footer-txttt">Shipping</p>
                </div>
              </Col>
              <Col >
                <div className="main-pink">
                  <div className="pink-bgggg">
                    <FontAwesomeIcon icon="fa-solid fa-hand-holding-dollar" className="order-iconnn" />
                  </div>
                  <p className="footer-txttt">Return & Refund</p>
                </div>
              </Col>
              <Col >
                <div className="main-pink">
                  <div className="pink-bgggg">
                    <FontAwesomeIcon icon="fa-solid fa-truck-ramp-box" className="order-iconnn" />
                  </div>
                  <p className="footer-txttt">Product & Stock</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Second section */}

        <div className="footer-second-sec">
          <Container>
              <Row className=' row-cols-xl-5 row-cols-md-3 row-cols-sm-2 row-cols-2 p-2'>
              <Col className="text-center">
                <img src={process.env.PUBLIC_URL + '/assets/Image/Logo/logo-1.png'} />
                <p>AFFORDABLE QUALITY
                  FUN SHOPPING</p>
              </Col>
              <Col>
                <div className="text-center">
                  <FontAwesomeIcon icon="fa-solid fa-truck" className="service-iconn" />
                  <h5 className="service-head">Express Shipping</h5>
                  <p className="footer-txttttt">Fast, reliable delivery from global warehouses</p>
                </div>
              </Col>
              <Col>
                <div className="text-center">
                  <FontAwesomeIcon icon="fa-solid fa-gift" className="service-iconn" />
                  <h5 className="service-head">Surprise Gifts</h5>
                  <p className="footer-txttttt">Refer a Friend</p>
                </div>
              </Col>
              <Col>
                <div className="text-center">
                  <FontAwesomeIcon icon="fa-solid fa-phone" className="service-iconn" />
                  <h5 className="service-head">Surprise Gifts</h5>
                  <p className="footer-txttttt">Refer a Friend</p>
                </div>

              </Col>
              <Col>
                <div className="text-center">
                  <FontAwesomeIcon icon="fa-solid fa-cart-plus" className="service-iconn" />
                  <h5 className="service-head">Surprise Gifts</h5>
                  <p className="footer-txttttt">Refer a Friend</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Third Section */}
        <div className="footer-third-sec">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12} >
                <div className="first-divvv">
                  <p className="third-sec-txtt">ADD MORE BEAUTY TO YOUR EMAIL</p>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Enter your email..."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <InputGroup.Text id="basic-addon2"><FontAwesomeIcon icon="fa-solid fa-paper-plane" /></InputGroup.Text>
                  </InputGroup>
                  <p className="footer-txttt">By submitting your application, you agree to our Terms & Conditions and Privacy Policy.</p>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12} >
                <div className="second-divv">
                  <p className="third-sec-txtt">STAY IN TOUCH WITH US</p>
                  <div className="d-lg-flex">
                    <p className="footer-txttt me-5"><FontAwesomeIcon icon="fa-solid fa-phone-volume" className="me-2" /> 8882-999-4553</p>
                    <p className="footer-txttt"><FontAwesomeIcon icon={faEnvelope} className="me-2" />demo@example.com</p>
                  </div>
                  <div>
                    <Link to='https://www.facebook.com/'><FontAwesomeIcon icon={faFacebookF} className="me-4" /></Link>
                    <Link to='https://x.com/'><FontAwesomeIcon icon={faTwitter} className="me-4" /></Link>
                    <Link to='https://www.pinterest.com/'><FontAwesomeIcon icon={faPinterestP} className="me-4" /></Link>
                    <Link to='https://www.instagram.com/'><FontAwesomeIcon icon={faInstagram} className="me-4" /></Link>
                  </div>
                </div>                
              </Col>
            </Row>
          </Container>

        </div>
    
        {/* Fourth Section */}
        <Container >
          <div >
            <Nav
              defaultActiveKey="/"
              className="mx-auto justify-content-center"
            >
              <Nav.Item>
                <NavLink className="nav-link" as={Link} to="/">
                  Home
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink className="nav-link" as={Link} to="/contactus">
                  Contact Us
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink className="nav-link" as={Link} to="/my-account">
                  My Account
                </NavLink>
              </Nav.Item>
              {/* <Nav.Item>
                <NavLink className="nav-link" as={Link} to="/order-status">
                  Order Status
                </NavLink>
              </Nav.Item> */}
              <Nav.Item>
                <NavLink className="nav-link" as={Link} to="/ship-returns">
                  Shipping & returns
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink className="nav-link" as={Link} to="/privacy-policy">
                  PRIVACY POLICY
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink className="nav-link" as={Link} to="/terms-condition">
                  TERMS & CONDITIONS
                </NavLink>
              </Nav.Item>
            </Nav>
          </div>
          <div className="text-center mb-5">
            <p className="last-line">© 2024 All Rights Reserved Terms Of Use | Privacy Policy Powered by <span className="prof-title">Profcyma</span></p> 
          </div>
        </Container>
      </section>
    </>
  );
};

export default Footer;
