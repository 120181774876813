import React, {useState} from "react";
import {Col, Container, Nav, Row, Tab} from "react-bootstrap";
import "./TrendingProducts.css";
import {Rating} from "react-simple-star-rating";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const TrendingProducts = () => {
    // Rating star
    const [rating, setRating] = useState(0);

    // Catch Rating value
    const handleRating = (rate: number) => {
        setRating(rate);

        // other logic
    };
    // Optinal callback functions
    const onPointerEnter = () => console.log("Enter");
    const onPointerLeave = () => console.log("Leave");
    const onPointerMove = (value: number, index: number) => console.log(value, index);

    const trending = [
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Trendingproduct/strawberry.jpg",
            name: "Fresh Red Apples",
            price: "$66.00",
            kg: "1kg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Trendingproduct/strawberry.jpg",
            name: "Fresh Red Apples",
            price: "$66.00",
            kg: "1kg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Trendingproduct/strawberry.jpg",
            name: "Fresh Red Apples",
            price: "$66.00",
            kg: "1kg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Trendingproduct/strawberry.jpg",
            name: "Fresh Red Apples",
            price: "$66.00",
            kg: "1kg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Trendingproduct/strawberry.jpg",
            name: "Fresh Red Apples",
            price: "$66.00",
            kg: "1kg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Trendingproduct/strawberry.jpg",
            name: "Fresh Red Apples",
            price: "$66.00",
            kg: "1kg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Trendingproduct/strawberry.jpg",
            name: "Fresh Red Apples",
            price: "$66.00",
            kg: "1kg",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/Image/Homepage/Trendingproduct/strawberry.jpg",
            name: "Fresh Red Apples",
            price: "$66.00",
            kg: "1kg",
        },
    ];

    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <section className="TrendingProducts">
                <Container>
                    <div className="main_feature_div">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <div className="topheaddiv  mb-3">
                                <h4 className="title">Trending Products </h4>

                                <div>
                                    <Nav variant="pills" className="">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Butter & Eggs</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Fastfood</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Fresh Meat</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="fourth">Ocean Foods</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="five">Vegetables</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <div className="Main-Mobile-View">
                                <h4 className="title">TrendingProducts Products</h4>
                                <div className="Mobile-View">
                                    <FontAwesomeIcon icon={faBars} onClick={handleToggle} />
                                    {isOpen && (
                                        <div>
                                            <Nav variant="pills" className="">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first">Butter & Eggs</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">Fastfood</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third">Fresh Meat</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fourth">Ocean Foods</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="five">Vegetables</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Row>
                                            {trending.map((item, index) => (
                                                <Col xxl={3} xl={3} lg={4} md={4} sm={6} xs={12} key={index}>
                                                    <div className="main-card">
                                                        <div className="main-product">
                                                            <div className="trending">
                                                                <img
                                                                    className="product"
                                                                    src={item.image}
                                                                    alt={item.name}
                                                                />
                                                            </div>
                                                            <div className="Content">
                                                                <Rating
                                                                    onClick={handleRating}
                                                                    onPointerEnter={onPointerEnter}
                                                                    onPointerLeave={onPointerLeave}
                                                                    onPointerMove={onPointerMove}
                                                                    /* Add other necessary props if required */
                                                                />
                                                                <h4 className="sub-title">{item.name}</h4>
                                                                <p className="price">
                                                                    {item.price}
                                                                    <span className="kg">/{item.kg}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="second">
                                        <Row>
                                            {trending.map((item, index) => (
                                                <Col xxl={3} xl={3} lg={4} md={4} sm={6} xs={12} key={index}>
                                                    <div className="main-card">
                                                        <div className="main-product">
                                                            <div className="trending">
                                                                <img
                                                                    className="product"
                                                                    src={item.image}
                                                                    alt={item.name}
                                                                />
                                                            </div>
                                                            <div className="Content">
                                                                <Rating
                                                                    onClick={handleRating}
                                                                    onPointerEnter={onPointerEnter}
                                                                    onPointerLeave={onPointerLeave}
                                                                    onPointerMove={onPointerMove}
                                                                    /* Add other necessary props if required */
                                                                />
                                                                <h4 className="sub-title">{item.name}</h4>
                                                                <p className="price">
                                                                    {item.price}
                                                                    <span className="kg">/{item.kg}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third"></Tab.Pane>
                                    <Tab.Pane eventKey="fourth"></Tab.Pane>
                                    <Tab.Pane eventKey="five"></Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default TrendingProducts;
