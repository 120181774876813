import React, { useContext, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { Context } from "../../../utils/context";
import { useForm } from "react-hook-form";
import { userRegister } from "../../../utils/apis/login/Login";
import Cookies from "js-cookie";
import classNames from "classnames";
import { RegxExpression } from "../../../utils/common";

function Register({setShowRegisterForm}) {
    const [showEmail, setShowEmail] = useState(true);
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [showLogin, setShowLogin] = useState(false);
    const [phoneValue, setPhoneValue] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const { signin, setSignin, sellerId } = useContext(Context);

    const {
        register,
        handleSubmit,
        getValues,
        setError,
        formState: { errors },
        reset,
    } = useForm();

    const onSubmit = async (data) => {
        if (data.password === data.re_password) {
            data.seller_id = sellerId;
            data.contact_no = phoneValue;
            const res = await userRegister(data);
            if (res?.success) {
                await Cookies.set("ecommerce3_security", res?.data?.token);
                setSignin(true);
                reset();
                navigate("/");
            } else {
                if (res?.message?.both) {
                    setError("email", { message: res?.message?.both });
                    setError("contact_no", { message: "" });
                }
                if (res?.message?.email) {
                    setError("email", { message: res?.message?.email });
                }
                if (res?.message?.contact_no) {
                    setError("contact_no", { message: res?.message?.contact_no });
                }
            }
        } else {
            setError("password", { message: "Password does not match" });
        }
    };

    return (
        <section className="login_section">
            <Container fluid>
                <div>
                    <Row className="justify-content-center mx-auto">
                        <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} className="p-4 p-lg-4">
                            <div className="login-bggg ">
                                <div className="text-center">
                                    <h1 className="title">CREATE AN ACCOUNT.</h1>
                                </div>

                                <div>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlInput1"
                                        >
                                            <Form.Label className="text">Full Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Full Name"
                                                {...register("first_name", {
                                                    required: "First name required",
                                                })}
                                                className={classNames("", {
                                                    "is-invalid": errors?.first_name,
                                                    "is-valid": getValues("first_name"),
                                                })}
                                                onKeyDown={(event) => {
                                                    if (!RegxExpression.name.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                        <div>

                                            <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlInput1"
                                            >
                                                <Form.Label className="text">Email</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="name@example.com"
                                                    {...register("email", {
                                                        required: "Email is required",
                                                        pattern: {
                                                            value: RegxExpression.email,
                                                            message: "Invalid email address",
                                                        },
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.email,
                                                        "is-valid": getValues("email"),
                                                    })}
                                                />
                                            </Form.Group>

                                            <Form.Label className="text">Phone</Form.Label>
                                            <PhoneInput
                                                className="mb-3"
                                                country={"in"}
                                                value={phoneValue}
                                                onChange={setPhoneValue}
                                                inputProps={{
                                                    name: "phone",
                                                    required: true,
                                                    autoFocus: true,
                                                }}
                                            />


                                        </div>

                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlTextarea1"
                                        >
                                            <Form.Label className="text">Password</Form.Label>
                                            <div className="fcont_div">
                                                <Form.Control
                                                    type={showPassword ? "text" : "password"}
                                                    placeholder="password"
                                                    {...register("password", {
                                                        required: "Password is required",
                                                        minLength: {
                                                            value: 8,
                                                            message:
                                                                "Password must be at least 8 characters long",
                                                        },
                                                        pattern: {
                                                            value:
                                                                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                                            message:
                                                                "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                                        },
                                                    })}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <FontAwesomeIcon
                                                    icon={showPassword ? faEyeSlash : faEye}
                                                    className="eyicn"
                                                    onClick={togglePasswordVisibility}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlTextarea1"
                                        >
                                            <Form.Label className="text">
                                                Confirm Password
                                            </Form.Label>
                                            <div className="fcont_div">
                                                <Form.Control
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    placeholder="password"
                                                    {...register("re_password", {
                                                        required: "Re-Password is required",
                                                    })}
                                                    onChange={(e) =>
                                                        setPasswordAgain(e.target.value)
                                                    }
                                                />
                                                <FontAwesomeIcon
                                                    icon={showConfirmPassword ? faEyeSlash : faEye}
                                                    className="eyicn"
                                                    onClick={toggleConfirmPasswordVisibility}
                                                />
                                            </div>
                                        </Form.Group>

                                        {password && (
                                            <PasswordChecklist
                                                rules={[
                                                    "minLength",
                                                    "specialChar",
                                                    "number",
                                                    "capital",
                                                    "match",
                                                ]}
                                                minLength={8}
                                                value={password}
                                                valueAgain={passwordAgain}
                                                onChange={(isValid) => { }}
                                                style={{
                                                    fontSize: "10px",
                                                    padding: "4px",
                                                    margin: "0",
                                                }}
                                            />
                                        )}

                                        <div className="d-flex checkformcc mt-2">
                                            <Form.Check
                                                type="checkbox"
                                                id="custom-switch"
                                                label="By signing up you agree to our "
                                                className="sub-text"
                                            />
                                            <Link to="/forgotpassword">
                                                <p className="sub-text ms-1">
                                                    terms and conditions
                                                </p>
                                            </Link>
                                        </div>
                                        <div className="text-center">
                                            <Button
                                                type="submit"
                                                className="loginbtn"
                                            >Create Account </Button>
                                        </div>
                                        <div className="text-center">
                                            <p className="mt-3 mb-2 sub-text " >Already have an account?
                                                <span className="midtext colrrr" onClick={() => {  setShowRegisterForm(false) }} > Login Now.</span> </p>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    );
}

export default Register;
