import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import MainBlog from './MainBlog/MainBlog'
import RecentNews from './RecentNews/RecentNews'
import Breadcrumbs from '../CommonComponets/Breadcrumbs/Breadcrumbs'
import { Context } from '../../utils/context'
import { blogPost } from '../../utils/apis/common/Common'

const Blog = () => {

    const { IMG_URL, sellerId } = useContext(Context);
    const [blogdata, setBlogdata] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);
    const date = new Date();
    const getBlogData = async (page) => {
        const res = await blogPost(sellerId, page);
        if (res?.success) {
            setBlogdata(res?.data);
            console.log(res?.data?.data);
            setTotalPages(res?.data?.totalPages);
        } else {
        }
    };

    useEffect(() => {
        getBlogData(activePage);
    }, [activePage]);

    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/" },
        { text: "Blog", link: "#" },
        ,
      ]);
    return (
        <section className='my-4'>
            <Container>
            <div>
              <Breadcrumbs items={breadcrumbItems} />
            </div>
                <Row>
                    <Col lg={3} md={4} sm={12} xs={12} >
                        <RecentNews blogdata={blogdata} />
                    </Col>
                    <Col lg={9} md={8} sm={12} xs={12}>
                        <MainBlog blogdata={blogdata}/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Blog