import React, {useContext, useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Pagination} from "swiper/modules";
import "./HomeBanner.css";
import {Col, Row} from "react-bootstrap";
import {faChevronLeft, faChevronRight, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Product from "../../CommonComponets/Product/Product";
import {Link} from "react-router-dom";
import {Context} from "../../../utils/context";
import { GetAllHomeBanner } from "../../../utils/apis/master/Master";


const HomeBanner = () => {
    const [swiperInstance2, setSwiperInstance2] = useState(null);
 
    const handleMainSliderClick2 = (direction) => {
        if (swiperInstance2) {
            if (direction === "prev") {
                swiperInstance2.slidePrev();
            } else if (direction === "next") {
                swiperInstance2.slideNext();
            }
        }
    };
   

    const items = [
        {
            name: "Beauty",
        },
        {
            name: "Butter & Eggs",
        },
        {
            name: "Cleansers",
        },
        {
            name: "Eyes Care",
        },
        {
            name: "Fastfood",
        },
    ];
    const BannerData = [
        {
            imgSrc: process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/home-banner1.png",
        },
        {
            imgSrc: process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/home-banner1.png",
        },
    ];

   
    const [homebanner, setHomeBanner] = useState([]);

    const {sellerId, IMG_URL} = useContext(Context);

    const getHomeBanner = async () => {
        const res = await GetAllHomeBanner(sellerId);
        if (res?.success) {
            setHomeBanner(res?.data);
        }
    };
    useEffect(() => {
        getHomeBanner();
    }, []);
    return (
        <>
            <section className="Home-Banner mt-5">
                <div className="container ">
                    <Row>
                        <div className="swipermain_div">
                            <Swiper
                                spaceBetween={30}
                                modules={[]}
                                autoplay={{delay: 2000}}
                                onSwiper={(swiper) => setSwiperInstance2(swiper)}
                                className="mySwiper"
                            >
                                {homebanner.map((slide, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="swiperimg_div">
                                            <img className="Banner_image" src={IMG_URL + slide?.image} alt="Banner" />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            <div className="swiperbutton_div">
                                <div className="buttondiv" onClick={() => handleMainSliderClick2("prev")}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </div>
                                <div className="buttondiv" onClick={() => handleMainSliderClick2("next")}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </div>
                            </div>
                        </div>

                        {/* <Col xxl={4} xl={4} lg={4} md={4} >
                  <div className="swiperimg_div  sideimgbandiv">

                    <img src={process.env.PUBLIC_URL + "/assets/Image/Homepage/Banner/banner71.jpg"} className="Banner_image" />
                  </div>


                </Col> */}

                       
                        {/* </Col> */}
                    </Row>
                </div>
            </section>
        </>
    );
};

export default HomeBanner;
