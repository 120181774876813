import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './BlogDetails.css'
import RecentNews from '../RecentNews/RecentNews'
import MainBlog from '../MainBlog/MainBlog'
import BlogDetailsMain from './BlogDeatilsMain/BlogDetailsMain'
import Breadcrumbs from '../../CommonComponets/Breadcrumbs/Breadcrumbs'
import { Context } from '../../../utils/context'
import { useParams } from 'react-router-dom'
import { blogdetails } from '../../../utils/apis/common/Common'

function BlogDetails() {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/" },
        { text: "Blog", link: "/blog" },
        { text: "BlogDetails", link: "#" },
        ,
      ]);

      const {id} = useParams();

    const {IMG_URL} = useContext(Context);
    const [blogdet, setBlogdet] = useState([]);
    const [date, setDate] = useState(new Date());
    const getBlogdetails = async () => {
        const res = await blogdetails(id);
        if (res?.success) {
            setBlogdet(res?.data);
            console.log(res?.data);
        } else {
        }
    };

    useEffect(() => {
        if (id) {
            getBlogdetails();
        }
    }, [id]);
  return (
    <>
    <section className='mt-4'>
            <Container>
            <div>
              <Breadcrumbs items={breadcrumbItems} />
            </div>
                <Row>
                    {/* <Col lg={3} md={4} sm={12} xs={12}>
                        <RecentNews />
                    </Col> */}
                    <Col lg={12} md={12} sm={12} xs={12} className=''>
                        <BlogDetailsMain blogdet={blogdet}/>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
  )
}

export default BlogDetails