import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { getData, postData, putData, deleteData, getDownloadDataExcel } from "./api";
import { Per_Page_Dropdown, Select2Data, RegxExpression } from "./common";
import { getCart } from "./apis/addToCart/addToCart";
import { getWishlist } from "./apis/Wishlist/Wishlist";

export const Context = createContext();

const AppContext = ({ children }) => {
    // const IMG_URL = "http://192.168.16.39:3000";
    const IMG_URL = "http://netpurtinode.profcymabackend.com:3434";
    const navigate = useNavigate();

    const [sellerId, setSellerId] = useState(1);
    const [cartprice, setCartprice] = useState(null);
    const [type, setType] = useState("outer");
    const [carttotalPrice, setCarttotalPrice] = useState(null);
    const [signin, setSignin] = useState(false);
    const [usertype, setUsertype] = useState("");
    const [userdata, setUserData] = useState({});
    const [isAllow, setisAllow] = useState([]);
    const [cartCount, setCartCount] = useState(0);
    const [wishlistCount, setWishlistCount] = useState(0);
    const [wishlistData, setWishlistData] = useState([]);
    const [addCartModal, setAddCartModal] = useState(false);
    const [shimmerLoader, setShimmerLoader] = useState(false);
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [trigger, setTrigger] = useState(0);
    const [cartLengthFromCookies, setCartLengthFromCookies] = useState(0);
    console.log(cartLengthFromCookies, "lengthththth");
    useEffect(() => {
        isTokenValid();
        updateCartLengthFromCookies();
    }, [signin, trigger]);

    const triggercart = () => {
        setTrigger(trigger === 0 ? 1 : 0);
    };

    const updateCartLengthFromCookies = () => {
        const cart = Cookies.get("ecommerce3_cart");
        if (cart) {
            setCartLengthFromCookies(JSON.parse(cart).length);
        }
    };

    const isTokenValid = async () => {
        const token = Cookies.get("ecommerce3_security");

        if (token) {
            const decodedToken = JSON.parse(atob(token.split(".")[1]));
            const currentTime = Date.now() / 1000;

            if (decodedToken.exp < currentTime) {
                Cookies.remove("ecommerce3_security", { path: "/" });
                setSignin(false);
                setUsertype("");
                setisAllow([]);
                navigate("/");
            } else {
                setisAllow(decodedToken?.permissions);
                setUsertype(decodedToken.rolename);
                const cart = await getCart(sellerId);
                setCartCount(cart?.data?.length);

                let totalPrice = 0;
                cart?.data?.forEach((product) => {
                    totalPrice += product?.selling_price * product?.quantity;
                });
                setCarttotalPrice(totalPrice);

                const wishlist = await getWishlist();
                setWishlistData(wishlist?.data?.id);
                setWishlistCount(wishlist?.data?.id?.length);
                setSignin(true);
            }
        } else {
            setisAllow([]);
            setSignin(false);
            setUsertype("");
        }
    };

    const updateCart = async () => {
        const cart = await getCart(sellerId);
        setCartCount(cart?.data?.length);
    };

    const updateWishlist = async () => {
        const wishlist = await getWishlist();
        setWishlistCount(wishlist?.data?.id?.length);
    };

    const ErrorNotify = (name) => {
        toast.error(`${name} deleted successfully.`, {
            position: "top-right",
            autoClose: 100,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            style: { background: "red", color: "white" },
        });
    };

    const wishlistContext = async () => {
        if (signin) {
            const res = await getWishlist();
            if (res?.success) {
                setWishlistData(res?.data?.id);
            }
        }
    };

    return (
        <Context.Provider
            value={{
                IMG_URL,
                getData,
                postData,
                putData,
                deleteData,
                signin,
                setSignin,
                usertype,
                setUsertype,
                userdata,
                setUserData,
                getDownloadDataExcel,
                ErrorNotify,
                toggleSidebar: () => setSidebarOpen(!isSidebarOpen),
                isSidebarOpen,
                setSidebarOpen,
                Select2Data,
                Per_Page_Dropdown,
                RegxExpression,
                shimmerLoader,
                setShimmerLoader,
                addCartModal,
                setAddCartModal,
                cartCount,
                wishlistData,
                setWishlistData,
                wishlistContext,
                sellerId,
                setSellerId,
                type,
                setType,
                wishlistCount,
                carttotalPrice,
                triggercart,
                cartLengthFromCookies
            }}
        >
            {children}
        </Context.Provider>
    );
};

export default AppContext;
