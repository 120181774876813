import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';
import { useForm,} from "react-hook-form";
import PasswordChecklist from "react-password-checklist";
import { resetPassWord } from "../../../utils/apis/common/Common";
import PasswordResetModal from "../../CommonComponets/PasswordResetModal/PasswordResetModal";



function SetNewPassword({ email }) {

    const [modalShow, setModalShow] = React.useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordOne, setShowPasswordOne] = useState(false);
    const navigate = useNavigate();


    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        if (data.password === data.re_password) {

            data.email = email;
            const res = await resetPassWord(data);
            if (res?.success) {
                setModalShow(true);

                setTimeout(() => {
                    setModalShow(false);
                    navigate('/login');
                }, 3000);
            }
        } else {
            setError("password", { message: "Password does not match" });
        }
    }
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    return (
        <section className="login_section">
            <Container fluid>
                <Row className="justify-content-center mx-auto">
                    <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} className="p-4 p-lg-4">
                        <div className="login-bggg">
                            <div className="text-center">
                                <h1 className="title">Set New Password</h1>
                            </div>
                            <div className="log_in_form mt-4">
                                <Form>
                                    <Form.Label className="text">Password</Form.Label>
                                    <Form.Group className="mb-3" controlId="">
                                        <div className="fcont_div">
                                            <Form.Control
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Enter Password*"
                                                name="password"
                                                {...register("password", {
                                                    required: "Password is required",
                                                    minLength: {
                                                        value: 8,
                                                        message:
                                                            "Password must be at least 8 characters long",
                                                    },
                                                    pattern: {
                                                        value:
                                                            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                                        message:
                                                            "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                                    },
                                                })}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />

                                            <div className="fcont_div ">
                                                <FontAwesomeIcon
                                                    icon={showPassword ? "eye" : "eye-slash"}
                                                    className="eyicn"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            </div>
                                        </div>
                                        {errors?.password && (
                                            <sup className="text-danger">
                                                {errors?.password?.message}
                                            </sup>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label className="text">Confirm Password</Form.Label>
                                        <div className="password-field-holder">
                                            <Form.Control
                                                type={showPasswordOne ? "text" : "password"}
                                                placeholder="Re-Enter Password*"
                                                name="re_password"
                                                {...register("re_password", {
                                                    required: "Re-Password is required",
                                                })}
                                                onChange={(e) =>
                                                    setPasswordAgain(e.target.value)
                                                }
                                            />
                                            <div className="eye-icon-holder ">
                                                <FontAwesomeIcon
                                                    icon={showPasswordOne ? "eye-slash" : "eye"}
                                                    className="eyicn"
                                                    onClick={() => setShowPasswordOne(!showPasswordOne)}
                                                />
                                            </div>
                                        </div>
                                        {errors?.re_password && (
                                            <sup className="text-danger">
                                                {errors?.re_password?.message}
                                            </sup>
                                        )}
                                    </Form.Group>
                                    {password && (
                                        <PasswordChecklist
                                            rules={[
                                                "minLength",
                                                "specialChar",
                                                "number",
                                                "capital",
                                                "match",
                                            ]}
                                            minLength={8}
                                            value={password}
                                            valueAgain={passwordAgain}
                                            onChange={(isValid) => { }}
                                            style={{
                                                fontSize: "10px",
                                                padding: "4px",
                                                margin: "0",
                                            }}
                                        />
                                    )}
                                    <div className="btn-holder text-center">
                                        <Button
                                            onClick={handleSubmit(onSubmit)}
                                            className="loginbtn"
                                        >Reset Password</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <PasswordResetModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                texts=" Your password has been reset successfully!"
            />
        </section>
    );
}

export default SetNewPassword;
