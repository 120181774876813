import React from 'react'
import '../Dashboard/Dashboard.css'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Orders = () => {
  return (
      <section className='orders-section'>
        <div className='d-flex justify-content-between'>
              <p className='sub-txtt'><FontAwesomeIcon icon={faCalendar} /> No order has been made yet.</p>
              <button className='brows-pro'>Brows Product</button>
        </div>
    </section>
  )
}

export default Orders