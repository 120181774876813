import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard, faUser } from "@fortawesome/free-regular-svg-icons";
import { Col, Row } from "react-bootstrap";
import "./LoginModal.css";
import ButtonCommon from "../ButtonCommon/ButtonCommon";
import { Link } from "react-router-dom";
const LoginModal = ({ show, onHide }) => {
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        centered
        className="modalmain"
      >
        <Modal.Header closeButton>
          <div className="title">
            <FontAwesomeIcon icon={faAddressCard} className="me-2" />
            Sign In or Register
          </div>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Row>
            <Col xxl={6} xl={6} lg={6} md={6} sm={6} className="mb-2">
              <Form>
                <Form.Group controlId="formUsername" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    className="inputtext"
                  />
                </Form.Group>

                <Form.Group controlId="formPassword" className="mb-3">
                  <Form.Control type="password" placeholder="Password" />
                </Form.Group>

                <div className="d-flex justify-content-between mb-3">
                  <a className="text" href="#">
                    Forgot your password?
                  </a>
                </div>

                <Button className="createbtn" type="submit">
                  <FontAwesomeIcon icon={faLock} className="me-2" />
                  Login
                </Button>
              </Form>
            </Col>
            <Col
              xxl={6}
              xl={6}
              lg={6}
              md={6}
              sm={6}
              className="border-start ps-3 mb-2"
            >
              <h5 className="price ">New here?</h5>
              <p className="text">Registration is free and easy!</p>
              <ul>
                <li className="text">Faster checkout</li>
                <li className="text">Save multiple shipping addresses</li>
                <li className="text">View and track orders and more</li>
              </ul>
              {/* <Button className='createbtn'>Create an Account</Button> */}
              <Link to="/register">
                <ButtonCommon text="Create an Account" className="createbtn" onClick={onHide} />
              </Link>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginModal;
